import { request, } from '@/utils';
export async function getRevenues(payload: any): Promise<any> {
  const { year, } = payload;
  const filter: any = { status: { _eq: 'published', }, };
  if (year) {
    filter['year'] = { _eq: year, };
  }
  const params = {
    sort: '-year,month',
    fields: '*.*',
    filter: filter,
  };
  return request.get(`/items/revenues`, { params, });
}

export async function getFinance(payload: any): Promise<any> {
  const { year, } = payload;
  const filter: any = { status: { _eq: 'published', }, };
  if (year) {
    filter['year'] = { _eq: year, };
  }
  const params = {
    sort: '-year',
    fields: '*.*',
    filter: filter,
  };
  return request.get(`/items/finance_reports`, { params, });
}

export async function getAnnual(payload: any): Promise<any> {
  const { year, } = payload;
  const filter: any = { status: { _eq: 'published', }, };
  if (year) {
    filter['year'] = { _eq: year, };
  }
  const params = {
    sort: '-year',
    fields: '*.*',
    filter: filter,
  };
  return request.get(`/items/annual_reports`, { params, });
}