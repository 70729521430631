import {
  type ReactNode, type FC, type Key, Fragment, type ComponentProps,
} from 'react';
import {
  Box,
  type Theme,
  Typography,
  styled,
  type SxProps,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import SvgIcon from '@mui/material/SvgIcon';
import RectangleButton from '@/components/RectangleButton';
import PrimaryButton from '@/components/PrimaryButton';

interface FlowCardProps {
  dataId: number;
  sx?: SxProps<Theme>;
  icon?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  hiddenLine?: boolean;
}

interface FlowListProps {
  flowData?: Omit<FlowCardProps, 'sx' | 'hiddenLine'>[];
}

const flowData: Omit<FlowCardProps, 'sx' | 'hiddenLine'>[] = [
  {
    dataId: 0,
    icon: <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: '16px', }}>
      <g clipPath="url(#clip0_3508_4020)">
        <path d="M16.8774 10.2891H43.1226C44.5526 10.2891 45.7094 11.4458 45.7094 12.8758V48.753C45.7094
        49.7179 44.9304 50.4969 43.9655 50.4969H16.8774C15.4474 50.4969 14.2906 49.3401 14.2906
        47.9101V12.8758C14.2906 11.4458 15.4474 10.2891 16.8774 10.2891Z"
        stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M24.4283 35.8203H39.2454" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M24.4283 39.4297H39.2454" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M24.4283 43.0391H32.5838" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M20.7546 35.8203H22.3648" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M20.7546 39.4297H22.3648" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M20.7546 43.0391H22.3648" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M29.9972 25.1496C31.307 25.1496 32.3688 24.0877 32.3688 22.7779C32.3688 21.4681 31.307
        20.4062 29.9972 20.4062C28.6873 20.4062 27.6255 21.4681 27.6255 22.7779C27.6255 24.0877 28.6873
        25.1496 29.9972 25.1496Z" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M29.9971 31.0195C31.8863 31.0195 33.6011 30.2696 34.8625 29.0489C34.3684 26.8167 32.3804
        25.1484 29.9971 25.1484C27.6138 25.1484 25.6316 26.8167 25.1317 29.0489C26.3931 30.2638 28.1021
        31.0195 29.9971 31.0195Z" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M29.9971 31.0208C33.8656 31.0208 37.0016 27.8848 37.0016 24.0163C37.0016 20.1478 33.8656
        17.0117 29.9971 17.0117C26.1286 17.0117 22.9926 20.1478 22.9926 24.0163C22.9926 27.8848 26.1286
        31.0208 29.9971 31.0208Z" stroke="#0E2338" strokeMiterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_3508_4020">
          <rect width="32" height="40.7891" fill="white" transform="translate(14 10)"/>
        </clipPath>
      </defs>
    </svg>
    ,
    title: <FormattedMessage id={'hiring.blockfive.flow-list.flow1.title'} />,
    description: <FormattedMessage id={'hiring.blockfive.flow-list.flow1.message'} />,
  },
  {
    dataId: 1,
    icon: <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: '16px', }}>
      <g clipPath="url(#clip0_3508_4040)">
        <path d="M12.7731 31.3242H25.8668" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M12.8787 35.6641H28.4402" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M12.8787 40H23.3763" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M25.3234 20.5153C23.5951 19.8194 21.7009 19.4336 19.7236 19.4336C11.407 19.4336 4.66774
        26.1885 4.66774 34.5244C4.66774 38.6318 6.31295 42.3534 8.96942 45.0766L2.23767 49.6151H19.7236C26.312
        49.6151 31.9042 45.3791 33.9494 39.479" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M39.549 10.3789C47.8656 10.3789 54.6049 17.1338 54.6049 25.4697C54.6049 29.5771 52.9597 33.2987
        50.3033 36.0219L57.035 40.5605H39.549C31.2325 40.5605 24.4932 33.8055 24.4932 25.4697C24.4932 17.1338
        31.2325 10.3789 39.549 10.3789Z" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M40.0472 33.1025C40.3348 33.1025 40.5679 32.8688 40.5679 32.5805C40.5679 32.2923 40.3348 32.0586
        40.0472 32.0586C39.7596 32.0586 39.5265 32.2923 39.5265 32.5805C39.5265 32.8688 39.7596 33.1025 40.0472
        33.1025Z" fill="#0E2338"/>
        <path d="M40.0472 30.4454V26.2321C41.9716 26.2321 43.5263 24.6738 43.5263 22.745C43.5263 20.8161
        41.9716 19.2578 40.0472 19.2578C38.1228 19.2578 36.5681 20.8161 36.5681 22.745"
        stroke="#0E2338" strokeMiterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_3508_4040">
          <rect width="57.2727" height="40" fill="white" transform="translate(1 10)"/>
        </clipPath>
      </defs>
    </svg>
    ,
    title: <FormattedMessage id={'hiring.blockfive.flow-list.flow2.title'} />,
    description: <FormattedMessage id={'hiring.blockfive.flow-list.flow2.message'} />,
  },
  {
    dataId: 2,
    icon: <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: '16px', }}>
      <g clipPath="url(#clip0_3508_4060)">
        <path d="M48.4042 31.3377V45.3621C48.4042 46.6265 47.3716 47.6585 46.0913 47.6585H12.6572C11.3837
        47.6585 10.3442 46.6334 10.3442 45.3621V23.1362C10.3442 21.8719 11.3768 20.8398 12.6572 20.8398H41.0593"
        stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M11.4663 46.973L26.2869 34.6094" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M47.3371 46.9271L32.5715 34.5156" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M41.0593 25.7999V19.4233C41.0593 15.4046 44.3704 12.1172 48.418 12.1172C52.4656 12.1172 55.7767
        15.4046 55.7767 19.4233V25.7999" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M56.6852 25.8008H40.1505C39.6145 25.8008 39.1799 26.2322 39.1799 26.7645V27.4821C39.1799 28.0143
        39.6145 28.4457 40.1505 28.4457H56.6852C57.2212 28.4457 57.6558 28.0143 57.6558 27.4821V26.7645C57.6558
        26.2322 57.2212 25.8008 56.6852 25.8008Z" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M46.5112 12.233C46.5112 11.1942 47.3717 10.3398 48.418 10.3398C49.4643 10.3398 50.3248 11.1942
        50.3248 12.233" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M45.5061 28.4453C45.5061 30.0378 46.814 31.3363 48.4179 31.3363C50.0218 31.3363 51.3297 30.0378
        51.3297 28.4453" stroke="#0E2338" strokeMiterlimit="10"/>
        <path d="M11.3079 21.668L27.0715 35.2892C28.3932 36.4306 30.3619 36.4306 31.6836 35.2892L40.1505 28.4478"
          stroke="#0E2338" strokeMiterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_3508_4060">
          <rect width="48" height="38" fill="white" transform="translate(10 10)"/>
        </clipPath>
      </defs>
    </svg>
    ,
    title: <FormattedMessage id={'hiring.blockfive.flow-list.flow3.title'} />,
    description: <FormattedMessage id={'hiring.blockfive.flow-list.flow3.message'} />,
  },
];

const StyledFlowCard = styled('div')(({ theme, }) => ({
  width: '300px',
  height: '300px',
  borderRadius: '999px',
  border: '1px solid rgba(133, 193, 31, 0.25)',
  background:
    'linear-gradient(180deg, #DBEDBD 0%, rgba(192, 237, 114, 0) 100%)',
  ['&:hover']: { outline: '10px solid rgba(133, 193, 31, 0.25)', },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '50px',
  position: 'relative',
}));

const Line = styled('hr')(({ theme, }) => ({
  width: 'calc(23vw - 280px)',
  border: '2px solid rgba(133, 193, 31, 0.45)',
  position: 'absolute',
  top: '50%',
  zIndex: '-1',
}));

const FlowCard: FC<FlowCardProps> = ({
  sx, dataId, icon, title, description, hiddenLine,
}) => {
  return (
    <StyledFlowCard sx={sx}>
      {icon}
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '33px',
          marginBottom: '12px',
          color: '#0E2338',
        },]}
      >
        {title}
      </Typography>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
          color: 'rgba(14, 35, 56, 0.85)',
          width: '200px',
        },]}
      >
        {description}
      </Typography>
      <Line sx={[{ display: 'none', }, (theme) => ({ [theme.breakpoints.up('lg')]: {
        display: hiddenLine ? 'none' : 'block', left: '300px',
      }, }),]} />
    </StyledFlowCard>
  );
};

const FlowList: FC<FlowListProps> = ({ flowData, }) => {
  return (
    <Box
      sx={[{
        width: '100%',
        maxWidth: '1280px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '780px',
      }, (theme) => ({ [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        display: 'flex',
        gap: 'calc(23vw - 280px)',
        minHeight: 'unset',
      }, }),]}
    >
      {flowData?.map((item) => {
        return (
          <Fragment key={item.dataId}>
            <FlowCard
              sx={[{
                position: 'absolute',
                top: '0',
                marginTop: `${item.dataId * 230}px`,
              }, (theme) => ({ [theme.breakpoints.up('lg')]: {
                position: 'relative',
                marginTop: '0',
                top: 'unset',
              }, }),]}
              hiddenLine={item.dataId === 2}
              {...item}
            />
          </Fragment>
        );
      })}
    </Box>
  );
};

const Block5: FC<ComponentProps<typeof Box>> = ({ ...props }) => {
  return (
    <Box
      sx={[{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '1090px',
        padding: '0 20px',
        alignItems: 'center',
      }, (theme) => ({ [theme.breakpoints.up('lg')]: {
        minHeight: '560px',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingLeft: '70px',
      }, }),]}
      {...props}
    >
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '33px',
          color: '#0E2338',
          textAlign: 'center',
          width: '120px',
          marginBottom: '40px',
          marginTop: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }, (theme) => ({ [theme.breakpoints.up('lg')]: {
          width: '200px',
          marginRight: 'calc(23vw - 340px)',
          marginBottom: '0',
          marginTop: '0',
          fontSize: '40px',
          lineHeight: '54px',
        }, }),]}
      >
        <FormattedMessage id={'hiring.blockfive.flow.title'} />
        <a
          href="https://www.104.com.tw/company/bijt468"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'none', }}
        >
          <PrimaryButton
            colorStyle="green"
            sx={[{
              display: 'none', position: 'relative',
            }, (theme) => ({ [theme.breakpoints.up('lg')]: {
              display: 'block', marginTop: '25px',
            }, }),]}>

            <FormattedMessage id="hiring.blockfive.flow.button" />
          </PrimaryButton>
        </a>
      </Typography>
      <FlowList flowData={flowData}/>
      <a
        href="https://www.104.com.tw/company/bijt468"
        target="_blank"
        rel="noreferrer noopener"
        style={{ textDecoration: 'none', }}
      >
        <PrimaryButton
          colorStyle="green"
          sx={[{
            display: 'flex', marginTop: '25px',
          }, (theme) => ({ [theme.breakpoints.up('lg')]: { display: 'none', }, }),]}>
          <FormattedMessage id="hiring.blockfive.flow.button" />
        </PrimaryButton>
      </a>
    </Box>
  );
};

export default Block5;