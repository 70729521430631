import React, {
  useState, useEffect, useRef,
} from 'react';
import {
  useNavigate, Link,
} from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  Box, Theme, Typography, Button, Divider,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  getNews, getGlobal,
} from '@/reducers/states';
import ScrollAnimation from 'react-animate-on-scroll';
import Path from '../../../plugins/path.json';
import BackgroudImage from '../assets/Block1/background.png';
import AnchorBarBackgroud from '../assets/Block1/anchor-bar.png';
import TextButton from '@/components/TextButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    padding: '120px',
  },
  titleContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  pageName: {
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(133, 193, 31, 0.75)',
    borderRadius: '10px',
    padding: '8px 30px',
  },
  title1: {
    color: theme.palette.secondary.main,
    textShadow: '0 0 24px rgba(0, 0, 0, 0.2)',
  },
  title2: {
    color: theme.palette.secondary.main,
    textShadow: '0 0 24px rgba(0, 0, 0, 0.2)',
  },
  title3: {
    textShadow: '0 0 24px rgba(0, 0, 0, 0.2)',
    color: theme.palette.secondary.main,
  },
  anchorBar: {
    height: '80px',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    bottom: '0',
    left: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '110%',
  },
  [theme.breakpoints.down(800)]: { container: {
    padding: '96px 24px 24px 24px',
    height: '450px',
  }, },
}));

const aboutPageAnchors = Path.find((item, index) => item.path === '/about');

const BlockOne = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const isShowAnchor = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroudImage})`, }}
    >
      <Box className={classes.titleContainer}>
        <Typography
          className={classes.pageName}
          fontSize={isMobile ? '16px' : '24px'}
          fontWeight={'bold'}
          marginBottom={isMobile ? '16px' : '0px'}
        >
          <FormattedMessage id={'about.page_name'} />
        </Typography>
        <ScrollAnimation className="fadeInDown">
          <Typography
            className={classes.title1}
            fontSize={isMobile ? '24px' : '66px'}
            fontWeight={'bold'}
          >
            <FormattedMessage id={'about.blockone.title.1'} />
          </Typography>
          <Typography
            className={classes.title2}
            fontSize={isMobile ? '24px' : '66px'}
            fontWeight={'bold'}
            marginBottom={'24px'}
          >
            <FormattedMessage id={'about.blockone.title.2'} />
          </Typography>
          <Typography
            className={classes.title3}
            fontSize={isMobile ? '16px' : '24px'}
          >
            <FormattedMessage id={'about.blockone.title.3'} />
          </Typography>
        </ScrollAnimation>
      </Box>
      {isShowAnchor && (
        <Box
          className={classes.anchorBar}
          style={{
            backgroundImage: `url(${AnchorBarBackgroud})`,
            width: '948px',
          }}
        >
          {aboutPageAnchors?.menu
            ?.filter((item) => !!item.anchor)
            .map((item: any, index: any) => {
              // “關於新光鋼集團” 為外部連結，不放在白色錨點列表
              return (
                <Link
                  key={index}
                  to={item.anchor}
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.secondary.dark,
                    borderLeft: index !== 0 ? `0.5px solid ${theme.palette.primary.main}` : '',
                    padding: '0 24px',
                  }}
                >
                  <TextButton>
                    <Typography fontSize={locale === 'en_us' ? '14px' : '16px'}>
                      <FormattedMessage id={item.title} />
                    </Typography>
                  </TextButton>
                </Link>
              );
            })}
        </Box>
      )}
    </Box>
  );
};

export default BlockOne;