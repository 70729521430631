import React from 'react';
import DrawerHeader from './DrawerHeader';
import AppPlugins from './AppPlugins';
import Header from './Header';
import Footer from './Footer';

export type PluginType = {
  App: React.FC;
  [layoutName: string]: {};
};

const plugins: PluginType = {
  App: AppPlugins,
  Drawer: { DrawerHeader, },
  ApexOfficialWebsite: {
    Header,
    Footer,
  },
};
export default plugins;