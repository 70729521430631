import { useState, } from 'react';
import { Box, } from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { Link, } from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import MenuButton from './MenuButton';
import { isLink, } from '@/utils/tool';
import { makeStyles, } from '@mui/styles';
import { Theme, } from '@mui/material';

type Props = {
  children?: any;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  path?: any;
  item?: any;
  scrollTop?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  fakeHoverContainer: {
    position: 'absolute',
    top: '0px',
    height: '40px',
    width: '100%',
    zIndex: '1',
  },
  text: {
    textDecoration: 'none',
    position: 'relative',
    color: theme.palette.secondary.dark,
    userSelect: 'none',
    cursor: 'pointer',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      transform: 'scaleX(0)',
      height: '2px',
      bottom: '-3px',
      left: '0',
      backgroundColor: theme.palette.primary.main,
      transformOrigin: 'bottom left',
      transition: 'transform 0.25s ease-out',
    },
    '&:hover': {
      color: theme.palette.primary.main,
      '&:after': {
        transformOrigin: 'bottom left',
        transform: 'scaleX(1)',
      },
    },
    '&:active': {
      color: theme.palette.primary.main,
      '&:after': {
        transformOrigin: 'bottom left',
        transform: 'scaleX(1)',
      },
    },
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 'auto',
    padding: '16px',
    gap: '10px',
    position: 'absolute',
    whiteSpace: 'nowrap',
    top: '40px',
    left: '0',
    zIndex: '-1',
    background: 'rgba(255, 255, 255, 0.95)',
    borderRadius: '10px',
    borderTop: '0.75px solid rgba(209, 210, 212, 0.45);',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
}));

const Menu = ({
  setOnHover, menuItems, path, style,
}: any) => {
  const classes = useStyles();
  const { locale, } = useSelector(getGlobal);

  return (
    <div style={style}>
      <div
        onMouseEnter={() => {
          setOnHover(true);
        }}
        onMouseLeave={() => {
          setOnHover(false);
        }}
        className={classes.menuContainer}
      >
        {menuItems?.map((item: any, index: any) => {
          const isLink = !!item.link;
          if (isLink) {
            return (
              <a
                key={index}
                href={item.link}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: 'none',
                  width: '100%',
                }}
              >
                <MenuButton topLine={index === 0}>
                  <FormattedMessage id={item.title} />
                </MenuButton>
              </a>
            );
          } else {
            return (
              <Link
                key={index}
                to={item.anchor.includes('/') ? `${locale}${item.anchor}` : `${locale}${path}${item.anchor}`}
                style={{
                  textDecoration: 'none',
                  width: '100%',
                }}
              >
                <MenuButton topLine={index === 0}>
                  <FormattedMessage id={item.title} />
                </MenuButton>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
};

const HeaderButton = ({
  children, onClick, item, scrollTop, path,
}: Props) => {
  const classes = useStyles();
  const { locale, } = useSelector(getGlobal);
  const [onHover, setOnHover,] = useState(false);

  if (isLink(path)) {
    return (
      <Box className={classes.container}>
        <a
          className={classes.text}
          href={path}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {children}
        </a>
      </Box>
    );
  } else {
    return (
      <Box className={classes.container}>
        <Link
          to={locale + path}
          className={classes.text}
          onMouseEnter={() => {
            setOnHover(true);
          }}
          onMouseLeave={() => {
            setOnHover(false);
          }}
        >
          {children}
          <div
            className={classes.fakeHoverContainer}
            onMouseEnter={() => {
              setOnHover(true);
            }}
            onMouseLeave={() => {
              setOnHover(false);
            }}
          ></div>
        </Link>
        {item?.menu && (
          <Menu
            setOnHover={setOnHover}
            menuItems={item?.menu}
            path={path}
            style={{ display: onHover ? 'unset' : 'none', }}
          />
        )}
      </Box>
    );
  }
};

export default HeaderButton;