import React, {
  useEffect, useState,
} from 'react';
import {
  Box, Theme, Typography,
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import { makeStyles, } from '@mui/styles';
import {
  FormattedMessage, useIntl,
} from 'react-intl';
import clsx from 'clsx';
import BoardData from '../board';
import DefaultImg from '../assets/block3/default.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import PaginationButton from '@/components/PaginationButton';
import useEmblaCarousel from 'embla-carousel-react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '120px',
    [theme.breakpoints.down('md')]: { padding: '80px 24px', },
  },
  topFilter: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '320px',
    background: 'linear-gradient(180deg, rgba(50, 115, 182, 0.1) 0%, rgba(133, 193, 31, 0) 53.12%)',
    zIndex: 1,
  },
  title: {
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  },
  boardContainer: {
    width: '940px',
    display: 'flex',
    justifyContent: 'center',
    itemsAlign: 'center',
    gap: '40px',
    marginBottom: '80px',
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  mainImage: {
    position: 'relative',
    width: '320px',
    height: '320px',
    borderRadius: '12px',
    backgroundBlendMode: 'darken',
    mixBlendMode: 'normal',
    backdropFilter: 'blur(234px)',
    overflow: 'hidden',
    boxShadow: '0px 4px 20px 4px rgba(0, 0, 0, 0.25)',
    backgroundImage: 'red',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    [theme.breakpoints.down(600)]: {
      width: '159px',
      height: '159px',
      '& img': {
        width: '159px',
        height: '159px',
        objectFit: 'cover',
      },
    },
  },
  mainImageFilter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgba(9, 68, 129, 0) 61.72%, rgba(9, 68, 129, 0.25) 78.18%, rgba(9, 68, 129, 0.65) 100%)',
  },
  boardWrap: {
    width: '100%',
    display: 'flex',
    borderTop: '1px solid #D1D2D473',
    borderBottom: '1px solid #D1D2D473',
    overflowX: 'hidden',
  },
  boardList: {
    width: '100%',
    maxWidth: '940px',
    display: 'flex',
    // justifyContent: 'flex-start',
    itemsAlign: 'center',
    gap: '48px',
    padding: '48px 24px',
    // overflowX: 'scroll',
    '&::-webkit-scrollbar': { display: 'none', },
  },
  secondImage: {
    position: 'relative',
    width: '110px',
    height: '110px',
    borderRadius: '12px',
    backgroundBlendMode: 'darken',
    mixBlendMode: 'normal',
    backdropFilter: 'blur(234px)',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  secondImageFilter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgba(9, 68, 129, 0) 61.72%, rgba(9, 68, 129, 0.25) 78.18%, rgba(9, 68, 129, 0.65) 100%)',
  },
  boardCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    cursor: 'pointer',
    opacity: 0.5,
  },
  boardCardSelected: { opacity: 1, },
  slideButton: {
    display: 'flex',
    gap: '24px',
    zIndex: '10',
    [theme.breakpoints.down(800)]: { width: '100%', },
  },
  paginationButton:{
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(800)]: { display: 'none', },
  },
}));

const BlockThree = () => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const [selectedBoardIndex, setSelectedBoardIndex,] = useState<number>(1);
  const [selectedBoardData, setSelectedBoardData,] = useState<any>(BoardData[0]);
  const [emblaRef, emblaApi,] = useEmblaCarousel({
    loop: false,
    align: 'start',
    draggable: true,
  });

  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  const handleSelectBoardCard = (id: number) => {
    setSelectedBoardIndex(id);
    setSelectedBoardData(BoardData[id - 1]);
  };
  const scrollPrev = () => {
    emblaApi && emblaApi.scrollPrev();
  };

  const scrollNext = () => {
    emblaApi && emblaApi.scrollNext();
  };
  return (
    <Box
      id="board"
      className={classes.container}
    >
      <Box className={classes.topFilter} />
      <Box className={classes.title}>
        <Typography
          variant="h2"
          fontSize={isMobile ? '24px' : '40px'}
          fontWeight="700"
          color={theme.palette.secondary.dark}
          mb={3}
        >
          <FormattedMessage id="investor.blockthree.title" />
        </Typography>
      </Box>
      {/* 上方被選取的資料 */}
      <Box className={classes.boardContainer}>
        {/* 左側圖片與職稱 */}
        <Box
          display="flex"
          flexDirection={isMobile ? 'row' : 'column'}
          gap={isMobile ? '24px' : '0px'}
        >
          <Box
            className={classes.mainImage}
            mb={2}
          >
            <img
              src={selectedBoardData.image ?? DefaultImg}
              alt={intl.formatMessage({ id: selectedBoardData.name, })}
            />
            <Box className={classes.mainImageFilter} />
          </Box>
          <Box>
            <Typography
              variant="h2"
              fontSize={isMobile ? '24px' : '40px'}
              fontWeight="500"
              color={theme.palette.secondary.light}
            >
              <FormattedMessage id={selectedBoardData.name} />
            </Typography>
            <Typography
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight="400"
              color={theme.palette.secondary.light}
            >
              <FormattedMessage id={selectedBoardData.title} />
            </Typography>
          </Box>
        </Box>
        {/* 右側描述 */}
        <Box width="100%">
          <Box mb={3}>
            <Typography
              variant="h3"
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight="700"
              mb={1}
            >
              <FormattedMessage id="investor.blockthree.incumbent" />
            </Typography>
            {selectedBoardData.incumbent && (
              <Typography
                fontSize={isMobile ? '14px' : '16px'}
                color={theme.palette.secondary.dark}
                sx={{ opacity: '0.85', }}
                whiteSpace="pre-line"
              >
                <FormattedMessage id={selectedBoardData.incumbent} />
              </Typography>
            )}
          </Box>
          <Box mb={3}>
            {selectedBoardData.serve && (
              <Typography
                variant="h3"
                fontSize={isMobile ? '14px' : '16px'}
                fontWeight="700"
                mb={1}
              >
                <FormattedMessage id="investor.blockthree.serve" />
              </Typography>
            )}
            <Typography
              fontSize={isMobile ? '14px' : '16px'}
              color={theme.palette.secondary.dark}
              sx={{ opacity: '0.85', }}
              whiteSpace="pre-line"
            >
              {selectedBoardData.serve && <FormattedMessage id={selectedBoardData.serve} />}
            </Typography>
          </Box>
          <Box mb={3}>
            {selectedBoardData.educationalBackground && (
              <Typography
                variant="h3"
                fontSize={isMobile ? '14px' : '16px'}
                fontWeight="700"
                mb={1}
              >
                <FormattedMessage id="investor.blockthree.educationalBackground" />
              </Typography>
            )}
            {selectedBoardData.educationalBackground && (
              <Typography
                fontSize={isMobile ? '14px' : '16px'}
                color={theme.palette.secondary.dark}
                sx={{ opacity: '0.85', }}
                whiteSpace="pre-line"
              >
                <FormattedMessage id={selectedBoardData.educationalBackground} />
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {/* 下方董事的資料 */}
      <Box className={classes.slideButton}>
        <Box className={classes.paginationButton}>
          <PaginationButton
            color="green"
            reverse="180"
            onClick={scrollPrev}
          />
        </Box>
        <Box ref={emblaRef} className={classes.boardWrap}>
          <Box className={classes.boardList}>
            {BoardData.map((item: any) => (
              <Box
                key={item.id}
                className={clsx([classes.boardCard, selectedBoardIndex === item.id && classes.boardCardSelected,])}
                onClick={() => handleSelectBoardCard(item.id)}
              >
                <Box
                  className={classes.secondImage}
                  style={{ transform: selectedBoardIndex === item.id ? 'scale(1.1)' : 'scale(1)', }}
                >
                  {selectedBoardIndex === item.id && <Box className={classes.secondImageFilter} />}
                  <img
                    src={item.image ?? DefaultImg}
                    alt={intl.formatMessage({ id: item.name, })}
                  />
                </Box>
                <Typography
                  fontSize={isMobile ? '14px' : '16px'}
                  fontWeight="700"
                  color={theme.palette.secondary.light}
                >
                  <FormattedMessage id={item.name} />
                </Typography>
              </Box>
            ))}

          </Box>
        </Box>
        <Box className={classes.paginationButton}>
          <PaginationButton
            color="green"
            onClick={scrollNext}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(BlockThree);