import React, {
  useState, useEffect, useRef,
} from 'react';
import { useNavigate, } from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  Box, Theme, Typography, Button,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import {
  getNews, getGlobal,
} from '@/reducers/states';
import ScrollAnimation from 'react-animate-on-scroll';
import useMediaQuery from '@mui/material/useMediaQuery';
import BackgroudImage from '../assets/Block1/background.png';
import BackgroudVideo from '../assets/Block1/Block1-background-video.mp4';
import PaginationButton from '@/components/PaginationButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  videoBackground: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  titleContainer: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '70%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '223px',
  },
  title1: {
    textShadow: '0 0 24px rgba(0, 0, 0, 0.2)',
    color: theme.palette.secondary.main,
    animationName: '$goUp',
    animationDuration: '1s',
    animationTimingFunction: 'ease',
    animationFillMode: 'backwards',
    lineHeight: '1.35',
  },
  zhTitle2: {
    textShadow: '0 0 24px rgba(0, 0, 0, 0.2)',
    color: theme.palette.secondary.main,
    letterSpacing: '10px',
    '&:first-letter': { color: theme.palette.primary.light, },
    animationName: '$goUp',
    animationDuration: '1s',
    animationTimingFunction: 'ease',
    animationFillMode: 'backwards',
    lineHeight: '1.35',
  },
  enTitle2: {
    textShadow: '0 0 24px rgba(0, 0, 0, 0.2)',
    color: theme.palette.secondary.main,
    animationName: '$goUp',
    animationDuration: '1s',
    animationTimingFunction: 'ease',
    animationDelay: '3.2s',
    animationFillMode: 'backwards',
    lineHeight: '1.35',
  },
  zhTitle3: {
    color: theme.palette.secondary.main,
    letterSpacing: '10px',
    unicodeBidi: 'bidi-override',
    direction: 'rtl',
    textShadow: '0 0 24px rgba(0, 0, 0, 0.2)',
    '&:first-letter': { color: theme.palette.primary.light, },
    animationName: '$goUp',
    animationDuration: '1s',
    animationTimingFunction: 'ease',
    animationFillMode: 'backwards',
    lineHeight: '1.35',
  },
  enTitle3: {
    color: theme.palette.secondary.main,
    textShadow: '0 0 24px rgba(0, 0, 0, 0.2)',
    animationName: '$goUp',
    animationDuration: '1s',
    animationTimingFunction: 'ease',
    animationFillMode: 'backwards',
    lineHeight: '1.35',
  },
  anchorContainer: {
    width: '100%',
    height: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '26px',
    position: 'absolute',
    bottom: '0',
  },
  jumpIcon: { animation: '$jump 0.6s alternate infinite', },
  '@keyframes jump': { to: { transform: 'translateY(-10px)', }, },
  '@keyframes goUp': {
    from: {
      opacity: '0',
      transform: 'translateY(50px)',
    },
    to: {
      opacity: '1',
      transform: 'translateY(0px)',
    },
  },
  [theme.breakpoints.down(800)]: { titleContainer: { paddingTop: '212px', }, },
}));

const BlockOne = ({ anchorOnClick, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <Box
      className={classes.container}
      style={{ backgroundImage: `url(${isMobile ? BackgroudImage : undefined})`, }}
    >
      {!isMobile && (
        <Box className={classes.videoBackground}>
          <video
            autoPlay
            muted
            src={BackgroudVideo}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      )}
      <Box className={classes.titleContainer}>
        <Box
          className={classes.title1}
          style={{ animationDelay: isMobile ? '0s' : '2.5s', }}
          fontSize={isMobile ? '24px' : '2.8vw'}
        >
          <FormattedMessage id={'homepage.blockone.title.1'} />
        </Box>
        <Box
          className={locale === 'en_us' ? classes.enTitle2 : classes.zhTitle2}
          style={{ animationDelay: isMobile ? '0s' : '3.2s', }}
          fontSize={isMobile ? '40px' : '4.6vw'}
          fontWeight={'bold'}
        >
          <FormattedMessage id={'homepage.blockone.title.2'} />
        </Box>
        <Box
          className={locale === 'en_us' ? classes.enTitle3 : classes.zhTitle3}
          style={{ animationDelay: isMobile ? '0s' : '3.2s', }}
          fontSize={isMobile ? '40px' : '4.6vw'}
          fontWeight={'bold'}
        >
          <FormattedMessage id={'homepage.blockone.title.3'} />
        </Box>
      </Box>
      <Box className={classes.anchorContainer}>
        <Box className={classes.jumpIcon}>
          <PaginationButton
            onClick={anchorOnClick}
            reverse="90"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BlockOne;