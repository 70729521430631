import React, {
  useState, useEffect, useRef,
} from 'react';
import { useNavigate, } from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  Box, Theme, Typography, Button, Drawer,
} from '@mui/material';
import {
  FormattedMessage, useIntl,
} from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import { getImage, } from '@/utils/tool';
import PaginationButton from '@/components/PaginationButton';
import CardData from '../Block3CardData.json';
import DrawerData from '../Block3DrawerData.json';
import DrawerBackground from '../assets/Block3/drawer-background.png';
import { ReactComponent as CloseIcon, } from '../assets/Block3/icon-close.svg';
import { ReactComponent as Circle, } from '../assets/Block3/circle.svg';
import { ReactComponent as OuterCircleBorder, } from '../assets/Block3/outer-circle-border.svg';
import { ReactComponent as InnerCircleBorder, } from '../assets/Block3/inner-circle-border.svg';
import { getGlobal, } from '../../../reducers/states';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '900px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  titleContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '80px',
    pointerEvents: 'none',
  },
  title: { color: theme.palette.secondary.main, },
  description: {
    width: '600px',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  cardsContainer: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
  },
  cardContainer: {
    width: '25%',
    height: '900px',
    cursor: 'pointer',
  },
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
    paddingBottom: '285px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    userSelect: 'none',
    '&:hover': {
      backgroundImage: 'linear-gradient(270.14deg, rgba(50, 115, 182, 0.65) 0.12%, rgba(50, 115, 182, 0.85) 99.89%)',
      cursor: 'pointer',
      '& $circleContainer': {
        '& $circle': {
          backgroundColor: theme.palette.primary.main,
          '& path': { fill: theme.palette.primary.main, },
        },
        '& $circleBorder': { opacity: '1', },
        '& $buttonText': { opacity: '1', },
      },
    },
  },
  circleContainer: {
    position: 'relative',
    width: '150px',
    height: '150px',
  },
  circle: { borderRadius: '50%', },
  circleBorder: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: '0',
  },
  cardTitle: {
    position: 'absolute',
    width: '96px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  buttonText: {
    position: 'absolute',
    bottom: '-80%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main,
    opacity: '0',
  },
  drawerContainer: {
    position: 'relative',
    height: '574px',
    padding: '87px 117px 127px 87px',
    borderRadius: '20px 20px 0 0',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'white',
    display: 'flex',
    gap: '80px',
  },
  drawerDescription: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    right: '32px',
    top: '32px',
    cursor: 'pointer',
  },
  paginationButton: {
    marginTop: '16px',
    display: 'flex',
    gap: '16px',
  },
  [theme.breakpoints.down(1300)]: {
    container: { height: '100%', },
    titleContainer: { padding: '24px', },
    description: { width: '100%', },
    cardsContainer: {
      flexDirection: 'column',
      position: 'unset',
    },
    cardContainer: {
      width: '100%',
      height: '100%',
    },
    card: {
      width: '100%',
      height: '500px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '16px',
      paddingBottom: '0',
      '& $buttonText': { opacity: '1', },
    },
    buttonText: { bottom: '-60%', },
    drawerContainer: {
      height: '100vh',
      padding: '72px 24px 16px 24px',
      borderRadius: '0',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '24px',
      overflowY: 'auto',
    },
    drawerDescription: { height: 'fit-content', },
    closeIcon: {
      top: '20px',
      right: '20px',
    },
    paginationButton: { marginTop: '16px', },
  },
}));

const Card = ({
  title, background, onClick,
}: any) => {
  const classes = useStyles();
  const [isHovering, setIsHovering,] = useState(false);

  const backgroundImage = isHovering
    ? `linear-gradient(270.14deg, rgba(50, 115, 182, 0.65) 0.12%, rgba(50, 115, 182, 0.85) 99.89%),
  url(${getImage(background)})`
    : `linear-gradient(269deg, rgba(9, 68, 129, 0.45) 0.12%, rgba(9, 68, 129, 0.65) 99.89%),
  url(${getImage(background)})`;

  return (
    <Box
      className={classes.cardContainer}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Box
        className={classes.card}
        style={{ backgroundImage: backgroundImage, }}
      >
        <Box className={classes.circleContainer}>
          <OuterCircleBorder className={classes.circleBorder} />
          <InnerCircleBorder className={classes.circleBorder} />
          <Circle className={classes.circle} />
          <Typography
            className={classes.cardTitle}
            fontSize={'24px'}
            fontWeight={'bold'}
          >
            <FormattedMessage id={title} />
          </Typography>
          <Typography
            className={classes.buttonText}
            fontSize={'16px'}
          >
            <FormattedMessage id={'service.blockthree.button_text'} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const BlockThree = ({ id, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen,] = useState(false);
  const [currentDrawer, setCurrentDrawer,] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const intl = useIntl();
  const { locale, } = useSelector(getGlobal);

  return (
    <div
      id={id}
      className={classes.container}
    >
      <Box className={classes.cardsContainer}>
        {CardData.map((item, index) => {
          return (
            <Card
              key={index}
              title={item.title}
              background={item.background}
              onClick={() => {
                setIsDrawerOpen(true);
                setCurrentDrawer(index);
              }}
            />
          );
        })}

        <ScrollAnimation
          className={classes.titleContainer}
          animateIn="fadeInDown"
        >
          <Typography
            className={classes.title}
            fontSize={isMobile ? '24px' : '40px'}
            fontWeight={'bold'}
            marginBottom={'24px'}
          >
            <FormattedMessage id={'service.blockthree.title'} />
          </Typography>
          <Typography
            className={classes.description}
            fontSize={isMobile ? '14px' : '16px'}
          >
            <FormattedMessage id={'service.blockthree.description'} />
          </Typography>
        </ScrollAnimation>
      </Box>

      {/* 彈窗 */}
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        PaperProps={{
          elevation: 0,
          style: { backgroundColor: 'transparent', },
        }}
      >
        <Box
          className={classes.drawerContainer}
          style={{ backgroundImage: `url(${DrawerBackground})`, }}
        >
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => {
              setIsDrawerOpen(false);
            }}
          />
          <img
            width={isMobile ? '100%' : '600px'}
            src={getImage(DrawerData[currentDrawer].img)}
            alt={intl.formatMessage({ id: DrawerData[currentDrawer].alt, })}
          />
          <Box>
            <Box className={classes.drawerDescription}>
              {/* 流程名稱 */}
              <Typography
                fontSize={isMobile ? '16px' : '24px'}
                fontWeight={'bold'}
              >
                <FormattedMessage id={DrawerData[currentDrawer].title} />
              </Typography>

              {/* 流程內容介紹 */}
              {DrawerData[currentDrawer].description.map((descriptionItem: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <Typography
                      fontSize={isMobile ? '14px' : '16px'}
                      marginTop={'16px'}
                      marginBottom={'16px'}
                    >
                      <FormattedMessage id={descriptionItem.main} />
                    </Typography>
                    {descriptionItem.introduction.map((introductionItem: any, index: any) => {
                      return (
                        <Typography
                          key={index}
                          fontSize={isMobile ? '14px' : '16px'}
                        >
                          <FormattedMessage id={introductionItem.text} />
                        </Typography>
                      );
                    })}
                    <Box
                      display="flex"
                      marginTop="16px"
                    >
                      <Typography fontSize={isMobile ? '14px' : '16px'}>
                        <FormattedMessage id={descriptionItem.machine.title} />
                      </Typography>
                      <a
                        href={descriptionItem.machine.link}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: 'none',
                          color: theme.palette.primary.main,
                        }}
                      >
                        <Typography fontSize={isMobile ? '14px' : '16px'}>
                          <FormattedMessage id={descriptionItem.machine.button_text} />
                        </Typography>
                      </a>
                    </Box>
                    <Typography fontSize={isMobile ? '14px' : '16px'}>
                      {
                        locale?.replace(/_[a-zA-Z]+/, '') === 'zh' ? <>
                          <FormattedMessage id={descriptionItem.machine.name_en} />
                          <br />
                          <FormattedMessage id={descriptionItem.machine.name_zh} />
                        </> : <FormattedMessage id={descriptionItem.machine.name_en} />
                      }
                    </Typography>
                  </React.Fragment>
                );
              })}
            </Box>

            {/* 上下步驟按鈕 */}
            <Box className={classes.paginationButton}>
              {currentDrawer !== 0 && (
                <PaginationButton
                  color="green"
                  reverse="180"
                  textPosition="right"
                  onClick={() => setCurrentDrawer(currentDrawer - 1)}
                ></PaginationButton>
              )}
              {currentDrawer !== DrawerData.length - 1 && (
                <PaginationButton
                  color="green"
                  reverse="0"
                  onClick={() => setCurrentDrawer(currentDrawer + 1)}
                ></PaginationButton>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default BlockThree;