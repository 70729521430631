import React, {
  useState, useEffect,
} from 'react';
import {
  useNavigate, Link, useLocation,
} from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  Box, Theme, Drawer,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import path from './path.json';
import MobileHeader from './MobileHeader';
import HeaderButton from './component/HeaderButton';
import LanguageSwicher from './component/LanguageSwicher';
import ApexLogo from './assets/apex-logo.png';
import MenuIcon from './assets/header-menu-icon.png';
import HksSteelLogo from './assets/hkssteel-red-logo.png';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    height: '72px',
    color: 'white',
    paddingLeft: '40px',
    paddingRight: '40px',
    transition: 'top 0.25s ease-out',
    position: 'fixed',
    zIndex: '100',
  },
  navigate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
  },
  checkBoxWrapper: {
    width: '80px',
    height: '24px',
    position: 'relative',
    cursor: 'pointer',
  },
  checkBoxLabel: {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    width: '80px',
    height: '24px',
    borderRadius: '4px',
    background: theme.palette.secondary.dark,
    userSelect: 'none',
    '&:after': {
      content: '""',
      display: 'block',
      borderRadius: '4px 0px 0px 4px',
      width: '40px',
      height: '24px',
      background: theme.palette.primary.main,
      transition: '0.2s',
    },
  },
  checkBox: {
    opacity: '0',
    zIndex: '3',
    borderRadius: '4px',
    width: '80px',
    height: '24px',
    '&:checked + $checkBoxLabel': { '&:after': {
      content: '""',
      display: 'block',
      borderRadius: '0px 4px 4px 0px',
      width: '40px',
      height: '24px',
      background: theme.palette.primary.main,
      marginLeft: '40px',
      transition: '0.2s',
    }, },
  },
  menuIcon: { cursor: 'pointer', },
}));

// custom hook 判斷user正在往上滑還是往下滑
function useScrollDirection() {
  const [scrollDirection, setScrollDirection,] = useState<any>(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener('scroll', updateScrollDirection);

    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, [scrollDirection,]);

  return scrollDirection;
}

const Header = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const scrollDirection = useScrollDirection();
  const [scrollTop, setScrollTop,] = useState(true);
  const [isOpenMenu, setIsOpenMenu,] = useState(false);
  const isMobile = useMediaQuery('(min-width:1100px)');
  const { locale, } = useSelector(getGlobal);

  // 判斷是否滑到頁面最上方
  useEffect(() => {
    const detectScrollTop = () => {
      const HEADER_HEIGHT = 70;
      const isTop = window.scrollY > HEADER_HEIGHT;
      if (isTop) {
        setScrollTop(false);
      } else {
        setScrollTop(true);
      }
    };
    window.addEventListener('scroll', detectScrollTop);
    return () => {
      window.removeEventListener('scroll', detectScrollTop);
    };
  }, []);

  // 路由改變時，Mobile關閉Header
  useEffect(() => {
    setIsOpenMenu(false);
  }, [location,]);

  useEffect(() => {
    if (isMobile) {
      setIsOpenMenu(false);
    }
  }, [isMobile,]);

  return (
    <header>
      <Box
        className={classes.headerContainer}
        top={scrollDirection !== 'down' ? '0px' : '-80px'}
      >
        {/* left area */}
        <Box
          display="flex"
          justifyContent="space-between"
          gap="32px"
        >
          {/* Apex Logo */}
          <Link to={locale + '/'}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ textDecoration: 'none', }}
              onClick={() => {
                navigate('/');
              }}
            >
              <img
                src={ApexLogo}
                width="40px"
                height="32px"
                alt="apex-logo"
              />
            </Box>
          </Link>

          {/* nav button */}
          <nav className={classes.navigate}>
            {isMobile &&
              path.map((item, index) => {
                return (
                  <HeaderButton
                    key={index}
                    item={item}
                    scrollTop={scrollTop}
                    path={item.path}
                  >
                    <FormattedMessage id={item.title} />
                  </HeaderButton>
                );
              })}
          </nav>

          {/* language switcher */}
          {isMobile && <LanguageSwicher />}
        </Box>

        {/* right area */}
        {/* 新光鋼Logo */}
        {isMobile ? (
          <a
            href="https://www.hkssteel.com.tw/"
            target="_blank"
            rel="noreferrer"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={HksSteelLogo}
                width="72px"
                height="34px"
                alt="hkssteel-logo"
              />
            </Box>
          </a>
        ) : (
          <Box
            className={classes.menuIcon}
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => setIsOpenMenu(true)}
          >
            <img
              src={MenuIcon}
              alt="menu-icon"
            />
          </Box>
        )}
      </Box>

      {/* 小網 header */}
      <Drawer
        open={isOpenMenu}
        hideBackdrop={true}
        transitionDuration={0}
      >
        <MobileHeader onClose={() => setIsOpenMenu(false)} />
      </Drawer>
    </header>
  );
};

export default Header;