import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import useEmblaCarousel, { EmblaOptionsType, } from 'embla-carousel-react';
import {
  Box, Theme, Typography, Button,
} from '@mui/material';
import { Link, } from 'react-router-dom';
import {
  FormattedMessage, useIntl,
} from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import ScrollAnimation from 'react-animate-on-scroll';
import Autoplay from '@/utils/embla-carousel-autoplay/autoPlay';
import { getImage, } from '@/utils/tool';
import PaginationButton from '@/components/PaginationButton';
import RoundedButton from '@/components/RoundedButton';
import mockData from '../Block2SliderData.json';
import BackgroudImage from '../assets/Block2/background.png';
import TextButton from '@/components/TextButton';
import Path from '../../../plugins/path.json';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '80px 0',
  },
  anchorBar: {
    display: 'flex',
    alignItems: 'center',
    height: '68px',
    padding: '24px',
    marginBottom: '60px',
    fontSize: '14px',
    width: '100%',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': { display: 'none', },
  },
  titleContainer: {
    width: '850px',
    margin: '0 auto 0 220px',
    height: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '24px',
  },
  slideButtons: {
    display: 'flex',
    maxWidth: '100%',
    margin: '24px auto 0 220px',
    gap: '16px',
  },
  slideContainer: { overflow: 'hidden', },
  slides: {
    display: 'flex',
    paddingTop: '40px',
  },
  slide: {
    flex: '0 0 100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '1000px',
    height: '400px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.5s ease-out',
    gap: '40px',
    padding: '50px 0',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '20px',
  },
  slideInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    maxWidth: '300px',
    height: '100%',
    padding: '50px 0',
  },
  slideButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '50px',
    gap: '16px',
  },
  [theme.breakpoints.down(1100)]: {
    container: { padding: '0px 24px 60px', },
    titleContainer: {
      width: '100%',
      height: '30%',
      margin: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '24px',
      textAlign: 'center',
    },
    slideButtons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      width: '100%',
      margin: '50px 0 0 0',
      gap: '16px',
    },
    card: {
      width: '342px',
      height: 'fit-contect',
      padding: '18px 16px',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      gap: '0px',
    },
    slideInfo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      maxWidth: '100%',
      height: '100%',
      padding: '0',
    },
  },
  [theme.breakpoints.down(400)]: { card: { width: '100%', }, },
}));

const servicePageAnchors = Path.find((item, index) => item.path === '/service');

const SlideCard = ({
  title, description, alt, imgPath, style,
}: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1100));

  return (
    <Box
      className={classes.card}
      style={style}
    >
      <img
        width={isLittleDesktop ? '100%' : '500px'}
        src={getImage(imgPath)}
        alt={alt}
      />
      <Box className={classes.slideInfo}>
        <Typography
          fontSize={isLittleDesktop ? '16px' : '24px'}
          marginBottom={isLittleDesktop ? '12px' : '24px'}
          fontWeight={'bold'}
          color={theme.palette.secondary.dark}
        >
          <FormattedMessage id={title} />
        </Typography>
        <Typography
          fontSize={isLittleDesktop ? '14px' : '16px'}
          color={theme.palette.secondary.dark}
          overflow="auto"
        >
          <FormattedMessage id={description} />
        </Typography>
      </Box>
    </Box>
  );
};

const options = {
  delay: 5000,
  stopOnInteraction: false, // 拖動後是否停止自動輪播
}; // 自動輪播設定

const autoplay = Autoplay(options);

const BlockTwo = ({ id, }: any) => {
  const classes = useStyles();
  const [emblaRef, emblaApi,] = useEmblaCarousel({
    loop: true,
    containScroll: 'keepSnaps',
  }, [autoplay,]);
  const [currentSlideIndex, setCurrentSlideIndex,] = useState<any>(0);
  const theme = useTheme();
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1100));
  const isMobile = useMediaQuery(theme.breakpoints.down(400));
  const intl = useIntl();

  const isShowAnchor = useMediaQuery(theme.breakpoints.down('md'));

  const anchorRef = useRef<any>(null);
  const [anchorCenter, setAnchorCenter,] = useState(false);

  // 判斷 anchor bar 是否需要置中（如果需要 scroll 的話靠左，沒有的話置中）
  useEffect(() => {
    if (!!anchorRef.current) {
      const anchorContainerWidth = anchorRef.current.clientWidth;
      let anchorItemsTotalWidth = 0;
      for (const item of anchorRef.current.children) {
        anchorItemsTotalWidth += item.clientWidth;
      }
      if (anchorContainerWidth > anchorItemsTotalWidth) {
        setAnchorCenter(true);
      }
    }
  }, []);

  const scrollPrev = () => {
    emblaApi && emblaApi.scrollPrev();
    autoplay.reset();
  };

  const scrollNext = () => {
    emblaApi && emblaApi.scrollNext();
    autoplay.reset();
  };

  const scrollTo = (index: any) => {
    emblaApi && emblaApi.scrollTo(index);
  };

  useEffect(() => {
    const onSlideChange = () => {
      setCurrentSlideIndex(emblaApi?.selectedScrollSnap());
    };
    emblaApi?.on('select', onSlideChange);
    return () => {
      emblaApi?.off('select', onSlideChange);
    };
  }, [emblaApi,]);

  useEffect(() => {
    scrollTo(currentSlideIndex);
    autoplay.reset();
  }, [currentSlideIndex,]);

  return (
    <div
      id={id}
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroudImage})`, }}
    >
      {isShowAnchor && (
        <Box
          ref={anchorRef}
          className={classes.anchorBar}
          sx={{ justifyContent: `${anchorCenter ? 'center' : 'flex-start'}`, }}
        >
          {servicePageAnchors?.menu?.map((item: any, index: any) => {
            return (
              <Link
                key={index}
                to={item.anchor}
                style={{
                  textDecoration: 'none',
                  color: theme.palette.secondary.dark,
                  borderLeft: index !== 0 ? `2px solid ${theme.palette.primary.main}` : '',
                  padding: '0 24px',
                }}
              >
                <TextButton>
                  <FormattedMessage id={item.title} />
                </TextButton>
              </Link>
            );
          })}
        </Box>
      )}
      <ScrollAnimation
        className={classes.titleContainer}
        animateIn="fadeInDown"
      >
        <Typography
          fontSize={isLittleDesktop ? '24px' : '40px'}
          fontWeight={'bold'}
          color={theme.palette.secondary.dark}
        >
          <FormattedMessage id={'service.blocktwo.title'} />
        </Typography>
        <Typography
          fontSize={'16px'}
          color={theme.palette.secondary.dark}
        >
          <FormattedMessage id={'service.blocktwo.description'} />
        </Typography>
      </ScrollAnimation>
      <Box className={classes.slideButtons}>
        {mockData.map((slideItem, index) => {
          return (
            <Box key={index}>
              <RoundedButton
                height={40}
                borderRadius={24}
                fontSize={16}
                variant="outlined"
                selected={currentSlideIndex === index}
                onClick={() => {
                  setCurrentSlideIndex(index);
                }}
              >
                <FormattedMessage id={slideItem.button_text} />
              </RoundedButton>
            </Box>
          );
        })}
      </Box>
      <Box
        className={classes.slideContainer}
        ref={emblaRef}
      >
        <Box className={classes.slides}>
          {mockData.map((slideItem, index) => {
            const formatAlt = intl.formatMessage({ id: slideItem.alt, });
            const background = isLittleDesktop ? slideItem.little_background : slideItem.background;

            return (
              <div
                key={index}
                className={classes.slide}
              >
                <SlideCard
                  key={index}
                  title={slideItem.title}
                  description={slideItem.description}
                  imgPath={slideItem.img}
                  alt={formatAlt}
                  style={{
                    backgroundImage: `url(${getImage(background)})`,
                    display: 'flex',
                    flexDirection: isLittleDesktop ? 'column' : 'row',
                    justifyContent: isLittleDesktop ? 'flex-start' : 'center',
                  }}
                />
              </div>
            );
          })}
        </Box>
      </Box>
      <Box className={classes.slideButton}>
        <PaginationButton
          onClick={scrollPrev}
          color="green"
          reverse="180"
        />
        <PaginationButton
          onClick={scrollNext}
          color="green"
        />
      </Box>
    </div>
  );
};

export default BlockTwo;