import React from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme, Typography, Divider,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { useTheme, } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({ container: {
  padding: '120px 120px 0 120px',
  marginBottom: '120px',
  [theme.breakpoints.down('md')]: { padding: '60px 24px 0px 24px', },
}, }));

const Content = [
  {
    title: 'privacy.page.content.title1',
    content: 'privacy.page.content.content1',
  },
  {
    title: 'privacy.page.content.title2',
    content: 'privacy.page.content.content2',
  },
  {
    title: 'privacy.page.content.title3',
    content: 'privacy.page.content.content3',
  },
  {
    title: 'privacy.page.content.title4',
    content: 'privacy.page.content.content4',
  },
  {
    title: 'privacy.page.content.title5',
    content: 'privacy.page.content.content5',
  },
  {
    title: 'privacy.page.content.title6',
    content: 'privacy.page.content.content6',
  },
  {
    title: 'privacy.page.content.title7',
    content: 'privacy.page.content.content7',
  },
];

const BlockTwo = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box className={classes.container}>
      <Typography
        fontSize={40}
        fontWeight={700}
        color={theme.palette.secondary.dark}
        mb={2}
      >
        <FormattedMessage id="privacy.page.title" />
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={500}
        color={theme.palette.secondary.dark}
        sx={{ opacity: 0.45, }}
        mb={7}
      >
        <FormattedMessage id="privacy.page.date" />
      </Typography>
      <Typography
        fontSize={16}
        fontWeight={400}
        color={theme.palette.secondary.dark}
        mb={4}
        sx={{ opacity: 0.85, }}
      >
        <FormattedMessage id="privacy.page.description" />
      </Typography>
      {Content.map((item) => (
        <Box key={item.title}>
          <Typography
            fontSize={16}
            fontWeight={700}
            color={theme.palette.secondary.dark}
            mb={2}
          >
            <FormattedMessage id={item.title} />
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={400}
            color={theme.palette.secondary.dark}
            mb={4}
            sx={{ opacity: 0.85, }}
          >
            <FormattedMessage
              id={item.content}
              values={{
                p: (...chunks) => <p>{chunks}</p>,
                br: <br />,
              }}
            />
          </Typography>
        </Box>
      ))}
      <Typography
        fontSize={16}
        fontWeight={400}
        color={theme.palette.secondary.dark}
        sx={{ opacity: 0.45, }}
      >
        <FormattedMessage id="privacy.page.footer.description1" />
      </Typography>
      <Typography
        fontSize={16}
        fontWeight={400}
        color={theme.palette.secondary.dark}
        sx={{ opacity: 0.45, }}
      >
        <FormattedMessage id="privacy.page.footer.description2" />
      </Typography>
    </Box>
  );
};

export default React.memo(BlockTwo);