import React, {
  useState, useEffect, useRef,
} from 'react';
import { useNavigate, } from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  Box, Theme, Typography, Button, Divider,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import PaginationButton from '@/components/PaginationButton';
import BackgroudImage from '../assets/Block6/background.png';
import SectionImage from '../assets/Block6/section.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '120px',
    [theme.breakpoints.down(800)]: { padding: '60px 24px', },
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '90px',
    [theme.breakpoints.down(1200)]: {
      flexDirection: 'column',
      gap: '40px',
    },
    '& img': {
      width: '100%',
      maxWidth: '600px',
      height: 'auto',
      [theme.breakpoints.down(1200)]: { width: '100%', },
    },
  },
  sectionInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
    maxWidth: '450px',
    [theme.breakpoints.down(1200)]: { maxWidth: '100%', },
  },
}));

const BlockSix = ({ id, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <div
      id={id}
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroudImage})`, }}
    >
      <Box className={classes.section}>
        <img src={SectionImage} />
        <Box className={classes.sectionInfo}>
          <ScrollAnimation animateIn="fadeInRight">
            <Typography
              fontSize={isMobile ? '24px' : '40px'}
              fontWeight={'bold'}
              color={theme.palette.secondary.dark}
            >
              <FormattedMessage id={'about.blocksix.title'} />
            </Typography>
            <Typography
              fontSize={isMobile ? '14px' : '16px'}
              color={theme.palette.secondary.dark}
            >
              <FormattedMessage id={'about.blocksix.description'} />
            </Typography>
          </ScrollAnimation>
          <a
            href="https://www.104.com.tw/company/bijt468"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', }}
          >
            <PaginationButton color="green">
              <FormattedMessage id={'about.blocksix.button'} />
            </PaginationButton>
          </a>
        </Box>
      </Box>
    </div>
  );
};

export default BlockSix;