import {
  type FC, type ComponentProps, useState, useEffect, ReactNode,
} from 'react';
import {
  Box,
  type Theme,
  Typography,
  styled,
  type SxProps,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { getImage, } from '@/utils/tool';

interface ManagerData {
  id: number;
  avatar?: string;
  name: ReactNode;
  title: ReactNode;
}

const managerData: ManagerData[] = [
  {
    id: 1,
    avatar: 'service/assets/Block7/ceo.png',
    name: <FormattedMessage id="service.block7.manager1.name" />,
    title: <FormattedMessage id="service.block7.manager1.title" />,
  },
  {
    id: 2,
    avatar: 'service/assets/Block7/factory-director.png',
    name: <FormattedMessage id="service.block7.manager2.name" />,
    title: <FormattedMessage id="service.block7.manager2.title" />,
  },
  {
    id: 3,
    avatar: 'service/assets/Block7/deputy-director.png',
    name: <FormattedMessage id="service.block7.manager3.name" />,
    title: <FormattedMessage id="service.block7.manager3.title" />,
  },
  {
    id: 4,
    avatar: 'service/assets/Block7/business-executives.png',
    name: <FormattedMessage id="service.block7.manager4.name" />,
    title: <FormattedMessage id="service.block7.manager4.title" />,
  },
];

const ImageWrapper = styled('div')(({ theme, }) => ({
  borderRadius: '12px',
  overflow: 'hidden',
  background:
      'linear-gradient(165.95deg, rgba(50, 115, 182, 0) 55.21%, rgba(50, 115, 182, 0.25) 92.91%)',
  aspectRatio: '1 / 1',
}));

const Image = styled('img')(({ theme, }) => ({
  width: '100%',
  objectFit: 'cover',
}));

const Avatar: FC<{ src: string; alt: string; sx?: SxProps<Theme> }> = ({
  src,
  alt,
  sx,
}) => {
  return (
    <ImageWrapper sx={sx}>
      <Image
        src={src}
        alt={alt}
      />
    </ImageWrapper>
  );
};

const Manager: FC<{ data: ManagerData }> = ({ data, }) => {
  const {
    avatar, name, title,
  } = data;
  return (
    <Box sx={[{
      display: 'flex',
      flexDirection: 'column',
    },]}>
      <Avatar
        sx={[{
          maxWidth: '160px', width: '100%', marginBottom: '24px',
        }, (theme) => ({ [theme.breakpoints.up('lg')]: {
          maxWidth: '250px', width: '100%',
        }, }),]}
        src={avatar ? getImage(avatar) : getImage('service/assets/Block7/default-avatar.png')}
        alt={typeof name === 'string' ? name : 'avatar'} />
      <Typography sx={[{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '33px',
        color: '#3273B6',
        textAlign: 'left',
      }, (theme) => ({ [theme.breakpoints.up('lg')]: {
        fontSize: '40px',
        lineHeight: '54px',
      }, }),]}>
        {name}
      </Typography>
      <Typography sx={[{
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '19px',
        color: '#3273B6',
        textAlign: 'left',
      }, (theme) => ({
        fontSize: '16px',
        lineHeight: '22px',
      }),]}>
        {title}
      </Typography>
    </Box>
  );
};

const ManagerList: FC<{ data: ManagerData[] }> = ({ data, }) => {
  return (
    <Box sx={[{
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      alignItems: 'center',
      textAlign: 'center',
      gap: '32px',
    }, (theme) => ({ [theme.breakpoints.up('lg')]: { gridTemplateColumns: 'repeat(4, minmax(0, 1fr))', }, }),]}>
      {data.map((item) => <Manager key={item.id} data={item} />)}
    </Box>
  );
};

const Block7: FC<ComponentProps<typeof Box>> = ({ ...props }) => {
  return (
    <Box id="key_managers" sx={[{
      background: 'linear-gradient(180deg, rgba(50, 115, 182, 0.1) 0%, rgba(133, 193, 31, 0) 53.12%)',
      minHeight: '785px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingY: '80px',
      paddingX: '24px',
    },]} {...props}>
      <Typography sx={[{
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '33px',
        marginBottom: '40px',
      }, (theme) => ({ [theme.breakpoints.up('lg')]: {
        fontSize: '40px',
        lineHeight: '54px',
      }, }),]}>
        <FormattedMessage id="service.block7.title" />
      </Typography>
      <ManagerList data={managerData} />
    </Box>
  );
};

export default Block7;