import React, {
  useState, useEffect, useRef,
} from 'react';
import { useNavigate, } from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  Box, Theme, Typography, Button, Divider,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import BackgroudImage from '../assets/Block3/background.png';
import BackgroudImageMobile from '../assets/Block3/background-m.png';
import CardData from '../Block3CardData.json';
import { ReactComponent as Icon1, } from '../assets/Block3/icon1.svg';
import { ReactComponent as Icon2, } from '../assets/Block3/icon2.svg';
import { ReactComponent as Icon3, } from '../assets/Block3/icon3.svg';
import { ReactComponent as Icon4, } from '../assets/Block3/icon4.svg';
import { ReactComponent as Icon5, } from '../assets/Block3/icon5.svg';

const icons = [
  <Icon1 />,
  <Icon2 />,
  <Icon3 />,
  <Icon4 />,
  <Icon5 />,
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '550px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    [theme.breakpoints.down(800)]: { height: '1277px', },
  },
  titleContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '80px',
    pointerEvents: 'none',
    [theme.breakpoints.down(800)]: { padding: '80px 24px', },
  },
  title: { color: theme.palette.secondary.main, },
  description: {
    width: '100%',
    maxWidth: '600px',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  cardsContainer: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down(800)]: {
      flexDirection: 'column',
      top: '270px',
      gap: '60px',
    },
  },
  card: {
    width: '25%',
    height: '550px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
    padding: '0 16px 80px 16px',
    '&:hover': {
      '& $cardTitle': { color: theme.palette.secondary.main, },
      '& $cardDescription': { opacity: '1', },
      '& svg': {
        '& g': { stroke: 'white', },
        '& path': { stroke: 'white', },
      },
      backgroundImage: 'linear-gradient(to top, rgba(103, 155, 14, 0.85), rgba(133, 193, 31, 0))',
    },
    [theme.breakpoints.down(800)]: {
      width: '100%',
      height: '100%',
      paddingBottom: '0px',
      '& $cardTitle': { color: theme.palette.secondary.main, },
      '& $cardDescription': { opacity: '1', },
      '& svg': {
        '& g': { stroke: 'rgba(103, 155, 14, 0.85)', },
        '& path': { stroke: 'rgba(103, 155, 14, 0.85)', },
      },
      '&:hover': { backgroundImage: 'none', },
    },
  },
  cardTitle: { color: theme.palette.primary.main, },
  cardDescription: {
    height: '10%',
    opacity: '0',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
}));

const Card = ({
  title, description, index,
}: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <Box className={classes.card}>
      <Box>{icons[index]}</Box>
      <Typography
        className={classes.cardTitle}
        fontSize={isMobile ? '14px' : '16px'}
        fontWeight={'bold'}
      >
        <FormattedMessage id={title} />
      </Typography>
      <Typography
        className={classes.cardDescription}
        fontSize={isMobile ? '14px' : '16px'}
      >
        <FormattedMessage id={description} />
      </Typography>
    </Box>
  );
};

const BlockThree = ({ id, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <div
      id={id}
      className={classes.container}
      style={{ backgroundImage: `linear-gradient(to bottom, rgba(14, 35, 56, 0.45) 0%, rgba(14, 35, 56, 0.9) 100%),
      url(${isMobile ? BackgroudImageMobile : BackgroudImage})`, }}
    >
      <Box className={classes.cardsContainer}>
        {CardData.map((item, index) => {
          return (
            <Card
              key={index}
              title={item.title}
              description={item.description}
              index={index}
            />
          );
        })}
      </Box>
      <ScrollAnimation
        className={classes.titleContainer}
        animateIn="fadeInDown"
      >
        <Typography
          className={classes.title}
          fontSize={isMobile ? '24px' : '40px'}
          fontWeight={'bold'}
          marginBottom={'24px'}
        >
          <FormattedMessage id={'about.blockthree.title'} />
        </Typography>
        <Typography
          className={classes.description}
          fontSize={isMobile ? '14px' : '16px'}
        >
          <FormattedMessage id={'about.blockthree.description'} />
        </Typography>
      </ScrollAnimation>
    </div>
  );
};

export default BlockThree;