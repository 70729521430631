import React, {
  useState, useEffect,
} from 'react';
import {
  useNavigate, Link,
} from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  Box, Theme, Typography, Button,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  getNews, getGlobal,
} from '@/reducers/states';
import ScrollAnimation from 'react-animate-on-scroll';
import PaginationButton from '@/components/PaginationButton';
import RectangleButton from '@/components/RectangleButton';
import CardImage1 from '../assets/Block3/card-image-mrt.png';
import CardImage2 from '../assets/Block3/card-image-wind.png';
import CardImage3 from '../assets/Block3/card-image-box.png';
import CardIcon1 from '../assets/Block3/card-icon-mrt.png';
import CardIcon2 from '../assets/Block3/card-icon-wind.png';
import CardIcon3 from '../assets/Block3/card-icon-box.png';
import BackgroudImage from '../assets/Block3/background.png';
import MobileBackgroudImage from '../assets/Block3/background-mobile.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '80px 0',
  },
  titleContainer: {
    width: '100%',
    height: '30%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: '80px',
  },
  title: {
    color: theme.palette.secondary.dark,
    textShadow: '0 0 24px rgba(0, 0, 0, 0.2)',
  },
  description: {
    width: '730px',
    color: theme.palette.secondary.dark,
    textAlign: 'center',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
  },
  card: {
    width: '350px',
    height: '400px',
    borderRadius: '10px',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '32px 0',
    transition: 'transform .2s',
    cursor: 'pointer',
    '& div': { opacity: '1', },
    [theme.breakpoints.up(1000)]: { '&:hover': {
      transform: 'translateY(-20px)',
      backgroundSize: '150% 150%',
      '& svg': { '& path': { stroke: theme.palette.primary.main, }, },
    }, },
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  cardDescription: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    width: '240px',
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32px',
  },
  [theme.breakpoints.down(1200)]: {
    container: {
      height: '100%',
      paddingBottom: '93px',
    },
    description: {
      width: '330px',
      paddingBottom: '20px',
    },
    section: {
      padding: '0 24px',
      flexDirection: 'column',
    },
  },
}));

const CardsData = [
  {
    backgroundImage: CardImage1,
    icon: CardIcon1,
    title: 'homepage.blockthree.card1.title',
    description: 'homepage.blockthree.card1.description',
  },
  {
    backgroundImage: CardImage2,
    icon: CardIcon2,
    title: 'homepage.blockthree.card2.title',
    description: 'homepage.blockthree.card2.description',
  },
  {
    backgroundImage: CardImage3,
    icon: CardIcon3,
    title: 'homepage.blockthree.card3.title',
    description: 'homepage.blockthree.card3.description',
  },
];

const Block3 = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1400));
  const isMobile = useMediaQuery(theme.breakpoints.down(400));

  return (
    <Box
      className={classes.container}
      style={{ backgroundImage: `url(${isMobile ? MobileBackgroudImage : BackgroudImage})`, }}
    >
      <ScrollAnimation
        className={classes.titleContainer}
        animateIn="fadeInDown"
      >
        <Typography
          className={classes.title}
          fontSize={isLittleDesktop ? '24px' : '40px'}
          fontWeight={'bold'}
          marginBottom={'24px'}
        >
          <FormattedMessage id={'homepage.blockthree.title'} />
        </Typography>
        <Typography
          className={classes.description}
          fontSize={isLittleDesktop ? '14px' : '16px'}
        >
          <FormattedMessage id={'homepage.blockthree.description'} />
        </Typography>
      </ScrollAnimation>
      <section className={classes.section}>
        {CardsData.map((cardData, index) => {
          return (
            <Link
              key={index}
              to={`/${locale}/service#application_industry`}
              style={{ textDecoration: 'none', }}
            >
              <Box
                className={classes.card}
                style={{ backgroundImage: `url(${cardData.backgroundImage})`, }}
              >
                <Box className={classes.cardInfo}>
                  <img
                    width="80px"
                    height="80px"
                    src={cardData.icon}
                  />
                  <Typography
                    className={classes.cardTitle}
                    fontSize={locale === 'en_us' ? '24px' : '40px'}
                    fontWeight={'bold'}
                    marginBottom={'24px'}
                  >
                    <FormattedMessage id={cardData.title} />
                  </Typography>
                  <Typography
                    className={classes.cardDescription}
                    fontSize={'16px'}
                  >
                    <FormattedMessage id={cardData.description} />
                  </Typography>
                </Box>
                <PaginationButton disabled />
              </Box>
            </Link>
          );
        })}
      </section>
      <Box className={classes.button}>
        <Link
          to={`/${locale}/service#application_industry`}
          style={{ textDecoration: 'none', }}
        >
          <RectangleButton color="green">
            <FormattedMessage id={'homepage.blockthree.button'} />
          </RectangleButton>
        </Link>
      </Box>
    </Box>
  );
};

export default Block3;