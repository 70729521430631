import newsSaga from './newsSaga';
import financialSaga from './financialSaga';
import historySaga from './historySaga';
import contactSaga from './contactSaga';

const appSagas = [
  ...newsSaga,
  ...financialSaga,
  ...historySaga,
  ...contactSaga,
];

export default appSagas;