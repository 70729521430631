import React, {
  useState, useEffect,
} from 'react';
import {
  useNavigate, Link, useLocation,
} from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  Box, Theme,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  checkBoxWrapper: {
    width: '80px',
    height: '24px',
    position: 'relative',
    cursor: 'pointer',
  },
  checkBoxLabel: {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    width: '80px',
    height: '24px',
    borderRadius: '4px',
    background: theme.palette.secondary.dark,
    userSelect: 'none',
    '&:after': {
      content: '""',
      display: 'block',
      borderRadius: '4px 0px 0px 4px',
      width: '40px',
      height: '24px',
      background: theme.palette.primary.main,
      transition: '0.2s',
    },
  },
  checkBox: {
    opacity: '0',
    zIndex: '3',
    borderRadius: '4px',
    width: '80px',
    height: '24px',
    '&:checked + $checkBoxLabel': { '&:after': {
      content: '""',
      display: 'block',
      borderRadius: '0px 4px 4px 0px',
      width: '40px',
      height: '24px',
      background: theme.palette.primary.main,
      marginLeft: '40px',
      transition: '0.2s',
    }, },
  },
}));

const LanguageSwicher = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { locale, } = useSelector(getGlobal);
  const { pathname, } = location;
  const urlLocale = pathname.split('/')[1];

  // 切換語系按鈕時一併改變網址上的語系
  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (pathname.includes('zh_tw')) {
        const newPathname = pathname.replace('zh_tw', 'en_us');
        navigate(newPathname, { replace: true, });
      } else {
        if (!pathname.includes('en_us')) {
          navigate(`en_us${pathname}`, { replace: true, });
        }
      }
    } else {
      if (pathname.includes('en_us')) {
        const newPathname = pathname.replace('en_us', 'zh_tw');
        navigate(newPathname, { replace: true, });
      }
    }
  };

  // 根據網址上的語系來觸發語系狀態的切換，這樣不論使用者是從語系按鈕來切換還是直接改變網址都能會觸發切換語系的動作
  useEffect(() => {
    if (locale !== urlLocale) {
      if (urlLocale === 'en_us') {
        dispatch(globalActions.changeLanguage('en_us'));
      } else {
        dispatch(globalActions.changeLanguage('zh_tw'));
      }
    }
  }, [urlLocale,]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box className={classes.checkBoxWrapper}>
        <input
          className={classes.checkBox}
          id="checkbox"
          type="checkbox"
          checked={urlLocale === 'en_us'}
          onChange={handleLanguageChange}
        />
        <label
          className={classes.checkBoxLabel}
          htmlFor="checkbox"
        >
          <Box
            display="flex"
            position="absolute"
            top="0"
            left="0"
            fontSize="12px"
            zIndex="2"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="24px"
              width="40px"
            >
              繁中
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="24px"
              width="40px"
            >
              EN
            </Box>
          </Box>
        </label>
      </Box>
    </Box>
  );
};

export default LanguageSwicher;