import { useEffect, } from 'react';
import { Box, } from '@mui/material';
import { Helmet, } from 'react-helmet';
import { useIntl, } from 'react-intl';
import Block1 from './component/Block1';
import Block2 from './component/Block2';
import Block3 from './component/Block3';
import Block4 from './component/Block4';
import Block5 from './component/Block5';
import Block6 from './component/Block6';
import ScrollHandler from '@/components/ScrollHandler';
import { globalActions, } from '@/reducers/global.slice';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { historyActions, } from '@/reducers/history.slice';
import { getHistory, } from '@/reducers/states';
import Loading from '@/pages/Loading/Loading';

const About = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { history, } = useSelector(getHistory);
  const { loaded, } = history;

  // meta tag文案
  const pageTitle = intl.formatMessage({ id: 'about.page.title', });
  const ogTagTitle = intl.formatMessage({ id: 'about.ogtag.title', });
  const ogTagDescription = intl.formatMessage({ id: 'about.ogtag.description', });

  useEffect(() => {
    dispatch(historyActions.getCompanyHistoryRequest({ fields: '*.*', }));
  }, []);

  if (!loaded) return <Loading />;
  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={ogTagDescription}
        ></meta>
        <meta
          property="og:title"
          content={ogTagTitle}
        />
        <meta
          property="og:description"
          content={ogTagDescription}
        />
      </Helmet>
      <ScrollHandler />
      <Block1 />
      <Block2 id="brand_description" />
      <Block3 id="value" />
      <Block4 id="sustainable" />
      <Block5 id="history" />
      <Block6 id="talents" />
    </Box>
  );
};

export default About;