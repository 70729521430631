import { Box, } from '@mui/material';
import { styled, } from '@mui/system';

type Props = {
  children?: any;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  selected?: boolean;
  color?: 'green' | 'blue';
  variant?: 'contained' | 'outlined';
};

const Container = styled(Box)(({
  color, theme,
}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.5rem 0.75rem',
  borderRadius: '20px',
  backgroundColor: color === 'green' ? theme.palette.primary.contrastText : theme.palette.secondary.dark,
  width: 'fit-content',
  height: '28px',
  fontSize: '12px',
  fontWeight: '500',
  userSelect: 'none',
  color: color === 'green' ? theme.palette.secondary.dark : theme.palette.default.contrastText,
}));

const OutlinedContainer = styled(Container)(({
  theme, color,
}) => ({
  border: `1px solid ${color === 'green' ? theme.palette.primary.main : theme.palette.secondary.dark}`,
  backgroundColor: '#fff',
  color: color === 'green' ? theme.palette.primary.main : theme.palette.secondary.dark,
}));

const Tag = ({
  children, color, variant,
}: Props) => {
  switch (variant) {
    case 'outlined':
      return <OutlinedContainer color={color}>{children}</OutlinedContainer>;
    default:
      return <Container color={color}>{children}</Container>;
  }
};

export default Tag;