import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  Box, Theme, Typography,
} from '@mui/material';
import { Link, } from 'react-router-dom';
import { useTheme, } from '@mui/material/styles';
import { makeStyles, } from '@mui/styles';
import { FormattedMessage, } from 'react-intl';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  useDispatch, useSelector,
} from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';
import Path from '../path.json';
import zhCompanyStructureImage from '../assets/block2/companyStructure-zh.png';
import enCompanyStructureImage from '../assets/block2/companyStructure-en.png';
import TextButton from '@/components/TextButton';
import { useNavigate, } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '120px',
    [theme.breakpoints.down('md')]: { padding: '0px 24px 80px', },
  },
  anchorBar: {
    display: 'flex',
    alignItems: 'center',
    height: '68px',
    padding: '24px',
    marginBottom: '60px',
    fontSize: '14px',
    width: '100%',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': { display: 'none', },
  },
  title: {
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: '805px',
    [theme.breakpoints.down('md')]: { width: '100%', },
  },
  bottomFilter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '320px',
    background: 'linear-gradient(180deg, rgba(141, 141, 141, 0.01) 0%, rgba(100, 100, 100, 0.15) 86.46%)',
  },
}));

const BlockTwo = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { locale, } = useSelector(getGlobal);
  const anchorRef = useRef<any>(null);
  const [anchorCenter, setAnchorCenter,] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const isShowAnchor = useMediaQuery(theme.breakpoints.down('md'));

  // 判斷 anchor bar 是否需要置中（如果需要 scroll 的話靠左，沒有的話置中）
  useEffect(() => {
    if (!!anchorRef.current) {
      const anchorContainerWidth = anchorRef.current.clientWidth;
      let anchorItemsTotalWidth = 0;
      for (const item of anchorRef.current.children) {
        anchorItemsTotalWidth += item.clientWidth;
      }
      if (anchorContainerWidth > anchorItemsTotalWidth) {
        setAnchorCenter(true);
      }
    }
  }, []);

  return (
    <Box
      id="organization"
      className={classes.container}
    >
      {isShowAnchor && (
        <Box
          ref={anchorRef}
          className={classes.anchorBar}
          sx={{ justifyContent: `${anchorCenter ? 'center' : 'flex-start'}`, }}
        >
          {Path.map((item: any, index: any) => {
            return (
              <Link
                key={index}
                to={item.anchor}
                style={{
                  textDecoration: 'none',
                  color: theme.palette.secondary.dark,
                  borderLeft: index !== 0 ? `2px solid ${theme.palette.primary.main}` : '',
                  padding: '0 24px',
                }}
              >
                <TextButton>
                  <FormattedMessage id={item.title} />
                </TextButton>
              </Link>
            );
          })}
        </Box>
      )}
      <ScrollAnimation
        className={classes.title}
        animateIn="fadeIn"
      >
        <Typography
          variant="h2"
          fontSize={isMobile ? '24px' : '40px'}
          fontWeight="700"
          color={theme.palette.secondary.dark}
          mb={3}
        >
          <FormattedMessage id="investor.blocktwo.title" />
        </Typography>
        <Typography
          fontSize={isMobile ? '14px' : '16px'}
          fontWeight="400"
          textAlign="center"
          color={theme.palette.secondary.dark}
          sx={{ opacity: 0.85, }}
        >
          <FormattedMessage id="investor.blocktwo.description" />
        </Typography>
      </ScrollAnimation>
      <Box className={classes.imageContainer}>
        <img
          className={classes.image}
          src={locale === 'en_us' ? enCompanyStructureImage : zhCompanyStructureImage}
          alt="company-structure"
        />
      </Box>
      <Box className={classes.bottomFilter} />
    </Box>
  );
};

export default React.memo(BlockTwo);