import {
  type FC, type ReactNode, useState, useEffect, type ComponentProps,
} from 'react';
import {
  Box,
  type BoxProps,
  type Theme,
  Typography,
  styled,
  type SxProps,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import PaginationButton from '@/components/PaginationButton';
import { ReactComponent as SalesIcon, } from '../assets/Block4/icon-sales.svg';
import { ReactComponent as QulityIcon, } from '../assets/Block4/icon-quality.svg';
import { ReactComponent as ProductionIcon, } from '../assets/Block4/icon-production.svg';
import { ReactComponent as Frame1, } from '../assets/Block4/frame1.svg';
import { ReactComponent as Frame2, } from '../assets/Block4/frame2.svg';
import { ReactComponent as Frame3, } from '../assets/Block4/frame3.svg';
import useEmblaCarousel from 'embla-carousel-react';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EastIcon from '@mui/icons-material/East';
import { Link, } from 'react-router-dom';

interface JobCardProps extends ComponentProps<typeof Box> {
  jobTitle?: ReactNode;
  jobDescription?: ReactNode;
  icon?: ReactNode;
  department?: ReactNode;
  isActive?: boolean;
  link: string;
}

type JobData = Pick<JobCardProps, 'jobTitle' | 'jobDescription' | 'icon' | 'department' | 'link'> & { jobId: number }

const jobData: JobData[] = [
  {
    jobId: 1,
    jobTitle: <FormattedMessage id="hiring.blockfour.job-list.job1.title" />,
    jobDescription: <>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job1.message.session1" />
      </Typography>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job1.message.session2" />
      </Typography>
      <br />
      <ul
        style={{
          listStyle: 'none',
          paddingLeft: '20px',
          margin: '0',
          listStyleType: 'disc',
        }}
      >
        {[
          1,
          2,
          3,
        ].map((item) => (
          <li key={item}>
            <FormattedMessage
              id={`hiring.blockfour.job-list.job1.message.session2.list${item}`}
            />
          </li>
        ))}
      </ul>
    </>,
    department: <FormattedMessage id="hiring.blockfour.job-list.job1.department" />,
    icon: <SalesIcon />,
    link: 'https://www.104.com.tw/company/bijt468',
  },
  {
    jobId: 2,
    jobTitle: <FormattedMessage id="hiring.blockfour.job-list.job2.title" />,
    jobDescription: <>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job2.message.session1" />
      </Typography>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job2.message.session2" />
      </Typography>
      <br />
      <ul
        style={{
          listStyle: 'none',
          paddingLeft: '20px',
          margin: '0',
          listStyleType: 'disc',
        }}
      >
        {[
          1,
          2,
          3,
          4,
          5,
        ].map((item) => (
          <li key={item}>
            <FormattedMessage
              id={`hiring.blockfour.job-list.job2.message.session2.list${item}`}
            />
          </li>
        ))}
      </ul>
    </>,
    department: <FormattedMessage id="hiring.blockfour.job-list.job2.department" />,
    icon: <QulityIcon />,
    link: 'https://www.104.com.tw/company/bijt468',
  },
  {
    jobId: 3,
    jobTitle: <FormattedMessage id="hiring.blockfour.job-list.job3.title" />,
    jobDescription: <>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job3.message.session1" />
      </Typography>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job3.message.session2" />
      </Typography>
      <br />
      <ul
        style={{
          listStyle: 'none',
          paddingLeft: '20px',
          margin: '0',
          listStyleType: 'disc',
        }}
      >
        {[
          1,
          2,
          3,
          4,
        ].map((item) => (
          <li key={item}>
            <FormattedMessage
              id={`hiring.blockfour.job-list.job3.message.session2.list${item}`}
            />
          </li>
        ))}
      </ul>
    </>,
    department: <FormattedMessage id="hiring.blockfour.job-list.job3.department" />,
    icon: <ProductionIcon />,
    link: 'https://www.104.com.tw/company/bijt468',
  },
  {
    jobId: 4,
    jobTitle: <FormattedMessage id="hiring.blockfour.job-list.job4.title" />,
    jobDescription: <>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job4.message.session1" />
      </Typography>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job4.message.session2" />
      </Typography>
      <br />
      <ul
        style={{
          listStyle: 'none',
          paddingLeft: '20px',
          margin: '0',
          listStyleType: 'disc',
        }}
      >
        {[
          1,
          2,
          3,
          4,
        ].map((item) => (
          <li key={item}>
            <FormattedMessage
              id={`hiring.blockfour.job-list.job4.message.session2.list${item}`}
            />
          </li>
        ))}
      </ul>
    </>,
    department: <FormattedMessage id="hiring.blockfour.job-list.job4.department" />,
    icon: <ProductionIcon />,
    link: 'https://www.104.com.tw/company/bijt468',
  },
  {
    jobId: 5,
    jobTitle: <FormattedMessage id="hiring.blockfour.job-list.job5.title" />,
    jobDescription: <>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job5.message.session1" />
      </Typography>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job5.message.session2" />
      </Typography>
      <br />
      <ul
        style={{
          listStyle: 'none',
          paddingLeft: '20px',
          margin: '0',
          listStyleType: 'disc',
        }}
      >
        {[
          1,
          2,
          3,
          4,
        ].map((item) => (
          <li key={item}>
            <FormattedMessage
              id={`hiring.blockfour.job-list.job5.message.session2.list${item}`}
            />
          </li>
        ))}
      </ul>
    </>,
    department: <FormattedMessage id="hiring.blockfour.job-list.job5.department" />,
    icon: <ProductionIcon />,
    link: 'https://www.104.com.tw/company/bijt468',
  },
  {
    jobId: 6,
    jobTitle: <FormattedMessage id="hiring.blockfour.job-list.job6.title" />,
    jobDescription: <>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job6.message.session1" />
      </Typography>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
        },]}
      >
        <FormattedMessage id="hiring.blockfour.job-list.job6.message.session2" />
      </Typography>
      <br />
      <ul
        style={{
          listStyle: 'none',
          paddingLeft: '20px',
          margin: '0',
          listStyleType: 'disc',
        }}
      >
        {[
          1,
          2,
          3,
          4,
          5,
        ].map((item) => (
          <li key={item}>
            <FormattedMessage
              id={`hiring.blockfour.job-list.job6.message.session2.list${item}`}
            />
          </li>
        ))}
      </ul>
    </>,
    department: <FormattedMessage id="hiring.blockfour.job-list.job6.department" />,
    icon: <ProductionIcon />,
    link: 'https://www.104.com.tw/company/bijt468',
  },
];

const JobCard: FC<JobCardProps> = ({
  jobTitle, department, jobDescription, icon, sx, isActive, link, children, ...rest
}) => {
  const isInternalLink = link?.startsWith('/') || link?.startsWith('#');
  return (
    <Box sx={[{
      width: '350px',
      height: '430px',
      padding: '35px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      ['&:hover']: { boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', },
      boxShadow: isActive ? '0px 4px 20px rgba(0, 0, 0, 0.1)' : 'none',
      transition: 'box-shadow 0.3s ease-in-out',
      overflow: 'hidden',
    }, ]} {...rest}>
      <Box sx={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        marginBottom: '10px',
      }}>
        {icon}
        <Typography
          sx={[{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#85C11F',
          },]}
        >{department}</Typography>
      </Box>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '33px',
          marginBottom: '12px',
          color: '#0E2338',
        },]}
      >
        {jobTitle}
      </Typography>
      <Box
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '19px',
          height: '210px',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          marginBottom: '3px',
        },]}
      >
        {jobDescription}
      </Box>
      {children}
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '22px',
          marginTop: 'auto',
          color: '#85C11F',
          textAlign: 'right',
        },]}
      >
        {
          isInternalLink ? (
            <Link to={link} style={{
              color: '#85C11F',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '8px',
            }}>
              <FormattedMessage id="hiring.utils.hiring-now" />
              <EastIcon sx={{ fontSize: '20px', }} />
            </Link>
          ) : (
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#85C11F',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '8px',
              }}
              href={link}>
              <FormattedMessage id="hiring.utils.hiring-now" />
              <EastIcon sx={{ fontSize: '20px', }} />
            </a>)
        }
      </Typography>
    </Box>
  );
};

const StyledFrame1 = styled(Frame1)(({ theme, }) => ({}));
const StyledFrame2 = styled(Frame2)(({ theme, }) => ({}));
const StyledFrame3 = styled(Frame3)(({ theme, }) => ({}));

const JobList: FC<{ data: JobData[] }> = ({ data, }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const [prevBtnEnabled, setPrevBtnEnabled,] = useState<boolean | undefined>(false);
  const [nextBtnEnabled, setNextBtnEnabled,] = useState<boolean | undefined>(false);
  const [currentSlideIndex, setCurrentSlideIndex,] = useState<number | undefined>(0);
  const [emblaRef, emblaApi,] = useEmblaCarousel({
    loop: false, align: 'center',
  });
  const scrollPrev = () => {
    emblaApi && emblaApi.scrollPrev();
  };

  const scrollNext = () => {
    emblaApi && emblaApi.scrollNext();
  };

  const scrollTo = (index: number) => {
    emblaApi && emblaApi.scrollTo(index);
  };

  useEffect(() => {
    const onSlideChange = () => {
      setCurrentSlideIndex(emblaApi?.selectedScrollSnap());
      setPrevBtnEnabled(emblaApi?.canScrollPrev());
      setNextBtnEnabled(emblaApi?.canScrollNext());
    };
    emblaApi?.on('select', onSlideChange);
    return () => {
      emblaApi?.off('select', onSlideChange);
    };
  }, [emblaApi,]);

  useEffect(() => {
    scrollTo(!!currentSlideIndex ? currentSlideIndex : 0);
  }, [currentSlideIndex,]);
  return (
    <>
      <Box ref={emblaRef}>
        <Box sx={{
          display: 'flex',
          gap: '25px',
        }}>
          {data.map((item) => (
            <Box key={item.jobId} sx={{
              display: 'flex', flex: '0 0 1',
            }}>
              <JobCard
                isActive={currentSlideIndex === (!!item?.jobId ? item?.jobId : 0) - 1}
                onClick={() => scrollTo((!!item?.jobId ? item?.jobId : 0) - 1)}
                icon={item?.icon}
                department={item?.department}
                jobTitle={item?.jobTitle}
                jobDescription={item?.jobDescription}
                link={item?.link}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={[ {
        display: 'flex', gap: '24px', marginTop: '40px',
      }, ]}>
        <PaginationButton
          color="green"
          reverse="180"
          onClick={scrollPrev}
          disabled={!emblaApi?.canScrollPrev()}
        />
        <PaginationButton
          color="green"
          onClick={scrollNext}
          disabled={!emblaApi?.canScrollNext()}
        />
      </Box>
    </>
  );
};

const Block4: FC<ComponentProps<typeof Box>> = ({ ...props }) => {
  return (
    <Box sx={[{
      height: '745px',
      background: 'linear-gradient(270.08deg, rgba(233, 236, 239, 0) 0.06%, #E9ECEF 99.93%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
    }, (theme) => ({ [theme.breakpoints.up('md')]: { height: '865px', }, }),]}
    {...props}
    >
      <Typography sx={[{
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '54px',
        marginBottom: '60px',
      },]}>
        <FormattedMessage id="hiring.blockfour.title" />
      </Typography>
      <JobList data={jobData} />
      <StyledFrame1 sx={{
        position: 'absolute', top: 90, right: -10,
        zIndex: -1,
      }}/>
      <StyledFrame2 sx={{
        position: 'absolute', top: 10, right: 100,
        zIndex: -1,
      }}/>
      <StyledFrame3 sx={{
        position: 'absolute', bottom: 10, left: -10,
        zIndex: -1,
      }}/>
    </Box>
  );
};

export default Block4;