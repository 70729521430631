import { useEffect, } from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { Outlet, } from 'react-router-dom';

import { globalActions, } from '@/reducers/global.slice';

interface Props {
  routes: any;
  componentPlugins?: {
    Header?: React.FunctionComponent;
    Footer?: React.FunctionComponent;
  };
}

export const ApexOfficialWebsite = (props: Props) => {
  const {
    routes, componentPlugins,
  } = props;
  const dispatch = useDispatch();
  const { pathname, } = location;
  const urlLocale = pathname.split('/')[1];

  useEffect(() => {
    if (urlLocale === 'en_us') {
      dispatch(globalActions.changeLanguage('en_us'));
    } else {
      dispatch(globalActions.changeLanguage('zh_tw'));
    }
  }, []);

  return (
    <>
      {componentPlugins && componentPlugins.Header && <componentPlugins.Header />}
      <main>
        <Outlet />
      </main>
      {componentPlugins && componentPlugins.Footer && <componentPlugins.Footer />}
    </>
  );
};

export default ApexOfficialWebsite;