import {
  Box, Theme, Typography, useTheme, Drawer,
} from '@mui/material';
import { makeStyles, } from '@mui/styles';
import { ReactComponent as GreenCircle, } from './assets/green-circle.svg';
import { ReactComponent as HalfCircle, } from './assets/half-green-circle.svg';
import Logo from './assets/apex-logo.png';
import React, { useState, } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    userSelect: 'none',
    zIndex: '200',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(to top, rgba(50, 115, 182, 0.25), rgba(50, 115, 182, 0) 46%)',
  },
  circleContainer: {
    position: 'relative',
    width: 'fit-content',
    height: 'fit-content',
    animation: '$rotate 1s infinite linear',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  greenCircle: { position: 'absolute', },
  halfGreenCircle: {
    position: 'absolute',
    left: '50%',
  },
  logo: {
    position: 'absolute',
    backgroundColor: 'white',
    transform: 'translate(-15px, -10px)',
    padding: '0px 100px',
  },
  text: {
    transform: 'translate(0, 80px)',
    zIndex: '200',
    opacity: '0.45',
  },
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0deg)', },
    '100%': { transform: 'rotate(359deg)', },
  },
}));

const Loading = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      open={true}
      hideBackdrop={true}
      transitionDuration={0}
    >
      <Box className={classes.container}>
        <Box className={classes.circleContainer}>
          <GreenCircle className={classes.greenCircle} />
          <HalfCircle className={classes.halfGreenCircle} />
        </Box>
        <Box className={classes.logo}>
          <img
            width="112px"
            src={Logo}
          />
        </Box>
        <Typography
          className={classes.text}
          fontSize="16px"
          color={theme.palette.secondary.dark}
        >
          Loading...
        </Typography>
      </Box>
    </Drawer>
  );
};

export default Loading;