import {
  put, takeLatest, call,
} from 'redux-saga/effects';
import { historyActions, } from '@/reducers/history.slice';
import { getCompanyHistory, } from '@/serivces/historyApi';
import { AxiosResponse, } from 'axios';

export function* getCompanyHistorySaga({ payload, }: ReturnType<typeof historyActions.getCompanyHistoryRequest>) {
  try {
    const res: AxiosResponse = yield call(getCompanyHistory, payload);
    yield put(historyActions.getCompanyHistorySuccess(res));
  } catch (error) {
    yield put(historyActions.getCompanyHistoryFail(error));
  }
}

const sagas = [takeLatest(historyActions.getCompanyHistoryRequest, getCompanyHistorySaga),];

export default sagas;