import React, {
  useEffect, useState,
} from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme, Typography,
} from '@mui/material';
import {
  useSelector, useDispatch,
} from 'react-redux';
import { newsActions, } from '@/reducers/news.slice';
import {
  getNews, getGlobal,
} from '@/reducers/states';
import clsx from 'clsx';
import { useTheme, } from '@mui/material/styles';

import { FormattedMessage, } from 'react-intl';
import {
  useNavigate, useSearchParams, useParams,
} from 'react-router-dom';
import RecentUpdateData from '../Block2SideBarData.json';
import { getLanguageNewsData, } from '@/utils/tool';
import RoundedButton from '@/components/RoundedButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boxContainer: {
    width: '100%',
    marginBottom: '60px',
  },
  titleBox: {
    width: '100%',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '24px',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: '16px',
    marginBottom: '16px',
  },
  recentUpdateBox: {
    display: 'flex',
    padding: '16px 0',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    '&:nth-child(2)': { paddingTop: '0', },
  },
  recentUpdateImage: {
    position: 'relative',
    minWidth: '60px',
    height: '60px',
    borderRadius: '10px',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  recentUpdateImageOverLayer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(to bottom, rgba(9, 68, 129, 0) 0%, rgba(14, 35, 56, 0.75) 100%)',
  },
  recentUpdateContent: {
    display: ' -webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    height: '60px',
    marginLeft: '16px',
  },
  recentUpdateContentText: { '&:hover': { color: theme.palette.primary.main, }, },
  categoriesBox: {
    fontWeight: 700,
    cursor: 'pointer',
    margin: '16px 0px',
  },
  activeCategoriesBox: {
    width: 'fit-content',
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
}));

const Categories = [
  {
    id: 1,
    name: 'newspage.blocktwo.sidebar.ALL',
    value: 'ALL',
  },
  {
    id: 2,
    name: 'newspage.blocktwo.sidebar.NEWS',
    value: 'NEWS',
  },
  {
    id: 3,
    name: 'newspage.blocktwo.sidebar.ACTIVITY',
    value: 'ACTIVITY',
  },
];

export enum ActiveCategories {
  ALL = 'ALL',
  NEWS = 'NEWS',
  ACTIVITY = 'ACTIVITY',
}

const SideBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const params = useParams();
  const { locale, } = useSelector(getGlobal);
  const {
    tags, news, recentUpdateNews,
  } = useSelector(getNews);
  const {
    data, loaded,
  } = tags;

  const [searchParams, setSearchParams,] = useSearchParams();
  const [activeCategories, setActiveCategories,] = useState<ActiveCategories>(ActiveCategories.ALL);
  const [activeTags, setActiveTags,] = useState<any[]>([]);
  const isNewsArticlePage = !!params.id;
  const basicUrl = window.Config.API_HOST;
  const paramsTags = searchParams.get('tags');
  const categoriesTags = searchParams.get('categories');

  const handleChangeCategories = (value: string) => {
    // 如果在新聞內頁點選類別需回到新聞列表頁面
    if (isNewsArticlePage) {
      navigate(`/${locale}/news?categories=${value}`);
    } else {
      // 如果在新聞列表頁面點選類別則直接切換類別
      searchParams.set('categories', value);
      setSearchParams(searchParams);
    }
  };

  const handleSelectTags = (value: any) => {
    if (activeTags.includes(value)) {
      const newTags = activeTags.filter((item) => item !== value);
      searchParams.set('tags', newTags.join(','));
      setSearchParams(searchParams);
    } else {
      const newTags = [...activeTags, value,];
      searchParams.set('tags', newTags.join(','));
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    // 標籤
    if (paramsTags) {
      const tags = paramsTags.split(',');
      setActiveTags(tags);
    } else {
      setActiveTags([]);
    }

    // 類別
    if (categoriesTags) {
      setActiveCategories(categoriesTags as ActiveCategories);
    }
  }, [paramsTags, categoriesTags,]);

  // 獲取標籤資料
  useEffect(() => {
    dispatch(newsActions.getNewsTagsRequest({}));
    dispatch(newsActions.getRecentUpdateNewsRequest({ fields: '*.*.*.*.*', }));
  }, []);

  return (
    <Box className={classes.container}>
      {/* 近期更新 */}
      <Box className={classes.boxContainer}>
        <Box className={classes.titleBox}>
          <FormattedMessage id="newspage.blocktwo.sidebar.title1" />
        </Box>
        {recentUpdateNews?.data.map((item: any, index: any) => {
          // 找到當前語系的文案
          const languageNewsData = getLanguageNewsData(item.translations, locale);
          // 只顯示三則文案
          if (index < 3) {
            return (
              <Box
                key={item.id}
                className={classes.recentUpdateBox}
                onClick={() => navigate(`/${locale}/news/${item.id}`)}
              >
                <Box className={classes.recentUpdateImage}>
                  <Box className={classes.recentUpdateImageOverLayer} />
                  <img src={`${basicUrl}/assets/${item.cover.id}?width=100&height=100`} />
                </Box>
                <Box className={classes.recentUpdateContent}>
                  <Typography
                    fontSize="14px"
                    color={theme.palette.secondary.dark}
                    sx={{ opacity: '0.45', }}
                  >
                    {item.date_published}
                  </Typography>
                  <Typography
                    className={classes.recentUpdateContentText}
                    fontSize="14px"
                    color={theme.palette.secondary.dark}
                    sx={{ opacity: '0.85', }}
                  >
                    {languageNewsData.title}
                  </Typography>
                </Box>
              </Box>
            );
          }
        })}
      </Box>
      {/* 類別 */}
      <Box className={classes.boxContainer}>
        <Box className={classes.titleBox}>
          <FormattedMessage id="newspage.blocktwo.sidebar.title2" />
        </Box>
        {Categories.map((item) => (
          <Box
            key={item.id}
            className={clsx([classes.categoriesBox, activeCategories === item.value && classes.activeCategoriesBox,])}
            onClick={() => handleChangeCategories(item.value)}
          >
            <FormattedMessage id={item.name} />
          </Box>
        ))}
      </Box>
      {/* 標籤 */}
      {/* 新聞內頁不需出現標籤 */}
      {!isNewsArticlePage && (
        <Box className={classes.boxContainer}>
          <Box className={classes.titleBox}>
            <FormattedMessage id="newspage.blocktwo.sidebar.title3" />
          </Box>
          <Box className={classes.tagsContainer}>
            {data.map((item) => (
              <RoundedButton
                key={item.id}
                variant="outlined"
                selected={activeTags.includes(item.name)}
                onClick={() => handleSelectTags(item.name)}
              >
                {item.name}
              </RoundedButton>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(SideBar);