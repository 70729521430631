import {
  type FC, useState, useEffect, useCallback, useRef,
} from 'react';
import {
  Box, type Theme,
} from '@mui/material';
import { Helmet, } from 'react-helmet';
import ScrollHandler from '@/components/ScrollHandler';
import Block1 from './components/Block1';
import Block2 from './components/Block2';
import Block3 from './components/Block3';
import Block4 from './components/Block4';
import Block5 from './components/Block5';
import Path from '../../plugins/path.json';
import TextButton from '@/components/TextButton';
import {
  FormattedMessage, useIntl,
} from 'react-intl';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import { Link, } from 'react-router-dom';
import { makeStyles, } from '@mui/styles';


const useStyles = makeStyles((theme: Theme) => ({ anchorBar: {
  display: 'flex',
  alignItems: 'center',
  height: '68px',
  padding: '24px',
  marginBottom: '60px',
  fontSize: '14px',
  width: '100%',
  overflowX: 'scroll',
  '&::-webkit-scrollbar': { display: 'none', },
}, }));

const hiringPageAnchors = Path.find((item, index) => item.path === '/hiring');


const Hiring: FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isShowAnchor = useMediaQuery(theme.breakpoints.down('md'));
  const anchorRef = useRef<any>(null);
  const [anchorCenter, setAnchorCenter,] = useState(false);
  const classes = useStyles();


  // meta tag文案
  const pageTitle = intl.formatMessage({ id: 'hiring.page.title', });
  const ogTagTitle = intl.formatMessage({ id: 'hiring.ogtag.title', });
  const ogTagDescription = intl.formatMessage({ id: 'hiring.ogtag.description', });
  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={ogTagDescription}
        ></meta>
        <meta
          property="og:title"
          content={ogTagTitle}
        />
        <meta
          property="og:description"
          content={ogTagDescription}
        />
      </Helmet>
      <ScrollHandler />
      <Block1 />
      {isShowAnchor && (
        <Box
          ref={anchorRef}
          className={classes.anchorBar}
          sx={{ justifyContent: `${anchorCenter ? 'center' : 'flex-start'}`, }}
        >
          {hiringPageAnchors?.menu?.map((item: any, index: any) => {
            return (
              <Link
                key={index}
                to={item.anchor}
                style={{
                  textDecoration: 'none',
                  color: theme.palette.secondary.dark,
                  borderLeft: index !== 0 ? `2px solid ${theme.palette.primary.main}` : '',
                  padding: '0 24px',
                }}
              >
                <TextButton>
                  <FormattedMessage id={item.title} />
                </TextButton>
              </Link>
            );
          })}
        </Box>
      )}
      <Block2 id="message" />
      <Block3 id="benefits" />
      <Block4 id="career" />
      <Block5 id="flow" />
    </Box>
  );
};

export default Hiring;