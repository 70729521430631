import React from 'react';
import { useNavigate, } from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { historyActions, } from '@/reducers/history.slice';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  Box, Theme, Typography, Button, Divider,
} from '@mui/material';
import {
  FormattedMessage, useIntl,
} from 'react-intl';
import moment from 'moment';
import Loading from '@/pages/Loading/Loading';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DownloadData from '../DownloadData.json';
import { ReactComponent as DownloadIcon, } from '../assets/block3-icon-download.svg';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundPosition: 'center',
    flexDirection: 'column',
    backgroundSize: 'cover',
    padding: '0 120px 120px',
    [theme.breakpoints.down(800)]: {
      padding: '60px 24px',
      overflow: 'hidden',
    },
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
  },
  subTitle: {
    width: '100%',
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: '16px',
  },
  listContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  },
  list: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #D1D2D473',
    padding: '16px 0',
  },
  download: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.dark,
    textDecoration: 'none',
    '& svg': {
      width: '24px',
      height: '24px',
      marginLeft: '8px',
    },
    '&:hover': {
      '& svg path': { stroke: `${theme.palette.primary.main}`, },
      '& p': { color: theme.palette.primary.main, },
    },
  },
}));

const BlockThree = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { locale, } = useSelector(getGlobal);
  const navigate = useNavigate();
  const theme = useTheme();
  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <Box className={classes.container}>
      {/* 文件下載 */}
      <Box className={classes.contentContainer}>
        <Box className={classes.subTitle}>
          <Typography
            fontSize={isMobile ? '16px' : '24px'}
            fontWeight="700"
            color={theme.palette.primary.main}
          >
            <FormattedMessage id="harassment.page.data.title" />
          </Typography>
        </Box>
        <Box className={classes.listContainer}>
          {DownloadData.map((item) => {
            const fileName = intl.formatMessage({ id: item.fileName, });
            return (
              <Box
                key={item.title}
                className={classes.list}
              >
                <Typography
                  fontSize={isMobile ? '14px' : '16px'}
                  fontWeight="700"
                  color={theme.palette.secondary.dark}
                >
                  <FormattedMessage id={item.title} />
                </Typography>
                <a
                  className={classes.download}
                  href={item.path}
                  download={fileName}
                >
                  <Typography
                    fontSize={isMobile ? '14px' : '16px'}
                    fontWeight="700"
                  >
                    <FormattedMessage id="harassment.page.data.download" />
                  </Typography>
                  <DownloadIcon />
                </a>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default BlockThree;