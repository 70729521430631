import { Box, } from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import { styled, } from '@mui/system';

type Props = {
  children?: any;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  selected?: boolean;
  height?: number;
  fontSize?: number;
  borderRadius?: number;
  variant?: 'contained' | 'outlined';
};

const Container = styled(Box)(({
  theme, height, borderRadius, fontSize,
}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.5rem 0.75rem',
  borderRadius: borderRadius ? `${borderRadius}px` : '14px',
  backgroundColor: theme.palette.secondary.dark,
  width: 'fit-content',
  height: height ? `${height}px` : '28px',
  fontSize: fontSize ? `${fontSize}px` : '12px',
  fontWeight: '500',
  userSelect: 'none',
  cursor: 'pointer',
  color: theme.palette.default.contrastText,
  '&:hover': {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.primary.light,
  },
  '&:active': {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.primary.main,
  },
}));

const OutlinedContainer = styled(Container)(({ theme, }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0)',
  border: '1px solid',
  color: theme.palette.primary.main,
  '&:hover': { border: '1px solid rgba(0, 0, 0, 0)', },
  '&:active': {
    backgroundColor: theme.palette.primary.contrastText,
    border: '1px solid rgba(0, 0, 0, 0)',
  },
}));

const RoundedButton = ({
  children, disabled, onClick, selected, variant, height, borderRadius, fontSize,
}: Props) => {
  const theme = useTheme();

  switch (variant) {
    case 'outlined':
      return (
        <OutlinedContainer
          onClick={onClick}
          color={variant}
          height={height}
          borderRadius={borderRadius}
          fontSize={fontSize}
          style={{
            color: selected ? theme.palette.secondary.dark : '',
            borderColor: selected ? theme.palette.primary.contrastText : '',
            backgroundColor: selected ? theme.palette.primary.contrastText : '',
          }}
        >
          {children}
        </OutlinedContainer>
      );
    default:
      return (
        <Container
          onClick={onClick}
          color={variant}
          height={height}
          borderRadius={borderRadius}
          style={{
            color: selected ? theme.palette.secondary.dark : '',
            borderColor: selected ? theme.palette.primary.main : '',
            backgroundColor: selected ? theme.palette.primary.main : '',
          }}
        >
          {children}
        </Container>
      );
  }
};

export default RoundedButton;