import {
  useEffect, useRef,
} from 'react';
import {
  Box, Button,
} from '@mui/material';
import {
  useSelector, useDispatch,
} from 'react-redux';
import { Helmet, } from 'react-helmet';
import { useIntl, } from 'react-intl';
import Block1 from './component/Block1';
import Block2 from './component/Block2';
import Block3 from './component/Block3';
import Block4 from './component/Block4';
import Block5 from './component/Block5';
import { newsActions, } from '@/reducers/news.slice';
import { globalActions, } from '@/reducers/global.slice';
import { getNews, } from '@/reducers/states';
import Loading from '@/pages/Loading/Loading';

const Home = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { featureNews, } = useSelector(getNews);
  const { loaded, } = featureNews;
  const block2Ref = useRef<null | HTMLElement>(null);

  // meta tag文案
  const pageTitle = intl.formatMessage({ id: 'homepage.page.title', });
  const ogTagTitle = intl.formatMessage({ id: 'homepage.ogtag.title', });
  const ogTagDescription = intl.formatMessage({ id: 'homepage.ogtag.description', });

  const handleAnchorOnClick = () => {
    block2Ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'end',
    });
  };

  useEffect(() => {
    dispatch(newsActions.getFeatureNewsRequest({}));
  }, []);

  if (!loaded) return <Loading />;
  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={ogTagDescription}
        ></meta>
        <meta
          property="og:title"
          content={ogTagTitle}
        />
        <meta
          property="og:description"
          content={ogTagDescription}
        />
      </Helmet>
      <Block1 anchorOnClick={handleAnchorOnClick} />
      <Block2 block2Ref={block2Ref} />
      <Block3 />
      <Block4 />
      <Block5 />
    </Box>
  );
};

export default Home;