import { request, } from '@/utils';

export async function getCompanyHistory(payload: any): Promise<any> {
  const { fields, } = payload;
  const params = {
    fields: fields ?? '*.*',
    sort: '-year,-month',
    filter: { status: { _eq: 'published', }, },
  };
  return request.get(`/items/history`, { params, });
}