import React from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme, Typography, Divider,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({ container: {
  padding: '120px 120px 0 120px',
  marginBottom: '120px',
  [theme.breakpoints.down('md')]: { padding: '60px 24px 0px 24px', },
}, }));

const Content2Links = [
  {
    content: 'antifraud.page.content.content2.link1',
    link: 'https://165.npa.gov.tw/#/',
  },
  {
    content: 'antifraud.page.content.content2.link2',
    link: 'https://moneywise.fsc.gov.tw/index.jsp',
  },
  {
    content: 'antifraud.page.content.content2.link3',
    link: 'https://www.sfb.gov.tw/ch/home.jsp?id=775',
  },
  {
    content: 'antifraud.page.content.content2.link4',
    link: 'https://investoredu.twse.com.tw/pages/TWSE_InvestmentRisk6_2.aspx',
  },
  {
    content: 'antifraud.page.content.content2.link5',
    link: 'https://www.twse.com.tw/anti-fraud/zh/index.html',
  },
  {
    content: 'antifraud.page.content.content2.link6',
    link: 'https://www.taifex.com.tw/file/taifex/event/cht/anti_fraud/index.html',
  },
  {
    content: 'antifraud.page.content.content2.link7',
    link: 'https://www.tpex.org.tw/web/anti-fraud/',
  },
  {
    content: 'antifraud.page.content.content2.link8',
    link: 'http://info.csa.org.tw/',
  },
  {
    content: 'antifraud.page.content.content2.link9',
    link: 'https://www.futures.org.tw/antiFraud/list?active=newsAndPropagate%2520#a1',
  },
];

const Content = [{
  title: 'antifraud.page.content.title1',
  content: 'antifraud.page.content.content1',
}, {
  title: 'antifraud.page.content.title2',
  content: Content2Links,
},];

const BlockTwo = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box className={classes.container}>
      <Typography
        fontSize={ isMobile ? '24px' : '40px'}
        fontWeight={700}
        color={theme.palette.secondary.dark}
        mb={7}
      >
        <FormattedMessage id="antifraud.page.title" />
      </Typography>
      <Typography
        fontSize={ isMobile ? '14px' : '16px'}
        fontWeight={400}
        color={theme.palette.secondary.dark}
        mb={4}
        sx={{ opacity: 0.85, }}
      >
        <FormattedMessage
          id="antifraud.page.description"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
            a1:(...msg) =>
              <a
                style={{ 'color': theme.palette.secondary.dark, }}
                target="_blank" href="https://165.npa.gov.tw/#/report/statement"
              >
                {msg}
              </a>,
            a2:(...msg) =>
              <a
                style={{ 'color': theme.palette.secondary.dark, }}
                target="_blank" href="https://www.mjib.gov.tw/Poll?Module=2"
              >
                {msg}
              </a>,
          }}
        />
      </Typography>
      {Content.map((item) => (
        <Box key={item.title}>
          <Typography
            fontSize={16}
            fontWeight={700}
            color={theme.palette.secondary.dark}
            mb={2}
          >
            <FormattedMessage id={item.title} />
          </Typography>
          <Typography
            fontSize={isMobile ? '14px' : '16px'}
            fontWeight={400}
            color={theme.palette.secondary.dark}
            mb={4}
            sx={{ opacity: 0.85, }}
          >
            {
              typeof item.content === 'string' ?
                <FormattedMessage
                  id={item.content}
                  values={{
                    p: (...chunks) => <p>{chunks}</p>,
                    br: <br />,
                    ul:(...msg) => <ul style={{
                      listStyleType: 'disc', paddingInlineStart: '1.5rem',
                    }}>{msg}</ul>,
                    li:(...msg) => <li style={{ lineHeight: '2rem', }}>{msg}</li>,
                    b:(...msg) => <b>{msg}</b>,
                  }}
                />
                :
                <ul style={{
                  listStyleType: 'disc', paddingInlineStart: '1.5rem',
                }}>
                  {
                    item.content.map((linkItem)=>(
                      <li style={{ lineHeight: '2rem', }}>
                        <a target="_blank" href={linkItem.link} style={{ 'color': '#0E2338D9', }}>
                          <FormattedMessage
                            id={linkItem.content}
                          />
                        </a>
                      </li>
                    ))
                  }
                </ul>
            }
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default React.memo(BlockTwo);