import React from 'react';
import {
  Link, useNavigate,
} from 'react-router-dom';
import {
  Box, Theme, Typography, Button, Divider, Grid,
} from '@mui/material';
import {
  FormattedMessage, useIntl,
} from 'react-intl';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import PaginationButton from '@/components/PaginationButton';
import BackgroudImage from '../assets/Block4/background.png';
import BlueLine from '../assets/Block4/blue-line.png';
import EsgImage1 from '../assets/Block4/esg-image1.png';
import EsgImage2 from '../assets/Block4/esg-image2.png';
import EsgImage3 from '../assets/Block4/esg-image3.png';
import EsgImage4 from '../assets/Block4/esg-image4.png';
import EsgImage2Mobile from '../assets/Block4/esg-image2-mobile.png';
import EsgImage3Mobile from '../assets/Block4/esg-image3-mobile.png';
import EsgImage4Mobile from '../assets/Block4/esg-image4-mobile.png';
import PrimaryButton from '@/components/PrimaryButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundPosition: 'center',
    flexDirection: 'column',
    backgroundSize: 'cover',
    padding: '120px',
    [theme.breakpoints.down(800)]: {
      padding: '60px 24px',
      overflow: 'hidden',
    },
  },
  info1Container: {
    width: '750px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '32px',
    [theme.breakpoints.down(800)]: { width: '100%', },
  },
  info2Container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '120px',
    gap: '40px',
    [theme.breakpoints.down(1200)]: {
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column',
      marginTop: '60px',
    },
  },
  info2Description: {
    width: '692px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down(800)]: { width: '100%', },
  },
  bossNameAndTitle: {
    borderRadius: '20px',
    backgroundColor: '#e6e6e6',
    padding: '12px 16px',
    alignSelf: 'flex-end',
  },
  info3Container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '40px',
    marginTop: '162px',
    [theme.breakpoints.down(1200)]: {
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column-reverse',
      marginTop: '60px',
    },
  },
  info3Description: {
    width: '610px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingTop: '16px',
    [theme.breakpoints.down(800)]: { width: '100%', },
  },
  infoTitleUnderLine: {
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.secondary.light,
    marginBottom: '24px',
  },
  info4Container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '40px',
    marginTop: '60px',
    [theme.breakpoints.down(1200)]: {
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column',
      marginTop: '40px',
    },
  },
  info5Container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '40px',
    marginTop: '60px',
    [theme.breakpoints.down(1200)]: {
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column-reverse',
      marginTop: '40px',
    },
  },
}));

const BlockFour = ({ id, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const intl = useIntl();
  const { locale, } = useSelector(getGlobal);
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <div
      id={id}
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroudImage})`, }}
    >
      {/* 上方藍色線條圖片 */}
      <img
        width="100%"
        src={BlueLine}
        alt={intl.formatMessage({ id: 'esg.blockfure.title.1', })}
      />

      {/* 公司治理 */}
      <Box className={classes.info1Container}>
        <Typography
          color={theme.palette.secondary.light}
          fontSize={'24px'}
          fontWeight={'bold'}
          marginTop={'16px'}
          marginBottom={'24px'}
        >
          <FormattedMessage id={'esg.blockfure.title.1'} />
        </Typography>

        <Typography
          color={'#515151'}
          fontSize={isMobile ? '16px' : '14px'}
          textAlign="center"
        >
          <FormattedMessage id={'esg.blockfure.description.1'} />
        </Typography>
      </Box>

      <Box display={'flex'}>
        <PrimaryButton colorStyle="green">
          <FormattedMessage id={'esg.blockfure.button'} />
        </PrimaryButton>
        <Link
          to={`/${locale}/prevent_harassment`}
          style={{ textDecoration: 'none', }}
        >
          <Box ml={2}>
            <PrimaryButton colorStyle="green" >
              <FormattedMessage id={'esg.blockfure.button.2'} />
            </PrimaryButton>
          </Box>
        </Link>
      </Box>


      {/* 經營者的話 */}
      <Box className={classes.info2Container}>
        <img
          width={isMobile ? '160px' : '348px'}
          src={EsgImage1}
          alt={intl.formatMessage({ id: 'esg.blockfure.title.2', })}
        />
        <Box className={classes.info2Description}>
          <Typography
            color={theme.palette.secondary.dark}
            fontWeight={'bold'}
            fontSize={isMobile ? '24px' : '40px'}
          >
            <FormattedMessage id={'esg.blockfure.title.2'} />
          </Typography>
          <Typography
            color={'#515151'}
            fontSize={isMobile ? '14px' : '16px'}
          >
            <FormattedMessage id={'esg.blockfure.description.2-1'} />
          </Typography>
          <Typography
            color={'#515151'}
            fontSize={isMobile ? '14px' : '16px'}
          >
            <FormattedMessage id={'esg.blockfure.description.2-2'} />
          </Typography>
          <Box className={classes.bossNameAndTitle}>
            <Typography fontSize={isMobile ? '10px' : '12px'}>
              <FormattedMessage id={'esg.blockfure.description.boss_name_title'} />
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* 誠信經營 */}
      <Box className={classes.info3Container}>
        <Box className={classes.info3Description}>
          <ScrollAnimation animateIn="fadeInLeft">
            <Typography
              color={theme.palette.secondary.dark}
              fontWeight={'bold'}
              fontSize={isMobile ? '16px' : '24px'}
            >
              <FormattedMessage id={'esg.blockfure.title.3'} />
            </Typography>
            <Box className={classes.infoTitleUnderLine}></Box>
            <Typography
              color={'#515151'}
              fontSize={isMobile ? '14px' : '16px'}
            >
              <FormattedMessage id={'esg.blockfure.description.3'} />
            </Typography>
          </ScrollAnimation>
        </Box>
        <img
          width={isMobile ? '100%' : '300px'}
          src={isMobile ? EsgImage2Mobile : EsgImage2}
          alt={intl.formatMessage({ id: 'esg.blockfure.title.3', })}
        />
      </Box>

      {/* 法規遵循 */}
      <Box className={classes.info4Container}>
        <img
          width={isMobile ? '100%' : '300px'}
          src={isMobile ? EsgImage3Mobile : EsgImage3}
          alt={intl.formatMessage({ id: 'esg.blockfure.title.4', })}
        />
        <Box className={classes.info3Description}>
          <ScrollAnimation animateIn="fadeInRight">
            <Typography
              color={theme.palette.secondary.dark}
              fontWeight={'bold'}
              fontSize={isMobile ? '16px' : '24px'}
            >
              <FormattedMessage id={'esg.blockfure.title.4'} />
            </Typography>
            <Box className={classes.infoTitleUnderLine}></Box>
            <Typography
              color={'#515151'}
              fontSize={isMobile ? '14px' : '16px'}
            >
              <FormattedMessage id={'esg.blockfure.description.4'} />
            </Typography>
          </ScrollAnimation>
        </Box>
      </Box>

      {/* 企業治理 */}
      <Box className={classes.info5Container}>
        <Box className={classes.info3Description}>
          <ScrollAnimation animateIn="fadeInLeft">
            <Typography
              color={theme.palette.secondary.dark}
              fontWeight={'bold'}
              fontSize={isMobile ? '16px' : '24px'}
            >
              <FormattedMessage id={'esg.blockfure.title.5'} />
            </Typography>
            <div className={classes.infoTitleUnderLine}></div>
            <Typography
              color={'#515151'}
              fontSize={isMobile ? '14px' : '16px'}
              whiteSpace="pre-line"
            >
              <FormattedMessage id={'esg.blockfure.description.5'} />
            </Typography>
          </ScrollAnimation>
        </Box>
        <img
          width={isMobile ? '100%' : '300px'}
          src={isMobile ? EsgImage4Mobile : EsgImage4}
          alt={intl.formatMessage({ id: 'esg.blockfure.title.5', })}
        />
      </Box>
    </div>
  );
};

export default BlockFour;