import React from 'react';
import {
  Box, Theme, Typography,
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import { makeStyles, } from '@mui/styles';
import { FormattedMessage, } from 'react-intl';
import useMediaQuery from '@mui/material/useMediaQuery';
import ContentTable from './ContentTable';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '120px',
    [theme.breakpoints.down(800)]: { padding: '80px 24px', },
  },
  topFilter: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '315px',
    background: 'linear-gradient(180deg, rgba(50, 115, 182, 0.1) 0%, rgba(133, 193, 31, 0) 53.12%)',
    zIndex: 1,
  },
  title: {
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  },
  contentContainer: {
    maxWidth: '940px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    gap: '40px',
  },
  description: {
    width: '100%',
    marginBottom: '48px',
  },
  subTitle: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '1px solid rgba(209, 210, 212, 0.45)',
    marginBottom: '24px',
  },
  content: {
    color: theme.palette.secondary.dark,
    opacity: 0.85,
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: '24px',
  },
}));

// 工作說明列表資料
const Description2 = [
  'investor.blockfour.data.2.content.1',
  'investor.blockfour.data.2.content.2',
  'investor.blockfour.data.2.content.3',
];

// 最新公告表格資料
const rows1 = [[
  'investor.blockfour.data.3.table.row.1.1',
  'investor.blockfour.data.3.table.row.1.2',
  'investor.blockfour.data.3.table.row.1.3',
], [
  'investor.blockfour.data.3.table.row.2.1',
  'investor.blockfour.data.3.table.row.2.2',
  'investor.blockfour.data.3.table.row.2.3',
],];

const headers1 = [
  'investor.blockfour.data.3.table.head.1',
  'investor.blockfour.data.3.table.head.2',
  'investor.blockfour.data.3.table.head.3',
];

const BlockFour = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <Box
      id="internal"
      className={classes.container}
    >
      <Box className={classes.topFilter} />
      <Box className={classes.title}>
        <Typography
          variant="h2"
          fontSize={isMobile ? '24px' : '40px'}
          fontWeight="700"
          color={theme.palette.secondary.dark}
          mb={3}
        >
          <FormattedMessage id="investor.blockfour.title" />
        </Typography>
      </Box>
      {/* 內容 */}
      <Box className={classes.contentContainer}>
        {/* 運作說明 */}
        <Box className={classes.description}>
          <Box className={classes.subTitle}>
            <Typography
              variant="h3"
              fontSize={isMobile ? '16px' : '24px'}
              fontWeight="700"
              color={theme.palette.secondary.light}
              pb={2}
            >
              <FormattedMessage id="investor.blockfour.data.1.title" />
            </Typography>
          </Box>
          <Typography
            className={classes.content}
            fontSize={isMobile ? '14px' : '16px'}
          >
            <FormattedMessage id="investor.blockfour.data.1.content.1" />
          </Typography>
        </Box>
        {/* 工作重點說明 */}
        <Box className={classes.description}>
          <Box className={classes.subTitle}>
            <Typography
              variant="h3"
              fontSize={isMobile ? '16px' : '24px'}
              fontWeight="700"
              color={theme.palette.secondary.light}
              pb={2}
            >
              <FormattedMessage id="investor.blockfour.data.2.title" />
            </Typography>
          </Box>
          {Description2.map((item, index) => (
            <Box
              key={index}
              display="flex"
              mb={3}
            >
              <Typography
                fontSize="16px"
                fontWeight="700"
                color={theme.palette.secondary.light}
                pr={2}
              >
                {index + 1}
              </Typography>
              <Typography
                className={classes.content}
                fontSize={isMobile ? '14px' : '16px'}
              >
                <FormattedMessage id={item} />
              </Typography>
            </Box>
          ))}
        </Box>

        {/* 獨立董事與內部稽核主管及簽證會計師溝通事項 */}
        <Box className={classes.description}>
          <Box className={classes.subTitle}>
            <Typography
              variant="h3"
              fontSize={isMobile ? '16px' : '24px'}
              fontWeight="700"
              color={theme.palette.secondary.light}
              pb={2}
            >
              <FormattedMessage id="investor.blockfour.data.3.title" />
            </Typography>
          </Box>
          <ContentTable
            headers={headers1}
            rows={rows1}
          />

          <Typography
            fontSize={isMobile ? '14px' : '16px'}
            className={classes.content}
            mt={2}
          >
            <FormattedMessage id="investor.blockfour.data.3.table.remark" />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(BlockFour);