import {
  type FC, type ComponentProps, useState, useEffect, type ReactNode,
} from 'react';
import {
  Box, type Theme, Typography, styled, type SxProps,
} from '@mui/material';
import {
  FormattedMessage, injectIntl,
} from 'react-intl';
import { getImage, } from '@/utils/tool';
import { ReactComponent as Tech1Icon, } from '../assets/Block8/tech1.svg';
import { ReactComponent as Tech2Icon, } from '../assets/Block8/tech2.svg';
import { ReactComponent as Tech3Icon, } from '../assets/Block8/tech3.svg';

interface TechCardProps {
  cardColor?: string;
  background?: string;
  data: {
    id: number;
    icon?: ReactNode;
    title?: ReactNode;
    titleSx?: SxProps<Theme>;
  }[];
}

interface TechGroupProps {
  icon?: ReactNode;
  cardTitle?: ReactNode;
  data: TechCardProps;
}

const techData: TechCardProps = {
  cardColor: '#7CAADA',
  background: 'linear-gradient(180deg, rgba(124, 170, 218, 0.25) 0%, rgba(124, 170, 218, 0.0625) 100%)',
  data: [
    {
      id: 1,
      title: (
        <FormattedMessage
          id="service.block8.tech1.list.item1"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 2,
      title: (
        <FormattedMessage
          id="service.block8.tech1.list.item2"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 3,
      title: (
        <FormattedMessage
          id="service.block8.tech1.list.item3"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 4,
      title: (
        <FormattedMessage
          id="service.block8.tech1.list.item4"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 5,
      title: (
        <FormattedMessage
          id="service.block8.tech1.list.item5"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 6,
      title: (
        <FormattedMessage
          id="service.block8.tech1.list.item6"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 7,
      title: (
        <FormattedMessage
          id="service.block8.tech1.list.item7"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 8,
      title: (
        <FormattedMessage
          id="service.block8.tech1.list.item8"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 9,
      title: (
        <FormattedMessage
          id="service.block8.tech1.list.item9"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
  ],
};

const qualifyData: TechCardProps = {
  cardColor: '#F4D978',
  background: 'linear-gradient(180deg, rgba(255, 246, 200, 0.65) 0%, rgba(255, 246, 200, 0.1625) 100%)',
  data: [
    {
      id: 1,
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item1"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 2,
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item2"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 3,
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item3"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 4,
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item4"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 5,
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item5"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 6,
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item6"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 7,
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item7"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 8,
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item8"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 9,
      icon: (
        <img
          src={getImage('service/assets/Block8/ISO_9001_2015.png')}
          alt="ISO_9001_2015"
          width={40}
        />
      ),
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item9"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
      titleSx: [{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '17px',
      },],
    },
    {
      id: 10,
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item10"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 11,
      title: (
        <FormattedMessage
          id="service.block8.tech2.list.item11"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
  ],
};

const hseData: TechCardProps = {
  cardColor: '#85C11F',
  background: 'linear-gradient(180deg, rgba(192, 237, 114, 0.35) 0%, rgba(192, 237, 114, 0.0875) 100%)',
  data: [
    {
      id: 1,
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item1"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 2,
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item2"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 3,
      icon: (
        <img
          src={getImage('service/assets/Block8/ISO_14064_1.png')}
          alt="ISO_9001_2015"
          width={40}
        />
      ),
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item3"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
      titleSx: [{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '17px',
      },],
    },
    {
      id: 4,
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item4"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 5,
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item5"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 6,
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item6"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 7,
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item7"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 8,
      icon: (
        <img
          src={getImage('service/assets/Block8/ISO_14001_2015.png')}
          alt="ISO_9001_2015"
          width={40}
        />
      ),
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item8"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
      titleSx: [{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '17px',
      },],
    },
    {
      id: 9,
      icon: (
        <img
          src={getImage('service/assets/Block8/ISO_14001_2018.png')}
          alt="ISO_9001_2015"
          width={40}
        />
      ),
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item9"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
      titleSx: [{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '17px',
      },],
    },
    {
      id: 10,
      icon: (
        <img
          src={getImage('service/assets/Block8/ISO-50001.png')}
          alt="ISO-50001"
          width={40}
        />
      ),
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item10"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
      titleSx: [{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '17px',
      },],
    },
    {
      id: 11,
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item11"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 12,
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item12"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
    {
      id: 13,
      title: (
        <FormattedMessage
          id="service.block8.tech3.list.item13"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
    },
  ],
};

const TechCard: FC<TechCardProps & ComponentProps<typeof Box>> = ({
  cardColor, data, background, ...rest
}) => {
  return (
    <Box {...rest}>
      {data.map((item, i) => (
        <Box
          key={item.id}
          sx={[{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gridColumn: data.length % 2 === 1 && data.length - 1 === i ? 'span 2 / span 2' : undefined,
          }, (theme) => ({ [theme.breakpoints.up('lg')]: { gridColumn: 'span 1 / span 1', }, }),]}
        >
          <Box
            sx={[{
              width: '120px',
              height: '120px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '3px',
              borderRadius: '9999px',
              border: '1px solid',
              borderColor: cardColor,
              background: background,
              padding: '13px',
              textAlign: 'center',
            },]}
          >
            {item.icon}
            <Typography
              sx={
                item.titleSx
                  ? item.titleSx
                  : [{
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '17px',
                  },]
              }
            >
              {item.title}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const TechGroup: FC<TechGroupProps & ComponentProps<typeof Box>> = (props) => {
  const {
    icon, cardTitle, data, ...rest
  } = props;
  return (
    <Box
      sx={[{
        borderRadius: '20px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        padding: '24px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#FFFFFF',
      },]}
    >
      <Box
        sx={[{
          position: 'absolute',
          width: '60px',
          height: '60px',
          borderRadius: '9999px',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
          top: '-30px',
          background: '#FFFFFF',
          zIndex: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },]}
      >
        {icon}
      </Box>
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '22px',
          textAlign: 'center',
          marginTop: '16px',
          marginBottom: '24px',
        }, (theme) => ({ [theme.breakpoints.up('lg')]: {
          marginTop: '8px',
          fontSize: '24px',
          lineHeight: '33px',
        }, }),]}
      >
        {cardTitle}
      </Typography>
      <TechCard
        cardColor={data.cardColor}
        data={data.data}
        background={data.background}
        {...rest}
      />
    </Box>
  );
};

const Block8: FC<ComponentProps<typeof Box>> = (props) => {
  return (
    <Box
      id="technical_certification"
      sx={[{
        minHeight: '890px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: '80px',
        paddingX: '24px',
      },]}
      {...props}
    >
      <Typography
        sx={[{
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '33px',
          marginBottom: '57px',
        }, (theme) => ({ [theme.breakpoints.up('lg')]: {
          fontSize: '40px',
          lineHeight: '54px',
          marginBottom: '90px',
        }, }),]}
      >
        <FormattedMessage id="service.block8.title" />
      </Typography>
      <Box
        sx={[{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: '70px',
          width: '100%',
        }, (theme) => ({ [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
          gap: '24px',
        }, }),]}
      >
        <TechGroup
          cardTitle={<FormattedMessage id="service.block8.tech1.title" />}
          icon={<Tech1Icon />}
          data={techData}
          sx={[{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            rowGap: '24px',
            columnGap: '16px',
          },]}
        />
        <TechGroup
          cardTitle={<FormattedMessage id="service.block8.tech2.title" />}
          icon={<Tech2Icon />}
          data={qualifyData}
          sx={[{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            rowGap: '24px',
            columnGap: '16px',
          }, (theme) => ({ [theme.breakpoints.up('lg')]: { gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', }, }),]}
        />
        <TechGroup
          cardTitle={<FormattedMessage id="service.block8.tech3.title" />}
          icon={<Tech3Icon />}
          data={hseData}
          sx={[{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            rowGap: '24px',
            columnGap: '16px',
          }, (theme) => ({ [theme.breakpoints.up('lg')]: { gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', }, }),]}
        />
      </Box>
    </Box>
  );
};

export default Block8;