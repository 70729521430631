import {
  Box, Button,
} from '@mui/material';
import {
  styled, type SxProps, type Theme,
} from '@mui/system';
import { type ComponentProps, } from 'react';

interface Props extends ComponentProps<typeof Button> {
  colorStyle?: 'green' | 'white';
  containerSx?: SxProps<Theme>;
}

const Container = styled(Box)(({
  color, theme,
}) => ({
  position: 'relative',
  cursor: 'pointer',
  lineHeight: '1',
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 10px',
  border: `1px solid ${color === 'green' ? theme.palette.primary.main : theme.palette.default.main}`,
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: color === 'green' ? theme.palette.primary.main : theme.palette.default.main,
    zIndex: 20,
    '& p': { color: color === 'green' ? theme.palette.default.main : theme.palette.primary.main, },
    '& div': { display: 'none', },
  },
}));

const BackgroundOne = styled(Box)(({ theme, }) => ({
  position: 'absolute',
  width: '2px',
  height: '13px',
  backgroundColor: 'white',
  left: -1,
  top: '40%',
  zIndex: 10,
}));

const BackgroundTwo = styled(Box)(({ theme, }) => ({
  position: 'absolute',
  width: '2px',
  height: '13px',
  backgroundColor: 'white',
  right: -1,
  top: '40%',
  zIndex: 10,
}));

const Text = styled('p')(({
  color, theme,
}) => ({
  color: color === 'green' ? theme.palette.primary.main : theme.palette.default.main,
  fontSize: '16px',
  fontWeight: '500',
  zIndex: 20,
}));

const PrimaryButton = ({
  children, colorStyle, containerSx, ...rest
}: Props) => {
  return (
    <Button style={{ padding: 0, }} {...rest}>
      <Container color={colorStyle} sx={containerSx} className="primary-button-container">
        <Text color={colorStyle}>{children}</Text>
        <BackgroundOne />
        <BackgroundTwo />
      </Container>
    </Button>
  );
};

export default PrimaryButton;