import React from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme,
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import BackgroundImage from '../assets/block1-background.png';
import BackgroundFilterImage from '../assets/block1-filter.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '400px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    paddingTop: '72px',
    [theme.breakpoints.down('md')]: { height: '300px', },
  },
  filter: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxHeight: '400px',
    backgroundPosition: 'center',
    top: 0,
    left: 0,
    [theme.breakpoints.down('md')]: { maxHeight: '300px', },
  },
}));

const BlockOne = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroundImage})`, }}
    >
      <Box
        className={classes.filter}
        style={{ backgroundImage: `url(${BackgroundFilterImage})`, }}
      />
    </Box>
  );
};

export default React.memo(BlockOne);