export const getImage = (url: string) => {
  return require(`../pages/${url}`);
};

export const isLink = (path: string) => {
  return path.includes('http');
};

/**
 *
 * @param data api 資料回傳 translations
 * @param language 當前語系
 * @returns 對應當前語系 translations
 */

export const getLanguageNewsData = (data: any[], language: string) => {
  const languageData = data.find((item: any) => {
    return item.languages_id.code.toLowerCase().split('-').join('') === language.split('_').join('');
  });
  if (!!languageData) {
    return languageData;
  } else {
    return data[0];
  }
};