import {
  put, takeLatest, call,
} from 'redux-saga/effects';
import { newsActions, } from '@/reducers/news.slice';
import {
  getNews, getFeatureNews, getNewsTags, getNewsContent,
} from '@/serivces/newsApi';
import { AxiosResponse, } from 'axios';

export function* getNewsSaga({ payload, }: ReturnType<typeof newsActions.getNewsRequest>) {
  try {
    const res: AxiosResponse = yield call(getNews, payload);
    yield put(newsActions.getNewsSuccess(res));
  } catch (error) {
    yield put(newsActions.getNewsFail(error));
  }
}

export function* getNewsTagsSaga({ payload, }: ReturnType<typeof newsActions.getNewsTagsRequest>) {
  try {
    const res: AxiosResponse = yield call(getNewsTags, payload);
    yield put(newsActions.getNewsTagsSuccess(res));
  } catch (error) {
    yield put(newsActions.getNewsTagsFail(error));
  }
}

export function* getFeatureNewsSaga({ payload, }: ReturnType<typeof newsActions.getFeatureNewsRequest>) {
  try {
    const res: AxiosResponse = yield call(getFeatureNews, payload);
    yield put(newsActions.getFeatureNewsSuccess(res));
  } catch (error) {
    yield put(newsActions.getFeatureNewsFail(error));
  }
}

export function* getNewsContentSaga({ payload, }: ReturnType<typeof newsActions.getNewsContentRequest>) {
  try {
    const res: AxiosResponse = yield call(getNewsContent, payload);
    yield put(newsActions.getNewsContentSuccess(res));
  } catch (error) {
    yield put(newsActions.getNewsContentFail(error));
  }
}

export function* getRecentUpdateNewsSaga({ payload, }: ReturnType<typeof newsActions.getNewsRequest>) {
  try {
    const res: AxiosResponse = yield call(getNews, payload);
    yield put(newsActions.getRecentUpdateNewsSuccess(res));
  } catch (error) {
    yield put(newsActions.getRecentUpdateNewsFail(error));
  }
}

const sagas = [
  takeLatest(newsActions.getNewsRequest, getNewsSaga),
  takeLatest(newsActions.getNewsTagsRequest, getNewsTagsSaga),
  takeLatest(newsActions.getFeatureNewsRequest, getFeatureNewsSaga),
  takeLatest(newsActions.getNewsContentRequest, getNewsContentSaga),
  takeLatest(newsActions.getRecentUpdateNewsRequest, getRecentUpdateNewsSaga),
];

export default sagas;