import board1 from './assets/block3/board1.png';
import board2 from './assets/block3/board2.png';
import board3 from './assets/block3/board3.png';
import board4 from './assets/block3/board4.png';
import board5 from './assets/block3/board5.png';
import board6 from './assets/block3/board5.png';
import board7 from './assets/block3/board5.png';
import board8 from './assets/block3/board5.png';
import defaultMan from './assets/block3/default.png';

const data = [
  {
    id: 1,
    image: board1,
    name: 'investor.blockthree.board.data.1.name',
    title: 'investor.blockthree.board.data.1.title',
    incumbent: 'investor.blockthree.board.data.1.incumbent',
    serve: 'investor.blockthree.board.data.1.serve',
    educationalBackground: 'investor.blockthree.board.data.1.educationalBackground',
  },
  {
    id: 2,
    image: board2,
    name: 'investor.blockthree.board.data.2.name',
    title: 'investor.blockthree.board.data.2.title',
    incumbent: 'investor.blockthree.board.data.2.incumbent',
    serve: 'investor.blockthree.board.data.2.serve',
    educationalBackground: null,
  },
  {
    id: 3,
    image: board3,
    name: 'investor.blockthree.board.data.3.name',
    title: 'investor.blockthree.board.data.3.title',
    incumbent: 'investor.blockthree.board.data.3.incumbent',
    serve: 'investor.blockthree.board.data.3.serve',
    educationalBackground: 'investor.blockthree.board.data.3.educationalBackground',
  },
  {
    id: 4,
    image: board4,
    name: 'investor.blockthree.board.data.4.name',
    title: 'investor.blockthree.board.data.4.title',
    incumbent: 'investor.blockthree.board.data.4.incumbent',
    serve: null,
    educationalBackground: 'investor.blockthree.board.data.4.educationalBackground',
  },
  {
    id: 5,
    image: defaultMan,
    name: 'investor.blockthree.board.data.5.name',
    title: 'investor.blockthree.board.data.5.title',
    incumbent: 'investor.blockthree.board.data.5.incumbent',
    serve: 'investor.blockthree.board.data.5.serve',
    educationalBackground: 'investor.blockthree.board.data.5.educationalBackground',
  },
  {
    id: 6,
    image: defaultMan,
    name: 'investor.blockthree.board.data.6.name',
    title: 'investor.blockthree.board.data.6.title',
    incumbent: 'investor.blockthree.board.data.6.incumbent',
    serve: 'investor.blockthree.board.data.6.serve',
    educationalBackground: 'investor.blockthree.board.data.6.educationalBackground',
  },
  {
    id: 7,
    image: defaultMan,
    name: 'investor.blockthree.board.data.7.name',
    title: 'investor.blockthree.board.data.7.title',
    incumbent: 'investor.blockthree.board.data.7.incumbent',
    serve: 'investor.blockthree.board.data.7.serve',
    educationalBackground: 'investor.blockthree.board.data.7.educationalBackground',
  },
  {
    id: 8,
    image: defaultMan,
    name: 'investor.blockthree.board.data.8.name',
    title: 'investor.blockthree.board.data.8.title',
    incumbent: 'investor.blockthree.board.data.8.incumbent',
    serve: 'investor.blockthree.board.data.8.serve',
    educationalBackground: 'investor.blockthree.board.data.8.educationalBackground',
  },
];

export default data;