import {
  type FC, useState, useEffect, type ComponentProps, type ReactNode,
} from 'react';
import { useTheme, } from '@mui/material/styles';
import useEmblaCarousel, { type EmblaOptionsType, } from 'embla-carousel-react';
import Autoplay from '@/utils/embla-carousel-autoplay/autoPlay';
import {
  Box,
  type Theme,
  Typography,
  styled,
  type SxProps,
} from '@mui/material';
import type { AutoplayOptionsType, } from '@/utils/embla-carousel-autoplay/Options';
import { getImage, } from '@/utils/tool';

type ImageData = {
  id: number;
  alt: string;
  src: string;
}

interface ImageCarouselProps extends ComponentProps<typeof Box> {
  images: ImageData[];
  autoplayOptions?: AutoplayOptionsType;
  imageSx?: SxProps<Theme>;
  slideSx?: SxProps<Theme>;
  emblaOptions?: EmblaOptionsType;
}

const ImageWrapper = styled('div')(({ theme, }) => ({
  borderRadius: '12px',
  overflow: 'hidden',
  background:
      'linear-gradient(165.95deg, rgba(50, 115, 182, 0) 55.21%, rgba(50, 115, 182, 0.25) 92.91%)',
  aspectRatio: '500 / 338',
}));

const Image = styled('img')(({ theme, }) => ({
  width: '100%',
  objectFit: 'cover',
}));

const Picture: FC<{ src: string; alt: string; sx?: SxProps<Theme> }> = ({
  src,
  alt,
  sx,
}) => {
  return (
    <ImageWrapper sx={sx}>
      <Image
        src={src}
        alt={alt}
      />
    </ImageWrapper>
  );
};

const ImageCarousel: FC<ImageCarouselProps> = ({
  images, autoplayOptions, imageSx, slideSx, emblaOptions = {}, ...rest
}) => {
  const autoplay = Autoplay(autoplayOptions);
  const theme = useTheme();
  const [currentSlideIndex, setCurrentSlideIndex,] = useState<number | undefined>(0);
  const [emblaRef, emblaApi,] = useEmblaCarousel({
    loop: true,
    draggable: true,
    align: 'start',
    ...emblaOptions,
  }, [autoplay,]);

  const scrollTo = (index: any) => {
    emblaApi && emblaApi.scrollTo(index);
  };

  useEffect(() => {
    const onSlideChange = () => {
      setCurrentSlideIndex(emblaApi?.selectedScrollSnap());
    };
    emblaApi?.on('select', onSlideChange);
    return () => {
      emblaApi?.off('select', onSlideChange);
    };
  }, [emblaApi,]);

  useEffect(() => {
    scrollTo(currentSlideIndex);
  }, [currentSlideIndex,]);
  return (
    <Box sx={{
      width: '100%', direction: emblaOptions?.direction,
    }} {...rest}>
      <Box
        sx={{
          overflow: 'hidden',
          direction: emblaOptions?.direction,
        }}
        ref={emblaRef}
      >
        <Box sx={{
          display: 'flex', flex: '0 0 1', direction: emblaOptions?.direction,
        }}>
          {images.map((data) => {
            return (
              <Box sx={slideSx} key={data.id}>
                <Picture
                  sx={imageSx}
                  src={getImage(data.src)}
                  alt={data.alt}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      {/* 圖片輪播的綠色圓點 */}
      <Box sx={[{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        gap: '8px',
        margin: '25px 0px',
      },]}>
        {images.map((data, index) => {
          const isCurrentVideo = currentSlideIndex === index;
          return (
            <Box
              key={data.id}
              sx={[{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                cursor: 'pointer',
                backgroundColor: isCurrentVideo ? theme.palette.primary.main : 'rgba(133, 193, 31, 0.25)',
              },]
              }
              onClick={() => {
                setCurrentSlideIndex(index);
              }}
            ></Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ImageCarousel;
export type {
  ImageCarouselProps, ImageData,
};