import React, {
  useState, useEffect,
} from 'react';
import {
  useNavigate, Link, useLocation,
} from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  Box, Theme, Typography,
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import NavigateButton from './component/HeaderButton';
import path from './path.json';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import PaginationButton from '@/components/PaginationButton';
import TextButton from '@/components/TextButton';
import RectangleButton from '@/components/RectangleButton';
import { isLink, } from '@/utils/tool';
import ApexLogo from './assets/footer-logo-apex.png';
import GreenLogo from './assets/footer-logo-100+.png';
import BlueCover1 from './assets/footer-cover-blue1.png';
import BlueCover1Mobile from './assets/footer-cover-blue1-mobile.png';
import BlueCover2 from './assets/footer-cover-blue2.png';
import BlueCover2Mobile from './assets/footer-cover-blue2-mobile.png';
import BackGroundWorker from './assets/footer-background-worker.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '900px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  mainTitle: {
    position: 'absolute',
    margin: '166px 120px',
    width: '422px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    zIndex: '1',
  },
  blueCoverContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    position: 'relative',
  },
  blueCover1: {
    width: '620px',
    height: '200px',
    backgroundPosition: 'right center',
    backgroundSize: 'cover',
    padding: '76px 92px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  blueCover2: {
    width: '100%',
    height: '450px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    padding: '100px 120px',
    gap: '120px',
  },
  greenLogo: {
    position: 'absolute',
    top: '79px',
    right: '81px',
  },
  companyInfo: { minWidth: '256px', },
  navigates: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  navigateItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  copyright: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '22px',
  },
  goTopAnchor: {
    position: 'absolute',
    bottom: '60px',
    right: '60px',
    [theme.breakpoints.down('md')]: { right: '24px', },
  },
  [theme.breakpoints.down(1280)]: {
    mainTitle: { margin: '100px 24px', },
    container: {
      width: '100%',
      height: '1400px',
    },
    blueCover2: {
      flexWrap: 'wrap',
      height: 'fit-content',
      padding: '60px 24px 120px 24px',
      gap: '100px',
    },
    navigates: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '87px',
      justifyContent: 'flex-start',
    },
  },
  [theme.breakpoints.down(900)]: {
    mainTitle: {
      position: 'absolute',
      width: '350px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      zIndex: '1',
    },
    blueCover1: {
      width: '260px',
      height: '84px',
      padding: '0 0 0 43px',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    blueCover2: { height: 'auto', },
    companyInfo: {
      width: '342px',
      height: 'fit-content',
    },
    navigates: {
      gap: '20px',
      width: '100%',
    },
    navigateItem: { flexBasis: '40%', },
    greenLogo: {
      width: '200px',
      height: '130px',
      top: '15%',
      right: '28px',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const isMobile = useMediaQuery('(min-width:900px)');

  const version = window.Config.IMAGE_VERSION === 'APP_IMAGE_VERSION' ? '' : window.Config.IMAGE_VERSION;

  return (
    <footer>
      <Box
        className={classes.container}
        style={{ backgroundImage: `url(${BackGroundWorker})`, }}
      >
        <Box className={classes.mainTitle}>
          <ScrollAnimation
            animateIn="fadeInDown"
            delay={1}
          >
            <Typography
              fontSize={isMobile ? '40px' : '24px'}
              fontWeight={'bold'}
              color={theme.palette.secondary.main}
              marginBottom={'24px'}
            >
              <FormattedMessage id={'footer.title.main'} />
            </Typography>
            <Typography
              fontSize={isMobile ? '16px' : '14px'}
              color={theme.palette.secondary.main}
              marginBottom={'24px'}
            >
              <FormattedMessage id={'footer.title.subtitle'} />
            </Typography>
          </ScrollAnimation>
          <Link
            to={`/${locale}/contact`}
            style={{ textDecoration: 'none', }}
          >
            <RectangleButton color="white">
              <Typography fontSize={locale === 'en_us' ? '14px' : '16px'}>
                <FormattedMessage id={'footer.title.main.button'} />
              </Typography>
            </RectangleButton>
          </Link>
        </Box>
        <Box className={classes.blueCoverContainer}>
          <Box
            className={classes.blueCover1}
            style={{ backgroundImage: `url(${isMobile ? BlueCover1 : BlueCover1Mobile})`, }}
          >
            {isMobile && (
              <Typography
                fontSize={'24px'}
                fontWeight={'bold'}
                color={theme.palette.secondary.main}
                marginBottom="16px"
              >
                <FormattedMessage id={'footer.title.100+'} />
              </Typography>
            )}
            <Link
              to={`/${locale}/service`}
              style={{
                height: 'fit-content',
                margin: 'auto 0',
                textDecoration: 'none',
              }}
            >
              <TextButton
                color="white"
                arrow
              >
                <FormattedMessage id={'footer.title.100+.button'} />
              </TextButton>
            </Link>
          </Box>
          <Box
            className={classes.blueCover2}
            style={{ backgroundImage: `url(${isMobile ? BlueCover2 : BlueCover2Mobile})`, }}
          >
            <Box className={classes.companyInfo}>
              <Box display="flex">
                <img
                  height="60px"
                  src={ApexLogo}
                  alt="footer-logo-apex"
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  ml="20px"
                >
                  <Typography
                    fontSize={'14px'}
                    color={theme.palette.secondary.main}
                  >
                    <FormattedMessage id={'footer.title.company_name.lin1'} />
                  </Typography>
                  <Typography
                    fontSize={'14px'}
                    color={theme.palette.secondary.main}
                  >
                    <FormattedMessage id={'footer.title.company_name.lin2'} />
                  </Typography>
                </Box>
              </Box>
              <Typography
                fontSize={'14px'}
                color={theme.palette.primary.main}
                mt={'16px'}
                mb={'16px'}
              >
                <FormattedMessage id={'footer.companyinfo.description'} />
              </Typography>
              <Typography
                fontSize="10px"
                color={theme.palette.default.contrastText}
                mb={'8px'}
              >
                <FormattedMessage id={'footer.companyinfo.address1'} />
              </Typography>
              <Typography
                fontSize="10px"
                color={theme.palette.default.contrastText}
              >
                <FormattedMessage id={'footer.companyinfo.address2'} />
              </Typography>
            </Box>
            <Box className={classes.navigates}>
              {path.map((item, index) => {
                if (index < 4) {
                  return (
                    <Box
                      key={index}
                      className={classes.navigateItem}
                    >
                      <Link
                        to={locale + item.path}
                        style={{ textDecoration: 'none', }}
                      >
                        <TextButton
                          color="white"
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            marginBottom: '16px',
                          }}
                        >
                          <FormattedMessage id={item.title} />
                        </TextButton>
                      </Link>
                      {item.menu?.map((menuItem: any, index) => {
                        const isLink = !!menuItem.link;
                        if (isLink) {
                          return (
                            <a
                              key={index}
                              href={menuItem.link}
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: 'none', }}
                            >
                              <TextButton
                                color="white"
                                style={{
                                  fontSize: '12px',
                                  marginBottom: '10px',
                                }}
                              >
                                <FormattedMessage id={menuItem.title} />
                              </TextButton>
                            </a>
                          );
                        } else {
                          return (
                            <Link
                              key={index}
                              to={
                                menuItem.anchor.includes('/')
                                  ? `${locale}${menuItem.anchor}`
                                  : `${locale}${item.path}${menuItem.anchor}`
                              }
                              style={{ textDecoration: 'none', }}
                            >
                              <TextButton
                                color="white"
                                style={{
                                  fontSize: '12px',
                                  marginBottom: '10px',
                                }}
                              >
                                <FormattedMessage id={menuItem.title} />
                              </TextButton>
                            </Link>
                          );
                        }
                      })}
                    </Box>
                  );
                }
              })}
              <Box
                className={classes.navigateItem}
              >
                <TextButton
                  color="white"
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginBottom: '16px',
                  }}
                >
                  <FormattedMessage id={'footer.help.title'} />
                </TextButton>
                {path.map((item, index) => {
                  if (index > 3) {
                    if (isLink(item.path)) {
                      return (
                        <a
                          key={index}
                          href={item.path}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none', }}
                        >
                          <TextButton
                            color="white"
                            style={{
                              fontSize: '12px',
                              marginBottom: '10px',
                            }}
                          >
                            <FormattedMessage id={item.title} />
                          </TextButton>
                        </a>
                      );
                    } else {
                      return (
                        <Link
                          key={index}
                          to={locale + item.path}
                          style={{ textDecoration: 'none', }}
                        >
                          <TextButton
                            color="white"
                            style={{
                              fontSize: '12px',
                              marginBottom: '10px',
                            }}
                          >
                            <FormattedMessage id={item.title} />
                          </TextButton>
                        </Link>
                      );
                    }
                  }
                })}
                {/* 隱私權條款、性騷擾防治因為 header 不用放所以寫死放這裡 */}
                <Link
                  to={`${locale}/privacy`}
                  style={{ textDecoration: 'none', }}
                >
                  <TextButton
                    color="white"
                    style={{
                      fontSize: '12px',
                      marginBottom: '10px',
                    }}
                  >
                    <FormattedMessage id="footer.privacy.path" />
                  </TextButton>
                </Link>
                <Link
                  to={`${locale}/prevent_harassment`}
                  style={{ textDecoration: 'none', }}
                >
                  <TextButton
                    color="white"
                    style={{
                      fontSize: '12px',
                      marginBottom: '10px',
                    }}
                  >
                    <FormattedMessage id="footer.harassment.path" />
                  </TextButton>
                </Link>
                <Link
                  to={`${locale}/antifraud`}
                  style={{ textDecoration: 'none', }}
                >
                  <TextButton
                    color="white"
                    style={{
                      fontSize: '12px',
                      marginBottom: '10px',
                    }}
                  >
                    <FormattedMessage id="footer.antifraud.path" />
                  </TextButton>
                </Link>
              </Box>
            </Box>
          </Box>
          <img
            className={classes.greenLogo}
            src={GreenLogo}
            alt="footer-logo-100+"
          />
          <Box className={classes.copyright}>
            <Typography
              fontSize="10px"
              color={theme.palette.default.contrastText}
            >
              {`APEX © 2022 ALL RIGHTS RESERVED ${version}`}
            </Typography>
          </Box>
          <Box className={classes.goTopAnchor}>
            <PaginationButton
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
              reverse={'-90'}
            />
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;