import { useEffect, } from 'react';
import {
  Box, Button,
} from '@mui/material';
import { useDispatch, } from 'react-redux';
import { Helmet, } from 'react-helmet';
import { useIntl, } from 'react-intl';
import Block1 from './component/Block1';
import Block2 from './component/Block2';
import Block3 from './component/Block3';
import Block4 from './component/Block4';
import Block5 from './component/Block5';
import Block6 from './component/Block6';
import Block7 from './component/Block7';
import Block8 from './component/Block8';
import ScrollHandler from '@/components/ScrollHandler';
import { globalActions, } from '@/reducers/global.slice';

const Service = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  // meta tag文案
  const pageTitle = intl.formatMessage({ id: 'service.page.title', });
  const ogTagTitle = intl.formatMessage({ id: 'service.ogtag.title', });
  const ogTagDescription = intl.formatMessage({ id: 'service.ogtag.description', });

  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={ogTagDescription}
        ></meta>
        <meta
          property="og:title"
          content={ogTagTitle}
        />
        <meta
          property="og:description"
          content={ogTagDescription}
        />
      </Helmet>
      <ScrollHandler />
      <Block1 />
      <Block2 id="type" />
      <Block3 id="production_line" />
      <Block4 id="application_industry" />
      <Block6 id="map" />
      <Block7 id="key_managers"/>
      <Block5 id="international_certification" />
      <Block8 id="technical_certification"/>
    </Box>
  );
};

export default Service;