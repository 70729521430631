import React, { useEffect, } from 'react';
import { Box, } from '@mui/material';
import {
  useNavigate, useParams,
} from 'react-router-dom';
import {
  useSelector, useDispatch,
} from 'react-redux';
import { newsActions, } from '@/reducers/news.slice';
import {
  getNews, getGlobal,
} from '@/reducers/states';
import ArticleDetailBlock1 from './component/ArticleDetailBlock1';
import ArticleDetailBlock2 from './component/ArticleDetailBlock2';
import StructuredData from './StructuredData';

const NewsDetail = () => {
  const dispatch = useDispatch();
  const { locale, } = useSelector(getGlobal);
  const param = useParams();
  const { id, } = param;

  useEffect(() => {
    if (id) {
      dispatch(newsActions.getNewsContentRequest({ id, }));
    }
  }, [id,]);

  return (
    <Box>
      <StructuredData />
      <ArticleDetailBlock1 />
      <ArticleDetailBlock2 />
    </Box>
  );
};

export default React.memo(NewsDetail);