import React, {
  useState, useMemo, useEffect, useRef,
} from 'react';
import {
  Box, Theme, Typography, Select, MenuItem, FormControl, Autocomplete, TextField,
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import { makeStyles, } from '@mui/styles';
import { FormattedMessage, } from 'react-intl';
import useMediaQuery from '@mui/material/useMediaQuery';
import ContentTable from './ContentTable';
import {
  useSelector, useDispatch,
} from 'react-redux';
import {
  getFinancial, getGlobal,
} from '@/reducers/states';
import { financialActions, } from '@/reducers/financial.slice';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '120px 120px 60px',
    [theme.breakpoints.down(800)]: { padding: '80px 24px 40px', },
  },
  topFilter: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '315px',
    background: 'linear-gradient(180deg, rgba(50, 115, 182, 0.1) 0%, rgba(133, 193, 31, 0) 53.12%)',
    zIndex: 1,
  },
  title: {
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  },
  contentContainer: {
    maxWidth: '940px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    gap: '40px',
    width: '100%',
  },
  description: {
    width: '100%',
    marginBottom: '48px',
  },
  subTitle: {
    width: '100%',
    textAlign: 'left',
    borderBottom: '1px solid rgba(209, 210, 212, 0.45)',
    marginBottom: '24px',
  },
  subTitleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    color: theme.palette.secondary.dark,
    opacity: 0.85,
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: '24px',
  },
  formControl: { minWidth: '150px', },
}));

// 每月營業額表格資料
const initRows1 = [
  [
    'investor.financial.blocktwo.data.1.table.row.1',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.2',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.3',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.4',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.5',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.6',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.7',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.8',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.9',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.10',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.11',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.12',
    '-',
    '-',
  ],
  [
    'investor.financial.blocktwo.data.1.table.row.13',
    '-',
    '-',
  ],
];

const headers1 = [
  'investor.financial.blocktwo.data.1.table.head.1',
  'investor.financial.blocktwo.data.1.table.head.2',
  'investor.financial.blocktwo.data.1.table.head.3',
];

const FinancialBlock2 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const [year, setYear,] = React.useState<any>(String(new Date().getFullYear()));
  const [yearList, setYearList,] = React.useState<string[]>([]);
  const [rows1, setRows1,] = React.useState<any[]>(initRows1);
  const { revenues, } = useSelector(getFinancial);
  const {
    data, loaded, loading,
  } = revenues;

  const handleGetRevenues = () => {
    dispatch(financialActions.getRevenuesRequest({ fields: '*.*', }));
  };

  const handleChangeYear = (year: string) => {
    const total = data.find((item) => (item.month === 0 && String(item.year) === year));
    const newRows = _.cloneDeep(initRows1).map((item, idx) => {
      if(idx === 12) {
        if(!!total){
          item[1] = total.amount.toLocaleString();
          item[2] = total.growth_rate.toLocaleString();
        }
        return item;
      }
      const monthData = data.find((item) => (item.month === idx+1 && String(item.year) === year));
      if(!monthData) return item;

      item[1] = monthData.amount.toLocaleString();
      item[2] = monthData.growth_rate.toLocaleString();
      return item;
    });
    setRows1(newRows);
  };

  const handleYearList = () => {
    const newYearList: string[] = [];
    data.forEach((item) => {
      if(newYearList.includes(String(item.year))) return;
      newYearList.push(String(item.year));
    });
    setYearList(newYearList);

    // 帶入最近年份
    if(newYearList.length > 0){
      setYear(newYearList[0]);
    }
  };

  useEffect(() => {
    handleGetRevenues();
  }, []);


  // 進入頁面、轉換年份時取得營業額資訊
  // 資料帶入
  useEffect(() => {
    if(loaded) {
      handleChangeYear(year);
    }
  }, [loaded, year,]);

  // 第一次帶入新年份
  useEffect(() => {
    if(loaded) {
      handleYearList();
    }
  }, [loaded,]);

  return (
    <Box id="revenues" className={classes.container}>
      <Box className={classes.title}>
        <Typography
          variant="h2"
          fontSize={isMobile ? '24px' : '40px'}
          fontWeight="700"
          color={theme.palette.secondary.dark}
          mb={3}
        >
          <FormattedMessage id="investor.financial.blocktwo.title" />
        </Typography>
      </Box>
      {/* 內容 */}
      <Box
        className={classes.contentContainer}
      >
        {/* 每月營業額 */}
        <Box className={classes.description}>
          <Box className={classes.subTitle}>
            <Box className={classes.subTitleContent}>
              <Typography
                fontSize={isMobile ? '16px' : '24px'}
                fontWeight="700"
                color={theme.palette.secondary.light}
              >
                <FormattedMessage id="investor.financial.blocktwo.data.1.title" />
              </Typography>

              <FormControl
                variant="outlined"
                margin="dense"
              >
                <Autocomplete
                  className={classes.formControl}
                  fullWidth
                  size="small"
                  value={year || String(new Date().getFullYear())}
                  options={yearList}
                  disableClearable
                  onChange={(event: any, newValue: string | null) => {setYear(newValue);}}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Box>
          <Box className={classes.subTitleContent}>
            <Typography
              fontSize={isMobile ? '16px' : '24px'}
              fontWeight="700"
              color={theme.palette.secondary.light}
              pb={2}
            >
              {year}
            </Typography>
            <Typography
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight="400"
              pb={2}
            >
              <FormattedMessage id="investor.financial.blocktwo.data.1.remark" />
            </Typography>
          </Box>
          <ContentTable
            headers={headers1}
            rows={rows1}
            focusItems={['investor.financial.blocktwo.data.1.table.row.13',]}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default React.memo(FinancialBlock2);