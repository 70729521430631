import { request, } from '@/utils';

export async function getNews(payload: any): Promise<any> {
  const {
    category, page, limit, fields, tags,
  } = payload;
  const LIMIT_ITEMS = 8;
  const filter: any = { status: { _eq: 'published', }, };
  if (category && category !== 'ALL') {
    filter['category'] = category;
  }
  if (tags) {
    filter['tags'] = { news_tags_id: { name: { _in: tags, }, }, };
  }
  const params = {
    meta: 'filter_count',
    limit: limit || LIMIT_ITEMS,
    page: page,
    sort: '-date_published',
    fields: fields ?? '*.*,tags.news_tags_id.*',
    filter: filter,
  };
  return request.get(`/items/news`, { params, });
}

export async function getNewsTags(payload: any): Promise<any> {
  return request.get(`/items/news_tags`);
}

export async function getFeatureNews(payload: any): Promise<any> {
  const params = { fields: '*.*.*.*.*', };
  return request.get(`/items/feature_news`, { params, });
}

export async function getNewsContent(payload: any): Promise<any> {
  const { id, } = payload;
  return request.get(`/items/news/${id}?fields=*.*.*.*.*`);
}