import { createSlice, } from '@reduxjs/toolkit';

export const CONTACT_FEATURE_KEY = 'contact';

/*
 * Update these interfaces according to your requirements.
 */

export interface ContactState {
  contact: {
    loading: boolean;
    loaded: boolean;
  };
}

export const initialContactState: ContactState = { contact: {
  loading: false,
  loaded: false,
}, };

export const contactSlice = createSlice({
  name: CONTACT_FEATURE_KEY,
  initialState: initialContactState,
  reducers: {
    postContactRequest(state, action) {
      state.contact = {
        ...state.contact,
        loading: true,
        loaded: false,
      };
    },
    postContactSuccess(state, action) {
      state.contact = {
        ...state.contact,
        loading: false,
        loaded: true,
      };
    },
    postContactFail(state, action) {
      state.contact = {
        ...state.contact,
        loading: false,
        loaded: false,
      };
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const contactReducer = contactSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const contactActions = contactSlice.actions;