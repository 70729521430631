import { createSlice, } from '@reduxjs/toolkit';

export const NEWS_FEATURE_KEY = 'news';

/*
 * Update these interfaces according to your requirements.
 */

export interface TagsItem {
  id: number;
  name: string;
}

export interface NewsState {
  news: {
    loading: boolean;
    loaded: boolean;
    filter_count: number;
    data: any[];
  };
  tags: {
    loading: boolean;
    loaded: boolean;
    data: TagsItem[];
  };
  featureNews: {
    loading: boolean;
    loaded: boolean;
    data: any[];
  };
  newsContent: {
    loading: boolean;
    loaded: boolean;
    data: any;
  };
  recentUpdateNews: {
    loading: boolean;
    loaded: boolean;
    data: any;
  };
}

export const initialNewsState: NewsState = {
  news: {
    loading: false,
    loaded: false,
    filter_count: 0,
    data: [],
  },
  tags: {
    loading: false,
    loaded: false,
    data: [],
  },
  featureNews: {
    loading: false,
    loaded: false,
    data: [],
  },
  newsContent: {
    loading: false,
    loaded: false,
    data: {},
  },
  recentUpdateNews: {
    loading: false,
    loaded: false,
    data: [],
  },
};

export const newsSlice = createSlice({
  name: NEWS_FEATURE_KEY,
  initialState: initialNewsState,
  reducers: {
    getNewsRequest(state, action) {
      state.news = {
        ...state.news,
        loading: true,
        loaded: false,
      };
    },
    getNewsSuccess(state, action) {
      state.news = {
        ...state.news,
        loading: false,
        loaded: true,
        data: [...action.payload.data,],
        ...action.payload.meta,
      };
    },
    getNewsFail(state, action) {
      state.news = {
        ...state.news,
        loading: false,
        loaded: false,
      };
    },
    getNewsTagsRequest(state, action) {
      state.tags = {
        ...state.tags,
        loading: true,
        loaded: false,
      };
    },
    getNewsTagsSuccess(state, action) {
      state.tags = {
        ...state.tags,
        loading: false,
        loaded: true,
        data: [...action.payload.data,],
        ...action.payload.meta,
      };
    },
    getNewsTagsFail(state, action) {
      state.tags = {
        ...state.tags,
        loading: false,
        loaded: false,
      };
    },
    getFeatureNewsRequest(state, action) {
      state.featureNews = {
        ...state.featureNews,
        loading: true,
        loaded: false,
      };
    },
    getFeatureNewsSuccess(state, action) {
      state.featureNews = {
        ...state.featureNews,
        loading: false,
        loaded: true,
        data: [...action.payload.data,],
      };
    },
    getFeatureNewsFail(state, action) {
      state.featureNews = {
        ...state.featureNews,
        loading: false,
        loaded: false,
      };
    },
    getNewsContentRequest(state, action) {
      state.newsContent = {
        ...state.newsContent,
        loading: true,
        loaded: false,
      };
    },
    getNewsContentSuccess(state, action) {
      state.newsContent = {
        ...state.newsContent,
        loading: false,
        loaded: true,
        data: action.payload.data,
      };
    },
    getNewsContentFail(state, action) {
      state.newsContent = {
        ...state.newsContent,
        loading: false,
        loaded: false,
      };
    },
    getRecentUpdateNewsRequest(state, action) {
      state.recentUpdateNews = {
        ...state.recentUpdateNews,
        loading: true,
        loaded: false,
      };
    },
    getRecentUpdateNewsSuccess(state, action) {
      state.recentUpdateNews = {
        ...state.recentUpdateNews,
        loading: false,
        loaded: true,
        data: [...action.payload.data,],
        ...action.payload.meta,
      };
    },
    getRecentUpdateNewsFail(state, action) {
      state.recentUpdateNews = {
        ...state.recentUpdateNews,
        loading: false,
        loaded: false,
      };
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const newsReducer = newsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const newsActions = newsSlice.actions;