import { Box, } from '@mui/material';
import { makeStyles, } from '@mui/styles';
import { Theme, } from '@mui/material';
import { styled, } from '@mui/system';
import EastIcon from '@mui/icons-material/East';

type Props = {
  children?: any;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  arrow?: boolean;
  color?: 'white' | 'black' | 'green';
  style?: any;
};

const Container = styled(Box)((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  whiteSpace: 'nowrap',
}));

const TextContainer = styled(Box)(({
  theme, color,
}) => {
  const fontColor = () => {
    switch (color) {
      case 'green':
        return theme.palette.primary.main;
      case 'white':
        return theme.palette.default.main;
      default:
        return theme.palette.secondary.dark;
    }
  };

  return {
    display: 'inline-block',
    position: 'relative',
    color: fontColor(),
    userSelect: 'none',
    cursor: 'pointer',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      transform: 'scaleX(0)',
      height: '2px',
      bottom: '-3px',
      left: '0',
      backgroundColor: theme.palette.primary.main,
      transformOrigin: 'bottom left',
      transition: 'transform 0.25s ease-out',
    },
    '&:hover': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      '&:after': {
        transformOrigin: 'bottom left',
        transform: 'scaleX(1)',
      },
    },
    '&:active': {
      color: theme.palette.primary.main,
      '&:after': {
        transformOrigin: 'bottom left',
        transform: 'scaleX(1)',
      },
    },
  };
});

const Arrow = styled(Box)((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const TextButton = ({
  children, onClick, arrow, color, style,
}: Props) => {
  return (
    <Container onClick={onClick}>
      <TextContainer
        color={color}
        style={style}
      >
        {children}
      </TextContainer>
      {arrow && (
        <Arrow>
          <EastIcon
            style={{
              color: 'white',
              fontSize: '20px',
              marginLeft: '8px',
            }}
          />
        </Arrow>
      )}
    </Container>
  );
};

export default TextButton;