import { Box, } from '@mui/material';
import { Helmet, } from 'react-helmet';
import { useIntl, } from 'react-intl';
import Block1 from './component/Block1';
import Block2 from './component/Block2';
import Block3 from './component/Block3';
import Block4 from './component/Block4';
import Block5 from './component/Block5';
import ScrollHandler from '@/components/ScrollHandler';
import { globalActions, } from '@/reducers/global.slice';

const ESG = () => {
  const intl = useIntl();

  // meta tag文案
  const pageTitle = intl.formatMessage({ id: 'esg.page.title', });
  const ogTagTitle = intl.formatMessage({ id: 'esg.ogtag.title', });
  const ogTagDescription = intl.formatMessage({ id: 'esg.ogtag.description', });

  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={ogTagDescription}
        ></meta>
        <meta
          property="og:title"
          content={ogTagTitle}
        />
        <meta
          property="og:description"
          content={ogTagDescription}
        />
      </Helmet>
      <ScrollHandler />
      <Block1 />
      <Block2 id="environmental" />
      <Block3 id="responsibility" />
      <Block4 id="governance" />
      <Block5 id="file" />
    </Box>
  );
};

export default ESG;