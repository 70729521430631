import {
  put, takeLatest, call, delay,
} from 'redux-saga/effects';
import { contactActions, } from '@/reducers/contact.slice';
import { globalActions, } from '@/reducers/global.slice';
import { postContacts, } from '@/serivces/contactApi';
import { FormattedMessage, } from 'react-intl';
import { AxiosResponse, } from 'axios';

export function* postContactsSaga({ payload, }: ReturnType<typeof contactActions.postContactRequest>) {
  try {
    const res: AxiosResponse = yield call(postContacts, payload);
    yield put(contactActions.postContactSuccess(res));
    // snackbar
    yield put(globalActions.snackbarRequest({
      visible: true,
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      content: 'contact.blocktwo.form.submit.success',
    }));
  } catch (error) {
    yield put(contactActions.postContactFail(error));
    // snackbar
    yield put(globalActions.snackbarRequest({
      visible: true,
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      content: 'contact.blocktwo.form.submit.error',
    }));
  }
}

const sagas = [takeLatest(contactActions.postContactRequest, postContactsSaga),];

export default sagas;