import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import {
  useSelector, useDispatch,
} from 'react-redux';
import {
  getNews, getGlobal,
} from '@/reducers/states';
import useEmblaCarousel, { EmblaOptionsType, } from 'embla-carousel-react';
import {
  Box, Theme, Typography, Button,
} from '@mui/material';
import { Link, } from 'react-router-dom';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTheme, } from '@mui/material/styles';
import moment from 'moment';
import Autoplay from '@/utils/embla-carousel-autoplay/autoPlay';
import { getLanguageNewsData, } from '@/utils/tool';
import PaginationButton from '@/components/PaginationButton';
import RectangleButton from '@/components/RectangleButton';
import BackgroudImage from '../assets/Block5/background.png';
import MobileBackgroudImage from '../assets/Block5/background-mobile.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '80px 0',
  },
  titleContainer: {
    width: '100%',
    height: '30%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  title: { color: theme.palette.secondary.main, },
  slideContainer: { overflow: 'hidden', },
  slides: {
    display: 'flex',
    paddingTop: '40px',
  },
  slide: {
    flex: '0 0 50%',
    display: 'flex',
    justifyContent: 'center',
    gap: '50px',
  },
  card: {
    width: '1000px',
    height: '563px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    transition: 'all 0.5s ease-out',
  },
  date: {
    width: 'fit-content',
    height: 'fit-content',
    padding: '5px 10px',
    backgroundColor: 'rgba(50, 115, 182, 0.65)',
    borderRadius: '5px',
    color: theme.palette.secondary.main,
    fontSize: '12px',
  },
  slideLogo: {
    width: '250px',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    boxShadow: '4px 4px 24px rgba(0, 0, 0, 0.2)',
    padding: '40px',
  },
  slideInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '32px',
  },
  slideTitle: {
    color: theme.palette.secondary.main,
    maxWidth: '620px',
    textAlign: 'center',
    display: ' -webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  },
  slideDescription: {
    color: theme.palette.secondary.main,
    maxWidth: '500px',
    textAlign: 'center',
    display: ' -webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
  },
  slideButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '50px',
    gap: '30px',
  },
  [theme.breakpoints.down(1100)]: {
    card: {
      width: '382px',
      height: '590px',
      padding: '16px',
    },
    slideTitle: {
      color: theme.palette.secondary.main,
      maxWidth: '620px',
      textAlign: 'center',
    },
    slideDescription: {
      color: theme.palette.secondary.main,
      maxWidth: '500px',
      textAlign: 'center',
    },
  },
  [theme.breakpoints.down(800)]: {
    container: { padding: '80px 24px', },
    slide: { flex: '0 0 100%', },
    card: { width: '100%', },
  },
}));

const SlideCard = ({
  title, description, link, date, style,
}: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1100));

  return (
    <Box
      className={classes.card}
      style={style}
    >
      <Box className={classes.date}>{moment(date).format('YYYY.MM.DD')}</Box>
      <Box className={classes.slideInfo}>
        <Typography
          className={classes.slideTitle}
          fontSize={isLittleDesktop ? '24px' : '40px'}
          marginBottom={'24px'}
        >
          {title}
        </Typography>
        <Typography
          className={classes.slideDescription}
          fontSize={'16px'}
          marginBottom={'24px'}
        >
          {description}
        </Typography>
      </Box>
      <Link to={`/${locale + link}`}>
        <RectangleButton color="white">
          <FormattedMessage id={'homepage.blockthree.button'} />
        </RectangleButton>
      </Link>
    </Box>
  );
};

const options = {
  delay: 5000,
  stopOnInteraction: false, // 拖動後是否停止自動輪播
}; // 自動輪播設定

const autoplay = Autoplay(options);

const Block5 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [emblaRef, emblaApi,] = useEmblaCarousel({ loop: true, }, [autoplay,]);
  const [currentSlideIndex, setCurrentSlideIndex,] = useState<any>(0);
  const { locale, } = useSelector(getGlobal);
  const { featureNews, } = useSelector(getNews);
  const {
    data, loaded, loading,
  } = featureNews;
  const basicUrl = window.Config.API_HOST;
  const theme = useTheme();
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1100));
  const isMobile = useMediaQuery(theme.breakpoints.down(400));

  const slideData = data.map((newsItem: any, index: any) => {
    // 找到當前網站語系的翻譯文案
    const translateData = getLanguageNewsData(newsItem.news_id.translations, locale);
    // 找到文案內容第一行文字作為預覽
    const translateContent = translateData.contents.find((item: any) => item.type === 'TEXT');
    return {
      title: translateData.title,
      description: translateContent.content,
      background: `${basicUrl}/assets/${newsItem.news_id.cover.id}`,
      linkPath: `/news/${newsItem.news_id.id}`,
      date: newsItem.news_id.date_published,
    };
  });

  const scrollPrev = () => {
    emblaApi && emblaApi.scrollPrev();
    autoplay.reset();
  };

  const scrollNext = () => {
    emblaApi && emblaApi.scrollNext();
    autoplay.reset();
  };

  useEffect(() => {
    const onSlideChange = () => {
      setCurrentSlideIndex(emblaApi?.selectedScrollSnap());
    };
    emblaApi?.on('select', onSlideChange);
    return () => {
      emblaApi?.off('select', onSlideChange);
    };
  }, [emblaApi,]);

  return (
    <Box
      className={classes.container}
      style={{ backgroundImage: `url(${isMobile ? MobileBackgroudImage : BackgroudImage})`, }}
    >
      <ScrollAnimation
        className={classes.titleContainer}
        animateIn="fadeInDown"
      >
        <Typography
          className={classes.title}
          fontSize={isLittleDesktop ? '24px' : '40px'}
          fontWeight={'bold'}
        >
          <FormattedMessage id={'homepage.blockfive.title'} />
        </Typography>
      </ScrollAnimation>
      {loaded && (
        <Box
          display="flex"
          maxWidth="100%"
          marginRight="auto"
          marginLeft="auto"
        >
          <Box
            className={classes.slideContainer}
            ref={emblaRef}
          >
            <Box className={classes.slides}>
              {slideData?.map((slideItem: any, index) => {
                return (
                  <div
                    key={index}
                    className={classes.slide}
                  >
                    <SlideCard
                      key={index}
                      title={slideItem.title}
                      description={slideItem.description}
                      link={slideItem.linkPath}
                      date={slideItem.date}
                      style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(16, 92, 171, 0) 0%, #105cab 100%),
                      url(${slideItem.background}?width=${isLittleDesktop ? '300' : '1000'}&quality=90)`,
                        transform: `scale(${index === currentSlideIndex ? 1 : 0.7})`,
                        opacity: index === currentSlideIndex ? '1' : '0.65',
                      }}
                    />
                  </div>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
      <Box className={classes.slideButton}>
        <PaginationButton
          onClick={scrollPrev}
          color="green"
          reverse="180"
        />
        <PaginationButton
          onClick={scrollNext}
          color="green"
        />
      </Box>
    </Box>
  );
};

export default Block5;