import React from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme, Typography,
} from '@mui/material';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import { useNavigate, } from 'react-router-dom';
import Tag from '@/components/Tag';
import { getImage, } from '@/utils/tool';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '47%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '60px',
    [theme.breakpoints.down('md')]: { width: '100%', },
  },
  textOverflowContainer: {
    display: ' -webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  },
  imageBox: {
    position: 'relative',
    width: '100%',
    height: '230px',
    cursor: 'pointer',
    borderRadius: '8px',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'all 0.5s ease',
    },
    '&:hover': { '& img': { transform: 'scale(1.2)', }, },
  },
  overlayer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(to bottom, rgba(9, 68, 129, 0) 0%, rgba(14, 35, 56, 0.75) 100%)',
    zIndex: '1',
  },
  description: {
    position: 'absolute',
    bottom: '24px',
    left: '24px',
    paddingRight: '24px',
    color: theme.palette.common.white,
    zIndex: '1',
  },
  dateBox: {
    width: '86px',
    height: '28px',
    backgroundColor: 'rgba(50, 115, 182, 0.65)',
    marginBottom: '8px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  publisherBox: {
    margin: '16px 0px 8px',
    color: theme.palette.primary.main,
  },
  tagsBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    marginTop: '16px',
  },
}));

interface ArticleCardProps {
  id: number;
  date?: string;
  title?: string;
  publisher?: string;
  content?: string;
  articleTag?: any[];
  image?: string | null;
  alt?: string;
}

const ArticleCard = ({
  id, date, title, publisher, content, articleTag, image, alt,
}: ArticleCardProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { locale, } = useSelector(getGlobal);

  // 如果沒有封面圖的話，使用這張
  const defaultImage = getImage('news/assets/defaultImage.png');
  return (
    <Box
      className={classes.container}
      onClick={() => navigate(`/${locale}/news/${id}`)}
    >
      {/* 上方圖片區域 */}
      <Box className={classes.imageBox}>
        <Box className={classes.overlayer} />
        <img
          src={image ? image : defaultImage}
          alt={alt}
        />
        <Box className={classes.description}>
          <Box className={classes.dateBox}>
            <Typography
              fontSize="12px"
              fontWeight="500"
            >
              {date}
            </Typography>
          </Box>
          <Box className={classes.textOverflowContainer}>
            <Typography
              fontSize="24px"
              fontWeight="700"
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* 下方內容區域 */}
      <Box className={classes.publisherBox}>
        <Typography
          fontSize="14px"
          fontWeight="500"
        >
          {publisher}
        </Typography>
      </Box>
      <Box className={classes.textOverflowContainer}>{content}</Box>
      <Box className={classes.tagsBox}>
        {!!articleTag?.length &&
          articleTag?.map((tag: any, index: any) => {
            return (
              <Tag
                key={index}
                color="green"
                variant="outlined"
              >
                {tag.news_tags_id.name}
              </Tag>
            );
          })}
      </Box>
    </Box>
  );
};

export default React.memo(ArticleCard);