import React from 'react';
import {
  Box, Button,
} from '@mui/material';
import {
  styled, type SxProps, type Theme,
} from '@mui/system';
import { ReactComponent as ButtonBorder, } from './assets/rectangle-button-border.svg';

type Props = {
  children?: any;
  color: 'green' | 'white';
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  sx?: SxProps<Theme>;
};

const Container = styled(Box)(({
  color, theme,
}) => ({
  width: 'fit-content',
  cursor: 'pointer',
  lineHeight: '1.2',
  userSelect: 'none',
  display: 'flex',
  position: 'relative',
  '&:hover': {
    backgroundColor: color === 'green' ? theme.palette.primary.main : theme.palette.default.main,
    borderRadius: '4px',
    '& ${Border}': { '& path': { fill: 'none', }, },
    '& div': { color: color === 'green' ? theme.palette.default.main : theme.palette.primary.main, },
  },
}));

const Border = styled(ButtonBorder)(({
  color, theme,
}) => ({ '& path': { fill: color === 'green' ? theme.palette.primary.main : theme.palette.default.main, }, }));

const Text = styled('div')(({
  color, theme,
}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: color === 'green' ? theme.palette.primary.main : theme.palette.default.main,
  fontSize: '16px',
  fontWeight: '500',
  position: 'absolute',
  width: '100%',
  height: '100%',
}));

const RectangleButton = ({
  children, disabled, onClick, color, sx,
}: Props) => {
  return (
    <Button style={{ padding: '0', }} sx={sx}>
      <Container
        color={color}
        onClick={onClick}
      >
        <Text color={color}>{children}</Text>
        <Border color={color}></Border>
      </Container>
    </Button>
  );
};

export default RectangleButton;