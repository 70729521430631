import React from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme, Typography, Divider,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({ container: {
  padding: '120px 120px 0 120px',
  marginBottom: '120px',
  [theme.breakpoints.down('md')]: { padding: '60px 24px 0px 24px', },
}, }));

const Content = [{
  title: 'harassment.page.content.title1',
  content: 'harassment.page.content.content1',
}, {
  title: 'harassment.page.content.title2',
  content: 'harassment.page.content.content2',
},];

const BlockTwo = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box className={classes.container}>
      <Typography
        fontSize={ isMobile ? '24px' : '40px'}
        fontWeight={700}
        color={theme.palette.secondary.dark}
        mb={2}
      >
        <FormattedMessage id="harassment.page.title" />
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={500}
        color={theme.palette.secondary.dark}
        sx={{ opacity: 0.45, }}
        mb={7}
      >
        <FormattedMessage id="harassment.page.date" />
      </Typography>
      <Typography
        fontSize={ isMobile ? '14px' : '16px'}
        fontWeight={400}
        color={theme.palette.secondary.dark}
        mb={4}
        sx={{ opacity: 0.85, }}
      >
        <FormattedMessage
          id="harassment.page.description"
          values={{ b:(...msg) => <b>{msg}</b>, }}
        />
      </Typography>
      {Content.map((item) => (
        <Box key={item.title}>
          <Typography
            fontSize={16}
            fontWeight={700}
            color={theme.palette.secondary.dark}
            mb={2}
          >
            <FormattedMessage id={item.title} />
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={400}
            color={theme.palette.secondary.dark}
            mb={4}
            sx={{ opacity: 0.85, }}
          >
            <FormattedMessage
              id={item.content}
              values={{
                p: (...chunks) => <p>{chunks}</p>,
                br: <br />,
                ol:(...msg) => <ol style={{
                  listStyleType: 'decimal', 'paddingInlineStart': '1rem', 'marginBlockStart': '0', 'marginBlockEnd': 0,
                }}>{msg}</ol>,
                li:(...msg) => <li>{msg}</li>,
                b:(...msg) => <b>{msg}</b>,
              }}
            />
          </Typography>
        </Box>
      ))}
      <Typography
        fontSize={16}
        fontWeight={400}
        color={theme.palette.secondary.dark}
        lineHeight={2}
        sx={{ opacity: 0.45, }}
      >
        <FormattedMessage
          id="harassment.page.footer.description"
          values={{
            br: <br />,
            a:(...msg) => <a style={{ 'color': theme.palette.secondary.dark, }} href="mailTo:nike@apexwp.com.tw">{msg}</a>,
          }}
        />
      </Typography>
    </Box>
  );
};

export default React.memo(BlockTwo);