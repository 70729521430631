import {
  type FC, type ComponentProps, useState, useEffect,
} from 'react';
import {
  Box,
  type Theme,
  Typography,
  styled,
  type SxProps,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { getImage, } from '@/utils/tool';
import { ReactComponent as Frame, } from '../assets/Block2/frame.svg';

const ImageWrapper = styled('div')(({ theme, }) => ({
  borderRadius: '12px',
  overflow: 'hidden',
  background:
    'linear-gradient(165.95deg, rgba(50, 115, 182, 0) 55.21%, rgba(50, 115, 182, 0.25) 92.91%)',
  aspectRatio: '1 / 1',
}));

const Image = styled('img')(({ theme, }) => ({
  width: '100%',
  objectFit: 'cover',
}));

const NameCard = styled('div')(({ theme, }) => ({
  background: '#E6E6E6',
  borderRadius: '24px',
  padding: '12px 16px',
}));

const Avatar: FC<{ src: string; alt: string; sx?: SxProps<Theme> }> = ({
  src,
  alt,
  sx,
}) => {
  return (
    <ImageWrapper sx={sx}>
      <Image
        src={src}
        alt={alt}
      />
    </ImageWrapper>
  );
};

const StyledFrame = styled(Frame)(({ theme, }) => ({}));

const Block2: FC<ComponentProps<typeof Box>> = ({ ...props }) => {
  return (
    <Box
      sx={[{
        padding: '60px 25px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '40px',
      }, (theme) => ({ [theme.breakpoints.up('md')]: {
        gap: '100px', position: 'relative', padding: '180px 25px',
      }, }),]}
      {...props}
    >
      <Box
        sx={[{
          margin: '0 auto',
          width: '100%',
          maxWidth: '1150px',
          display: 'grid',
          gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        }, (theme) => ({
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', paddingRight: '0',
          },[theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', paddingRight: '20px',
          },
        }),]}
      >
        <Box
          sx={[{
            gridColumn: 'span 1 / span 1',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
          }, (theme) => ({
            [theme.breakpoints.up('md')]: { justifyContent: 'center', },[theme.breakpoints.up('lg')]: {
              justifyContent: 'end', paddingRight: '80px',
            },
          }),]}
        >
          <Avatar
            sx={[{ width: '160px', }, (theme) => ({ [theme.breakpoints.up('md')]: { width: '295px', }, }),]}
            src={getImage('hiring/assets/Block2/ceo.png')}
            alt="ceo"
          />
        </Box>
        <Box
          sx={[{
            gridColumn: 'span 1 / span 1', marginBottom: '15px',
          }, (theme) => ({
            [theme.breakpoints.up('md')]: {
              gridColumn: 'span 2 / span 2', paddingLeft: '75px',
            }, [theme.breakpoints.up('lg')]: {
              gridColumn: 'span 2 / span 2', paddingLeft: '0',
            },
          }),]}
        >
          <Typography
            sx={[{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '33px',
              marginBottom: '16px',
              color: '#0E2338',
              marginTop: '25px',
            }, (theme) => ({ [theme.breakpoints.up('md')]: {
              fontSize: '40px',
              lineHeight: '54px',
              marginTop: '0',
              marginBottom: '24px',
            }, }),]}
          >
            <FormattedMessage id={'hiring.blocktwo.ceo.title'} />
          </Typography>
          <Typography
            sx={[{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '19px',
              marginBottom: '24px',
              color: '#515151',
            }, (theme) => ({ [theme.breakpoints.up('md')]: {
              fontSize: '16px',
              lineHeight: '24px',
            }, }),]}
          >
            <FormattedMessage id={'hiring.blocktwo.ceo.message.session1'} />
          </Typography>
          <Typography
            sx={[{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '19px',
              color: '#515151',
            }, (theme) => ({ [theme.breakpoints.up('md')]: {
              fontSize: '16px',
              lineHeight: '24px',
            }, }),]}
          >
            <FormattedMessage id={'hiring.blocktwo.ceo.message.session2'} />
          </Typography>
        </Box>
        <Box
          sx={[{
            gridColumn: 'span 1 / span 1',
            display: 'flex',
            justifyContent: 'end',
          }, (theme) => ({ [theme.breakpoints.up('md')]: { gridColumn: 'span 3 / span 3', }, }),]}
        >
          <NameCard>
            <Typography
              sx={[{
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',
                color: '#515151',
              },]}
            >
              <FormattedMessage id={'hiring.blocktwo.ceo.name'} />
            </Typography>
          </NameCard>
        </Box>
      </Box>
      <Box
        sx={[{
          margin: '0 auto',
          width: '100%',
          maxWidth: '920px',
          display: 'flex',
          flexDirection: 'column-reverse',
        }, (theme) => ({ [theme.breakpoints.up('md')]: {
          display: 'grid',
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          gridAutoFlow: 'row',
        }, }),]}
      >
        <Box
          sx={[{
            width: '100%', marginBottom: '15px',
          }, (theme) => ({ [theme.breakpoints.up('md')]: {
            gridColumn: 'span 2 / span 2',
            paddingRight: '80px',
          }, }),]}
        >
          <Typography
            sx={[{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '33px',
              marginBottom: '16px',
              color: '#0E2338',
              marginTop: '25px',
            }, (theme) => ({ [theme.breakpoints.up('md')]: {
              fontSize: '40px',
              lineHeight: '54px',
              marginTop: '0',
              marginBottom: '24px',
            }, }),]}
          >
            <FormattedMessage id={'hiring.blocktwo.factory-director.title'} />
          </Typography>
          <Typography
            sx={[{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '19px',
              color: '#515151',
            }, (theme) => ({ [theme.breakpoints.up('md')]: {
              fontSize: '16px',
              lineHeight: '24px',
            }, }),]}
          >
            <FormattedMessage
              id={'hiring.blocktwo.factory-director.message.session1'}
            />
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '16px',
            }}
          >
            <NameCard>
              <Typography
                sx={[{
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: '#515151',
                },]}
              >
                <FormattedMessage id={'hiring.blocktwo.factory-director.name'} />
              </Typography>
            </NameCard>
          </Box>
        </Box>
        <Box
          sx={[{
            width: '100%',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }, (theme) => ({ [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            gridColumn: 'span 1 / span 1',
          }, }),]}
        >
          <Avatar
            sx={[{ width: '160px', }, (theme) => ({ [theme.breakpoints.up('md')]: { width: '295px', }, }),]}
            src={getImage('hiring/assets/Block2/factory-director.png')}
            alt="factory-director"
          />
        </Box>
      </Box>
      <StyledFrame sx={[ { display: 'none', }, (theme) => ({ [theme.breakpoints.up('md')]: {
        position: 'absolute', left: 0, bottom: 65, zIndex: -1, display: 'block',
      }, }),]} />
    </Box>
  );
};

export default Block2;