import React from 'react';
import {
  Box, Theme, Typography,
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import { makeStyles, } from '@mui/styles';
import { FormattedMessage, } from 'react-intl';
import { ReactComponent as DownloadIcon, } from '../assets/block5/icon-download.svg';
import DownloadData from '../downData.json';
import { getImage, } from '@/utils/tool';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '120px',
    [theme.breakpoints.down(800)]: { padding: '80px 24px', },
  },
  topFilter: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '280px',
    background: 'linear-gradient(180deg, rgba(50, 115, 182, 0.1) 0%, rgba(133, 193, 31, 0) 53.12%)',
    zIndex: 1,
    [theme.breakpoints.down(800)]: { height: '150px', },
  },
  title: {
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto 24px',
  },
  contentContainer: {
    width: '100%',
    maxWidth: '940px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
  },
  subTitle: {
    width: '100%',
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: '16px',
  },
  listContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  },
  list: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #D1D2D473',
    padding: '16px 0',
  },
  download: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '24px',
      height: '24px',
      marginLeft: '8px',
    },
    '&:hover': {
      '& svg path': { stroke: `${theme.palette.primary.main}`, },
      '& p': { color: theme.palette.primary.main, },
    },
  },
}));

const BlockFive = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  const handleClickDownload = (fileName: string, path: string) => {
    const link = document.createElement('a');
    link.href = getImage(path);
    link.download = `${fileName}.pdf`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      id="relevant"
      className={classes.container}
    >
      <Box className={classes.topFilter} />
      <Box className={classes.title}>
        <Typography
          variant="h2"
          fontSize={isMobile ? '24px' : '40px'}
          fontWeight="700"
          color={theme.palette.secondary.dark}
          mb={3}
        >
          <FormattedMessage id="investor.blockfive.title" />
        </Typography>
      </Box>
      {/* 相關章程 */}
      <Box className={classes.contentContainer}>
        <Box className={classes.subTitle}>
          <Typography
            fontSize={isMobile ? '16px' : '24px'}
            fontWeight="700"
            color={theme.palette.primary.main}
          >
            <FormattedMessage id="investor.blockfive.data.1.title" />
          </Typography>
        </Box>
        <Box className={classes.listContainer}>
          {DownloadData.map((item: any) => (
            <Box
              key={item.title}
              className={classes.list}
            >
              <Typography
                fontSize={isMobile ? '14px' : '16px'}
                fontWeight="700"
                color={theme.palette.secondary.dark}
              >
                <FormattedMessage id={item.title} />
              </Typography>
              <Box
                className={classes.download}
                // TODO:待後續提供檔案下載
                // onClick={() => handleClickDownload(item.fileName, item.path)}
                onClick={() => {}}
              >
                <Typography
                  fontSize={isMobile ? '14px' : '16px'}
                  fontWeight="700"
                >
                  <FormattedMessage id="investor.blockfive.data.download" />
                </Typography>
                <DownloadIcon />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(BlockFive);