import {
  useState, useEffect,
} from 'react';
import {
  Box, type SxProps,
} from '@mui/material';
import { makeStyles, } from '@mui/styles';
import { Theme, } from '@mui/material';
import { ReactComponent as ButtonCircle, } from './assets/pagination-button-circle.svg';
import { ReactComponent as ButtonArrow, } from './assets/pagination-button-arrow.svg';

type Props = {
  children?: any;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  reverse?: string;
  color?: 'green' | 'white';
  textPosition?: 'bottom' | 'top' | 'left' | 'right';
  sx?: SxProps<Theme>;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: 'fit-content',
    height: 'auto',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    gap: '16px',
    '&:hover': {
      '& $whiteArrow': { '& path': {
        animation: '$opacity .8s ease-in',
        stroke: theme.palette.primary.main,
      }, },
      '& $greenArrow': { '& path': {
        animation: '$opacity .8s ease-in',
        stroke: theme.palette.primary.dark,
      }, },
      '& $whiteText': { color: theme.palette.primary.main, },
      '& $greenText': { color: theme.palette.primary.dark, },
      '& $rotateWhiteCircle': {
        transform: 'rotate(180deg)',
        opacity: '1',
      },
      '& $rotateGreenCircle': {
        transform: 'rotate(180deg)',
        opacity: '1',
      },
    },
  },
  containerDisabled: {
    width: 'fit-content',
    userSelect: 'none',
    display: 'flex',
    opacity: '0.5',
    gap: '16px',
  },
  svgContainer: {
    position: 'relative',
    height: '58px',
    width: '58px',
    [theme.breakpoints.down('md')]: {
      height: '40px',
      width: '40px',
    },
  },
  whiteCircle: {
    position: 'absolute',
    '& path': { fill: theme.palette.default.main, },
    [theme.breakpoints.down('md')]: {
      height: '40px',
      width: '40px',
    },
  },
  greenCircle: {
    position: 'absolute',
    '& path': { fill: theme.palette.primary.main, },
    [theme.breakpoints.down('md')]: {
      height: '40px',
      width: '40px',
    },
  },
  rotateWhiteCircle: {
    position: 'absolute',
    opacity: '0',
    top: '0',
    left: '0',
    transform: 'rotate(0deg)',
    transition: 'all .5s ease-in',
    '& path': { fill: theme.palette.primary.main, },
    [theme.breakpoints.down('md')]: {
      height: '40px',
      width: '40px',
    },
  },
  rotateGreenCircle: {
    position: 'absolute',
    opacity: '0',
    top: '0',
    left: '0',
    transform: 'rotate(0deg)',
    transition: 'all .5s ease-in',
    '& path': { fill: theme.palette.primary.dark, },
    [theme.breakpoints.down('md')]: {
      height: '40px',
      width: '40px',
    },
  },
  whiteArrow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& path': { stroke: theme.palette.default.main, },
  },
  greenArrow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& path': { stroke: theme.palette.primary.main, },
  },
  whiteText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.default.main,
    fontSize: '16px',
    fontWeight: '500',
  },
  greenText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: '500',
  },
  '@keyframes opacity': {
    '0%': { opacity: '0', },
    '100%': { opacity: '1', },
  },
}));

const PaginationButton = ({
  children, disabled, onClick, reverse, color, textPosition, sx,
}: Props) => {
  const classes = useStyles();
  const [flexDirection, setFlexDirection,] = useState<any>('row');

  useEffect(() => {
    if (textPosition === 'right') {
      setFlexDirection('row-reverse');
    } else if (textPosition === 'top') {
      setFlexDirection('column');
    } else if (textPosition === 'bottom') {
      setFlexDirection('column-reverse');
    } else {
      setFlexDirection('row');
    }
  }, [textPosition,]);

  return (
    <Box
      className={disabled ? classes.containerDisabled : classes.container}
      onClick={!disabled ? onClick : () => {}}
      style={{
        flexDirection: flexDirection,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      sx={sx}
    >
      {children && <Box className={color === 'green' ? classes.greenText : classes.whiteText}>{children}</Box>}
      <Box
        className={classes.svgContainer}
        style={{ transform: reverse ? `rotate(${reverse}deg)` : `rotate(${reverse}deg)`, }}
      >
        <ButtonCircle className={color === 'green' ? classes.greenCircle : classes.whiteCircle} />
        <ButtonCircle className={color === 'green' ? classes.rotateGreenCircle : classes.rotateWhiteCircle} />
        <ButtonArrow className={color === 'green' ? classes.greenArrow : classes.whiteArrow} />
      </Box>
    </Box>
  );
};

export default PaginationButton;