import React, {
  useState, useEffect, useRef,
} from 'react';
import {
  useNavigate, Link,
} from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  Box, Theme, Typography, Button, Divider,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PaginationButton from '@/components/PaginationButton';
import BackgroudImage from '../assets/Block4/background.png';
import Section1Image from '../assets/Block4/section1.png';
import Section2Image from '../assets/Block4/section2.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: 'center',
    flexDirection: 'column',
    gap: '40px',
    backgroundSize: 'cover',
    padding: '120px',
    [theme.breakpoints.down(800)]: { padding: '60px 24px', },
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '40px',
    [theme.breakpoints.down(1200)]: {
      flexDirection: 'column',
      gap: '16px',
    },
    '& img': {
      width: '100%',
      maxWidth: '600px',
    },
  },
  sectionInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
    maxWidth: '560px',
  },
}));

const BlockFour = ({ id, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <div
      id={id}
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroudImage})`, }}
    >
      <Box className={classes.section}>
        <Box className={classes.sectionInfo}>
          <Typography
            fontSize={isMobile ? '24px' : '40px'}
            fontWeight={'bold'}
            color={theme.palette.primary.main}
          >
            <FormattedMessage id={'about.blockfour.section1.title'} />
          </Typography>
          <Typography
            fontSize={isMobile ? '14px' : '16px'}
            color={theme.palette.secondary.dark}
          >
            <FormattedMessage id={'about.blockfour.section1.description1'} />
          </Typography>
          <Typography
            fontSize={isMobile ? '14px' : '16px'}
            color={theme.palette.secondary.dark}
          >
            <FormattedMessage id={'about.blockfour.section1.description2'} />
          </Typography>
          <Link
            to={`/${locale}/esg`}
            style={{ textDecoration: 'none', }}
          >
            <PaginationButton color="green">
              <FormattedMessage id={'about.blockfour.section1.button'} />
            </PaginationButton>
          </Link>
        </Box>
        <img src={Section1Image} />
      </Box>
      <Box className={classes.section}>
        <Box className={classes.sectionInfo}>
          <Typography
            fontSize={isMobile ? '24px' : '40px'}
            fontWeight={'bold'}
            color={theme.palette.secondary.light}
          >
            <FormattedMessage id={'about.blockfour.section2.title'} />
          </Typography>
          <Typography
            fontSize={isMobile ? '14px' : '16px'}
            color={theme.palette.secondary.dark}
          >
            <FormattedMessage id={'about.blockfour.section2.description1'} />
          </Typography>
          <Typography
            fontSize={isMobile ? '14px' : '16px'}
            color={theme.palette.secondary.dark}
          >
            <FormattedMessage id={'about.blockfour.section2.description2'} />
          </Typography>
          <Link
            to={`/${locale}/service`}
            style={{ textDecoration: 'none', }}
          >
            <PaginationButton color="green">
              <FormattedMessage id={'about.blockfour.section2.button'} />
            </PaginationButton>
          </Link>
        </Box>
        <img src={Section2Image} />
      </Box>
    </div>
  );
};

export default BlockFour;