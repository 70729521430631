import React, {
  useState, useEffect,
} from 'react';
import { useNavigate, } from 'react-router-dom';
import {
  Box, Theme, Typography,
} from '@mui/material';
import {
  FormattedMessage, useIntl,
} from 'react-intl';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useEmblaCarousel from 'embla-carousel-react';
import ScrollAnimation from 'react-animate-on-scroll';
import EsgImage1 from '../assets/Block3/esg-pic-1.png';
import BackgroudImage from '../assets/Block3/background.png';
import OrangeLine from '../assets/Block3/orange-line.png';
import SignatureImage from '../assets/Block3/signature.png';
import CardData from '../Block3CardData.json';
import VideoData from '../Block3VideoData.json';
import ImageData from '../Block3ImageData.json';
import PeopleRightsDatas from '../Block3PeopleRightData.json';
import PaginationButton from '@/components/PaginationButton';
import { getImage, } from '@/utils/tool';
import Autoplay from '@/utils/embla-carousel-autoplay/autoPlay';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '120px 100px',
    [theme.breakpoints.down(800)]: { padding: '0px 24px 60px', },
  },
  info1Container: {
    width: '750px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '80px',
    [theme.breakpoints.down(800)]: { width: '100%', },
  },
  info2Container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardsContainer: {
    height: '300px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    [theme.breakpoints.down(1400)]: {
      height: '100%',
      flexWrap: 'noWrap',
    },
  },
  cardCircle: {
    maxHeight: '24px',
    minWidth: '24px',
    borderRadius: '50%',
    backgroundColor: '#ff6b18',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontWeight: 'bold',
    marginTop: '6px',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '260%',
      height: '1px',
      bottom: '-120%',
      backgroundColor: 'rgba(255, 107, 24, 0.15)',
      transform: 'rotate(90deg)',
    },
  },
  videoCarousel: { overflow: 'hidden', },
  videoCarouselContainer: { display: 'flex', },
  videoSlide: {
    flex: '0 0 10%',
    [theme.breakpoints.down(800)]: { flex: '0 0 100%', },
  },
  videogreenCircleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    margin: '25px 0px',
    [theme.breakpoints.down(800)]: { margin: '25px 0px', },
  },
  videogreenCircle: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  info3Container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '100px',
    [theme.breakpoints.down(1100)]: {
      flexDirection: 'column',
      gap: '24px',
    },
  },
  info1Description: {
    width: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.down(1400)]: { width: '100%', },
  },
  info4Container: {
    width: '99vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '100px',
    marginTop: '120px',
    [theme.breakpoints.down(1100)]: {
      width: '100%',
      flexDirection: 'column',
      gap: '24px',
      marginTop: '24px',
    },
  },
  info2Description: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingLeft: '120px',
    [theme.breakpoints.down(1100)]: {
      width: '100%',
      paddingLeft: '0px',
    },
  },
  imageCarousel: { overflow: 'hidden', },
  imageCarouselContainer: { display: 'flex', },
  imageSlide: {
    flex: '0 0 20%',
    [theme.breakpoints.down(800)]: { flex: '0 0 100%', },
  },
  peopleRights: {
    width: '936px',
    height: '600px',
    borderRadius: '20px',
    backgroundImage: 'linear-gradient(180deg, rgba(239, 235, 233, 0.85) 0%, rgba(239, 235, 233, 0) 100%)',
    padding: '50px 60px',
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      height: '100%',
      padding: '24px',
    },
  },
  peopleRightsTitle: {
    display: 'flex',
    gap: '24px',
  },
  peopleRightsTitle1: {
    position: 'relative',
    cursor: 'pointer',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '20px',
      height: '1px',
      bottom: '17px',
      backgroundColor: theme.palette.primary.main,
      transform: 'rotate(90deg)',
    },
    [theme.breakpoints.down(1000)]: { '&:after': { bottom: '10px', }, },
  },
  peopleRightsTitle2: { cursor: 'pointer', },
  peopleRightsContant: {
    width: '100%',
    height: '336px',
    overflowY: 'auto',
    marginTop: '32px',
    [theme.breakpoints.down(1000)]: { height: '354px', },
  },
  peopleRightsSignature: {
    width: '100%',
    height: 'fit-contact',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '42px',
    gap: '16px',
  },
  peopleRightsSignatureTitle: {
    borderRadius: '20px',
    backgroundColor: '#e6e6e6',
    padding: '12px 16px',
  },
}));

const options = {
  delay: 5000,
  stopOnInteraction: false, // 拖動後是否停止自動輪播
}; // 自動輪播設定

const autoplay = Autoplay(options);

const ImageCarousel = () => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const [currentSlideIndex, setCurrentSlideIndex,] = useState<any>(0);
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const { locale, } = useSelector(getGlobal);
  const [emblaRef, emblaApi,] = useEmblaCarousel({
    loop: true,
    draggable: true,
    align: 'start',
  }, [autoplay,]);

  const scrollTo = (index: any) => {
    emblaApi && emblaApi.scrollTo(index);
  };

  useEffect(() => {
    const onSlideChange = () => {
      setCurrentSlideIndex(emblaApi?.selectedScrollSnap());
    };
    emblaApi?.on('select', onSlideChange);
    return () => {
      emblaApi?.off('select', onSlideChange);
    };
  }, [emblaApi,]);

  useEffect(() => {
    scrollTo(currentSlideIndex);
  }, [currentSlideIndex,]);

  return (
    <Box>
      <Box
        className={classes.imageCarousel}
        ref={emblaRef}
      >
        <Box className={classes.imageCarouselContainer}>
          {ImageData.map((data, index) => {
            return (
              <Box
                key={index}
                className={classes.imageSlide}
              >
                <Box
                  width={isMobile ? '100%' : '500px'}
                  margin={isMobile ? '0px' : '24px'}
                >
                  <img
                    width="100%"
                    src={getImage(data.imgPath)}
                    alt={intl.formatMessage({ id: 'esg.blockthree.title.4', })}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      {/* 圖片輪播的綠色圓點 */}
      <Box className={classes.videogreenCircleContainer}>
        {ImageData.map((data, index) => {
          const isCurrentVideo = currentSlideIndex === index;
          return (
            <Box
              key={index}
              className={classes.videogreenCircle}
              style={{ backgroundColor: isCurrentVideo ? theme.palette.primary.main : 'rgba(133, 193, 31, 0.25)', }}
              onClick={() => {
                setCurrentSlideIndex(index);
              }}
            ></Box>
          );
        })}
      </Box>
    </Box>
  );
};

const BlockThree = ({ id, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const intl = useIntl();
  const { locale, } = useSelector(getGlobal);
  const [emblaRef, emblaApi,] = useEmblaCarousel({
    loop: true,
    draggable: true,
  });
  const [currentSlideIndex, setCurrentSlideIndex,] = useState<any>(0);
  const [currentPeopleRightIndex, setCurrentPeopleRightIndex,] = useState<any>(0);
  const isLittleDestop = useMediaQuery(theme.breakpoints.down(1400));
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const isEqualHeaderMobile = useMediaQuery(theme.breakpoints.down(1100));

  const scrollTo = (index: any) => {
    emblaApi && emblaApi.scrollTo(index);
  };

  useEffect(() => {
    const onSlideChange = () => {
      setCurrentSlideIndex(emblaApi?.selectedScrollSnap());
    };
    emblaApi?.on('select', onSlideChange);
    return () => {
      emblaApi?.off('select', onSlideChange);
    };
  }, [emblaApi,]);

  useEffect(() => {
    scrollTo(currentSlideIndex);
  }, [currentSlideIndex,]);

  // workaround for scrolling to employee benefits
  // 員工福利的錨點位置會有非預期的位置產生，所以要依據裝置大小來做調整
  useEffect(() => {
    const element = document.getElementById(location.hash.replace('#', ''));

    const topPosition = () => {
      if (isMobile) {
        return 1000;
      } else if (isLittleDestop) {
        return 100;
      } else {
        return 500;
      }
    };

    if(element?.id && element?.id === 'employee_benefit') {
      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop + topPosition() : 1000,
      });
    }
  }, [location,]);

  return (
    <div
      id={id}
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroudImage})`, }}
    >
      {/* 上方紅色線條圖片 */}
      <img
        width="100%"
        src={OrangeLine}
        alt={intl.formatMessage({ id: 'esg.blockthree.title.1', })}
      />

      {/* 社會責任 */}
      <Box className={classes.info1Container}>
        <Typography
          color={'#ff6b18'}
          fontSize={'24px'}
          fontWeight={'bold'}
          marginTop={'16px'}
          marginBottom={'24px'}
        >
          <FormattedMessage id={'esg.blockthree.title.1'} />
        </Typography>

        <Typography
          color={theme.palette.secondary.dark}
          fontSize={isMobile ? '16px' : '14px'}
          textAlign="center"
        >
          <FormattedMessage id={'esg.blockthree.description.1'} />
        </Typography>
      </Box>

      {/* 人權政策 */}
      <Box mb="70px" className={classes.peopleRights}>
        <Box className={classes.peopleRightsTitle}>
          {/* 標題 */}
          {PeopleRightsDatas.map((data, index) => {
            const activeTitle = currentPeopleRightIndex === index;
            return (
              <Typography
                key={index}
                className={index === 0 ? classes.peopleRightsTitle1 : classes.peopleRightsTitle2}
                fontSize={isMobile ? (locale === 'en_us' ? '12px' : '16px') : '24px'}
                fontWeight={'bold'}
                color={activeTitle ? theme.palette.primary.main : theme.palette.secondary.dark}
                borderBottom={activeTitle ? `1px solid ${theme.palette.primary.main}` : ''}
                onClick={() => {
                  setCurrentPeopleRightIndex(index);
                }}
              >
                <FormattedMessage id={data.title} />
              </Typography>
            );
          })}
        </Box>
        {/* 內文 */}
        <Box className={classes.peopleRightsContant}>
          {PeopleRightsDatas[currentPeopleRightIndex].contacts.map((data, index) => {
            return (
              <Typography
                key={index}
                fontSize={isMobile ? '12px' : '16px'}
                fontWeight={data.subTitle ? 'bold' : 'regular'}
                marginBottom={'16px'}
                whiteSpace="pre-line"
              >
                <FormattedMessage id={data.subTitle ? data.subTitle : data.contact} />
              </Typography>
            );
          })}
        </Box>
        {/* 簽名 */}
        <Box className={classes.peopleRightsSignature}>
          <Box className={classes.peopleRightsSignatureTitle}>
            <Typography fontSize={isMobile ? '10px' : '12px'}>
              <FormattedMessage id={'esg.blockthree.signature.title'} />
            </Typography>
          </Box>
          <img
            width="92px"
            src={SignatureImage}
            alt={intl.formatMessage({ id: 'esg.blockthree.signature.title', })}
          />
        </Box>
      </Box>

      {/* 公益捐助，回饋地方 */}
      <Box className={classes.info3Container}>
        <img
          width={isMobile ? '100%' : '484px'}
          src={EsgImage1}
        />
        <Box className={classes.info1Description}>
          <ScrollAnimation animateIn="fadeInRight">
            <Typography
              fontSize={isMobile ? '24px' : '40px'}
              fontWeight={'bold'}
              marginTop={'16px'}
              marginBottom={'24px'}
            >
              <FormattedMessage id={'esg.blockthree.title.3'} />
            </Typography>
            <Typography
              fontSize={isMobile ? '14px' : '16px'}
              marginBottom={'16px'}
            >
              <FormattedMessage id={'esg.blockthree.description.3'} />
            </Typography>
          </ScrollAnimation>
          <PaginationButton
            onClick={() => {
              const newsId = 'apex-news-2022-11-22-apex-donate-fire-alarms-to-the-changhua-xixiang-fire-brigade';
              navigate(`/${locale}/news/apex-news-2022-11-22-apex-donate-fire-alarms-to-the-changhua-xixiang-fire-brigade`);
            }}
            color="green"
          >
            <FormattedMessage id={'esg.blocktwo.button'} />
          </PaginationButton>
        </Box>
      </Box>

      {/* 工作環境安全 */}
      <Box className={classes.info4Container}>
        <ScrollAnimation
          className={classes.info2Description}
          animateIn="fadeInLeft"
        >
          <Typography
            fontSize={isMobile ? '24px' : '40px'}
            fontWeight={'bold'}
            marginTop={'16px'}
            marginBottom={'24px'}
          >
            <FormattedMessage id={'esg.blockthree.title.4'} />
          </Typography>
          <Typography
            fontSize={isMobile ? '14px' : '16px'}
            marginBottom={'16px'}
          >
            <FormattedMessage id={'esg.blockthree.description.4-1'} />
          </Typography>
          <Typography
            fontSize={isMobile ? '14px' : '16px'}
            marginBottom={'16px'}
          >
            <FormattedMessage id={'esg.blockthree.description.4-2'} />
          </Typography>
        </ScrollAnimation>

        {/* 圖片輪播 */}
        <Box
          width={isEqualHeaderMobile ? '100%' : '50%'}
          ml={'auto'}
        >
          <ImageCarousel />
        </Box>
      </Box>

      {/* 影片輪播 */}
      <Box
        width={isMobile ? '100%' : '99vw'}
        margin={isMobile ? '50px 0px' : '100px 0px'}
      >
        <Box
          className={classes.videoCarousel}
          ref={emblaRef}
        >
          <Box className={classes.videoCarouselContainer}>
            {VideoData.map((data, index) => {
              return (
                <Box
                  key={index}
                  className={classes.videoSlide}
                >
                  <Box
                    width={isMobile ? '100%' : '600px'}
                    height={isMobile ? '280px' : '340px'}
                    borderRadius="10px"
                    overflow="hidden"
                    margin={isMobile ? '0px' : '0px 16px'}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      src={`https://www.youtube.com/embed/${data.url.split('be/')[1]}`}
                    ></iframe>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>

        {/* 綠色圓點 */}
        <Box className={classes.videogreenCircleContainer}>
          {VideoData.map((data, index) => {
            const isCurrentVideo = currentSlideIndex === index;
            return (
              <Box
                key={index}
                className={classes.videogreenCircle}
                style={{ backgroundColor: isCurrentVideo ? theme.palette.primary.main : 'rgba(133, 193, 31, 0.25)', }}
                onClick={() => {
                  setCurrentSlideIndex(index);
                }}
              ></Box>
            );
          })}
        </Box>
      </Box>

      {/* 員工福利標題 */}
      <ScrollAnimation
        className={classes.info2Container}
        animateIn="fadeInDown"
      >
        <Typography
          color={theme.palette.secondary.dark}
          fontSize={isMobile ? '24px' : '40px'}
          fontWeight={'bold'}
          marginTop={'16px'}
          marginBottom={'24px'}
        >
          <FormattedMessage id={'esg.blockthree.title.2'} />
        </Typography>

        <Typography
          color={theme.palette.secondary.dark}
          fontSize={isMobile ? '14px' : '16px'}
          marginBottom={'24px'}
          textAlign="center"
          maxWidth={'700px'}
        >
          <FormattedMessage id={'esg.blockthree.description.2'} />
        </Typography>
      </ScrollAnimation>

      {/* 員工福利項目 */}
      <Box className={classes.cardsContainer}>
        {CardData.map((data, index) => {
          const ITEM_PER_ROW = isLittleDestop ? 9 : 3;
          return (
            <Box
              key={index}
              display="flex"
              gap="16px"
              width="360px"
              height="60px"
            >
              <Box
                className={classes.cardCircle}
                // 3的倍數的項目隱藏偽元素
                style={{ overflow: (index + 1) % ITEM_PER_ROW === 0 ? 'hidden' : 'unset', }}
              >
                {index + 1}
              </Box>
              <Box>
                <Typography
                  color={theme.palette.secondary.dark}
                  fontSize={isMobile ? '16px' : '24px'}
                  fontWeight={'bold'}
                >
                  <FormattedMessage id={data.title} />
                </Typography>
                <Typography
                  color={theme.palette.secondary.dark}
                  fontSize={isMobile ? '12px' : '14px'}
                >
                  <FormattedMessage id={data.description} />
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      {/* 從人才招募專區，導頁過來的員工福利錨點 */}
      <div id="employee_benefit"/>
    </div>
  );
};

export default BlockThree;