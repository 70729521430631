import React, {
  useState, useEffect, useCallback,
} from 'react';
import useEmblaCarousel, { EmblaOptionsType, } from 'embla-carousel-react';
import {
  Box, Theme, Typography, Button,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { Link, } from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  getNews, getGlobal,
} from '@/reducers/states';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import Autoplay from '@/utils/embla-carousel-autoplay/autoPlay';
import { getImage, } from '@/utils/tool';
import { useTheme, } from '@mui/material/styles';
import PaginationButton from '@/components/PaginationButton';
import RectangleButton from '@/components/RectangleButton';
import mockData from '../Block4SliderData.json';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(to bottom, rgba(50, 115, 182, 0.25) 0%, rgba(50, 115, 182, 0) 35%)',
    padding: '80px 0',
  },
  titleContainer: {
    width: '100%',
    height: '30%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  title: { color: theme.palette.secondary.dark, },
  slideContainer: { overflow: 'hidden', },
  slides: {
    display: 'flex',
    paddingTop: '40px',
  },
  slide: {
    flex: '0 0 100%',
    display: 'flex',
    justifyContent: 'center',
    gap: '50px',
  },
  card: { width: '250px', },
  slideLogo: {
    width: '250px',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    boxShadow: '4px 4px 24px rgba(0, 0, 0, 0.2)',
    padding: '40px',
  },
  slideInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '32px',
  },
  slideTitle: {
    color: theme.palette.secondary.dark,
    textAlign: 'center',
  },
  slideDescription: { textAlign: 'center', },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32px',
  },
  prevButton: { paddingTop: '150px', },
  nextButton: { paddingTop: '150px', },
}));

// 將array裡的item，每三個一組做拆分
const sliceIntoChunks = (arr: any[], chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const SlideCard = ({
  logo, title, description, link, alt,
}: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <Box className={classes.slideLogo}>
          <img
            width="100%"
            src={getImage(logo)}
            alt={alt}
          />
        </Box>
      </a>
      <Box className={classes.slideInfo}>
        <Typography
          className={classes.slideTitle}
          fontSize={'16px'}
          fontWeight={'bold'}
          marginBottom={'24px'}
        >
          <FormattedMessage id={title} />
        </Typography>
        <Typography
          className={classes.slideDescription}
          fontSize={'14px'}
          marginBottom={'4px'}
        >
          <FormattedMessage id={description} />
        </Typography>
      </Box>
    </Box>
  );
};

const SLIDE_COUNT = 3;

const options = {
  delay: 5000,
  stopOnInteraction: false, // 拖動後是否停止自動輪播
}; // 自動輪播設定

const autoplay = Autoplay(options);

const Block4 = () => {
  const classes = useStyles();
  const [emblaRef, emblaApi,] = useEmblaCarousel({ loop: true, }, [autoplay,]);
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1100));
  const isMobile = useMediaQuery(theme.breakpoints.down(400));

  const scrollPrev = () => {
    emblaApi && emblaApi.scrollPrev();
    autoplay.reset();
  };

  const scrollNext = () => {
    emblaApi && emblaApi.scrollNext();
    autoplay.reset();
  };

  return (
    <Box className={classes.container}>
      <ScrollAnimation
        className={classes.titleContainer}
        animateIn="fadeInDown"
      >
        <Typography
          className={classes.title}
          fontSize={isLittleDesktop ? '24px' : '40px'}
          fontWeight={'bold'}
        >
          <FormattedMessage id={'homepage.blockfour.title'} />
        </Typography>
      </ScrollAnimation>
      {isLittleDesktop ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="40px"
        >
          {mockData.map((slideItem, index) => {
            return (
              <SlideCard
                key={index}
                logo={slideItem.logo}
                title={slideItem.title}
                description={slideItem.description}
                link={slideItem.link}
                alt={slideItem.alt}
              />
            );
          })}
        </Box>
      ) : (
        <Box
          display="flex"
          maxWidth="1050px"
          marginRight="auto"
          marginLeft="auto"
        >
          <Box className={classes.prevButton}>
            <PaginationButton
              onClick={scrollPrev}
              color="green"
              reverse="180"
            />
          </Box>
          <Box
            className={classes.slideContainer}
            ref={emblaRef}
          >
            <Box className={classes.slides}>
              {sliceIntoChunks(mockData, SLIDE_COUNT).map((chunk: any[], index: number) => {
                return (
                  <div
                    key={index}
                    className={classes.slide}
                  >
                    {chunk.map((slideItem, index) => {
                      return (
                        <SlideCard
                          key={index}
                          logo={slideItem.logo}
                          title={slideItem.title}
                          description={slideItem.description}
                          link={slideItem.link}
                          alt={slideItem.alt}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </Box>
          </Box>
          <Box className={classes.nextButton}>
            <PaginationButton
              onClick={scrollNext}
              color="green"
            />
          </Box>
        </Box>
      )}
      <Box className={classes.button}>
        <Link
          to={`/${locale}/about`}
          style={{ textDecoration: 'none', }}
        >
          <RectangleButton color="green">
            <FormattedMessage id={'homepage.blockthree.button'} />
          </RectangleButton>
        </Link>
      </Box>
    </Box>
  );
};

export default Block4;