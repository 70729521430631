import { useNavigate, } from 'react-router-dom';
import { useSelector, } from 'react-redux';
import { getGlobal, } from '@/reducers/states';
import {
  Box, Theme, Typography, Drawer,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import BackgroudImage from '../NotFound/assets/background.png';
import NotFoundImage from '../NotFound/assets/404-logo.png';
import RectangleButton from '@/components/RectangleButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    zIndex: '200',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  titleContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '40px',
  },
  title1: { color: theme.palette.secondary.dark, },
}));

const NotFound = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const { locale, } = useSelector(getGlobal);

  return (
    <Drawer
      open={true}
      hideBackdrop={true}
      transitionDuration={0}
    >
      <Box
        className={classes.container}
        style={{ backgroundImage: `url(${BackgroudImage})`, }}
      >
        <Box className={classes.titleContainer}>
          <img
            width={isMobile ? '273px' : '418px'}
            src={NotFoundImage}
          />
          <Typography
            className={classes.title1}
            fontSize={'14px'}
          >
            <FormattedMessage id={'404.title'} />
          </Typography>
          <RectangleButton
            color="green"
            onClick={() => navigate(locale + '/')}
          >
            <FormattedMessage id={'404.button'} />
          </RectangleButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default NotFound;