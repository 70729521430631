import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import useEmblaCarousel, { EmblaOptionsType, } from 'embla-carousel-react';
import {
  Box, Theme, Typography, Button,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTheme, } from '@mui/material/styles';
import Autoplay from '@/utils/embla-carousel-autoplay/autoPlay';
import { getImage, } from '@/utils/tool';
import PaginationButton from '@/components/PaginationButton';
import slideData from '../Block4SliderData.json';
import BackgroudImage from '../assets/Block4/background.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundPosition: 'top',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    padding: '100px 0',
  },
  titleContainer: {
    width: '580px',
    height: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '24px',
  },
  carousel: {
    display: 'flex',
    maxWidth: '1350px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  slideContainer: {
    overflow: 'hidden',
    width: '100%',
  },
  slides: {
    display: 'flex',
    paddingTop: '40px',
  },
  slide: {
    flex: '0 0 100%',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    width: '900px',
    height: '560px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.5s ease-out',
    gap: '40px',
    padding: '50px 0',
  },
  slideInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '500px',
    height: '100%',
    padding: '50px 0',
    textAlign: 'center',
  },
  slideButton: {
    width: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '16px',
    marginTop: '24px',
  },
  underLine: {
    width: '230px',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginTop: '8px',
  },
  [theme.breakpoints.down(1200)]: {
    container: {
      backgroundPosition: 'top',
      backgroundSize: 'auto 50%',
      padding: '100px 0',
    },
    titleContainer: { width: '90%', },
    carousel: { width: '100%', },
    card: {
      width: '342px',
      height: '400px',
      padding: '24px',
    },
  },
}));

const SlideCard = ({
  title1, title2, description, style,
}: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1200));

  return (
    <Box
      className={classes.card}
      style={style}
    >
      <Box className={classes.slideInfo}>
        <Typography
          fontSize={isLittleDesktop ? '14px' : '16px'}
          color={theme.palette.secondary.main}
        >
          <FormattedMessage id={description} />
        </Typography>
        <Typography
          fontSize={isLittleDesktop ? '16px' : '24px'}
          marginTop={'24px'}
          fontWeight={'bold'}
          color={theme.palette.secondary.main}
        >
          <FormattedMessage id={title1} />
        </Typography>
        <Typography
          fontSize={isLittleDesktop ? '16px' : '24px'}
          marginBottom={'24px'}
          fontWeight={'bold'}
          color={theme.palette.secondary.main}
        >
          <FormattedMessage id={title2} />
        </Typography>
      </Box>
    </Box>
  );
};

const options = {
  delay: 5000,
  stopOnInteraction: false, // 拖動後是否停止自動輪播
}; // 自動輪播設定

const autoplay = Autoplay(options);

const BlockFour = ({ id, }: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const [emblaRef, emblaApi,] = useEmblaCarousel({
    loop: true,
    align: 'center',
  }, [autoplay,]);
  const [currentSlideIndex, setCurrentSlideIndex,] = useState<any>(0);
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1100));

  const nextSliderIndex = currentSlideIndex === slideData.length - 1 ? 0 : currentSlideIndex + 1;
  const prevSliderIndex = currentSlideIndex === 0 ? slideData.length - 1 : currentSlideIndex - 1;

  const scrollPrev = () => {
    emblaApi && emblaApi.scrollPrev();
    autoplay.reset();
  };

  const scrollNext = () => {
    emblaApi && emblaApi.scrollNext();
    autoplay.reset();
  };

  useEffect(() => {
    const onSlideChange = () => {
      setCurrentSlideIndex(emblaApi?.selectedScrollSnap());
    };
    emblaApi?.on('select', onSlideChange);
    return () => {
      emblaApi?.off('select', onSlideChange);
    };
  }, [emblaApi,]);

  return (
    <div
      id={id}
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroudImage})`, }}
    >
      <ScrollAnimation
        className={classes.titleContainer}
        animateIn="fadeInDown"
      >
        <Typography
          fontSize={isLittleDesktop ? '24px' : '40px'}
          fontWeight={'bold'}
          color={theme.palette.secondary.main}
        >
          <FormattedMessage id={'service.blockfour.title'} />
        </Typography>
        <Typography
          fontSize={'16px'}
          color={theme.palette.secondary.main}
        >
          <FormattedMessage id={'service.blockfour.description'} />
        </Typography>
      </ScrollAnimation>
      <Box className={classes.carousel}>
        {!isLittleDesktop && (
          <Box className={classes.slideButton}>
            <PaginationButton
              onClick={scrollPrev}
              color="green"
              reverse="180"
              textPosition="bottom"
            >
              <Typography fontSize={'16px'}>
                <FormattedMessage id={slideData[prevSliderIndex].button_text} />
              </Typography>
            </PaginationButton>
          </Box>
        )}
        <Box
          className={classes.slideContainer}
          ref={emblaRef}
        >
          <Box className={classes.slides}>
            {slideData.map((slideItem, index) => {
              return (
                <Box
                  key={index}
                  className={classes.slide}
                >
                  <SlideCard
                    key={index}
                    title1={slideItem.title1}
                    title2={slideItem.title2}
                    description={slideItem.description}
                    style={{ backgroundImage: `url(${getImage(slideItem.background)})`, }}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        {!isLittleDesktop && (
          <Box className={classes.slideButton}>
            <PaginationButton
              onClick={scrollNext}
              color="green"
              textPosition="bottom"
            >
              <Typography fontSize={'16px'}>
                <FormattedMessage id={slideData[nextSliderIndex].button_text} />
              </Typography>
            </PaginationButton>
          </Box>
        )}
      </Box>
      <Box className={classes.bottomTitle}>
        <Typography
          fontSize={isLittleDesktop ? '24px' : '40px'}
          color={theme.palette.secondary.dark}
        >
          <FormattedMessage id={slideData[currentSlideIndex].button_text} />
        </Typography>
        <Typography
          fontSize={isLittleDesktop ? '14px' : '16px'}
          color={theme.palette.secondary.dark}
        >
          <FormattedMessage id={'service.blockfour.bottom_title'} />
        </Typography>
        <Box className={classes.underLine}></Box>
      </Box>

      {isLittleDesktop && (
        <Box
          width="100%"
          display="flex"
          gap="16px"
          mt="32px"
        >
          <Box
            width="50%"
            display="flex"
            justifyContent="center"
          >
            <PaginationButton
              onClick={scrollPrev}
              color="green"
              reverse="180"
              textPosition="bottom"
            >
              <Typography fontSize={'14px'}>
                <FormattedMessage id={slideData[prevSliderIndex].button_text} />
              </Typography>
            </PaginationButton>
          </Box>
          <Box
            width="50%"
            display="flex"
            justifyContent="center"
          >
            <PaginationButton
              onClick={scrollNext}
              color="green"
              textPosition="bottom"
            >
              <Typography fontSize={'14px'}>
                <FormattedMessage id={slideData[nextSliderIndex].button_text} />
              </Typography>
            </PaginationButton>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default BlockFour;