import React from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme,
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import {
  useNavigate, useParams,
} from 'react-router-dom';
import {
  useSelector, useDispatch,
} from 'react-redux';
import { Helmet, } from 'react-helmet';
import { newsActions, } from '@/reducers/news.slice';
import {
  getNews, getGlobal,
} from '@/reducers/states';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getLanguageNewsData, } from '@/utils/tool';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '400px',
    marginBottom: '100px',
    [theme.breakpoints.down('md')]: { marginBottom: '40px', },
  },
  imageOverLayer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(to bottom, rgba(14, 35, 56, 0.85) 0%, rgba(9, 68, 129, 0.1) 100%)',
  },
  img: {
    width: '100%',
    height: '400px',
    objectFit: 'cover',
  },
}));

const ArticleDetailBlock1 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const { newsContent, } = useSelector(getNews);
  const {
    data, loaded,
  } = newsContent;
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const basicUrl = window.Config.API_HOST;
  let ArticleData = React.useMemo(() => {
    if (loaded) {
      return getLanguageNewsData(data.translations, locale);
    }
  }, [loaded,]);
  const pageTitle = ArticleData?.title;
  const ogTagTitle = ArticleData?.title;
  // 找到文案內容第一行文字作為預覽的description
  const ogTagDescription = ArticleData?.contents.find((item: any) => item.type === 'TEXT');

  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={ogTagDescription}
        ></meta>
        <meta
          property="og:title"
          content={ogTagTitle}
        />
        <meta
          property="og:description"
          content={ogTagDescription}
        />
      </Helmet>
      <Box className={classes.container}>
        <Box className={classes.imageOverLayer}></Box>
        <img
          className={classes.img}
          src={loaded ? `${basicUrl}/assets/${data.header_image.id}?width=${isMobile ? '800' : '1920'}` : ''}
          alt={loaded ? `${ArticleData.title}` : ''}
        />
      </Box>
    </Box>
  );
};

export default React.memo(ArticleDetailBlock1);