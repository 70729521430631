import { GlobalState, } from '@/reducers/global.slice';
import { NewsState, } from '@/reducers/news.slice';
import { FinancialState, } from '@/reducers/financial.slice';
import { HistoryState, } from '@/reducers/history.slice';
import { ContactState, } from '@/reducers/contact.slice';

export interface RootState {
  global: GlobalState;
  news: NewsState;
  financial: FinancialState;
  history: HistoryState;
  contact: ContactState;
}

export const getGlobal = (state: RootState) => state.global;
export const getNews = (state: RootState) => state.news;
export const getFinancial = (state: RootState) => state.financial;
export const getHistory = (state: RootState) => state.history;
export const getContact = (state: RootState) => state.contact;