import zh_tw from './zh_tw.json';
import en_us from './en_us.json';

export type Locales = {
  [lang:string]:any
}

const languages: any = {
  en_us: { messages: en_us, },
  zh_tw: { messages: zh_tw, },
};
export default languages;