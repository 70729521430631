import React, {
  useState, useMemo, useEffect, useRef,
} from 'react';
import {
  Box, Theme, Typography, Select, MenuItem, FormControl, Autocomplete, TextField,
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import { makeStyles, } from '@mui/styles';
import { FormattedMessage, } from 'react-intl';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as DownloadIcon, } from '../assets/block5/icon-download.svg';
import {
  useSelector, useDispatch,
} from 'react-redux';
import {
  getFinancial, getGlobal,
} from '@/reducers/states';
import { financialActions, } from '@/reducers/financial.slice';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 120px 120px',
    [theme.breakpoints.down(800)]: { padding: '0 24px 80px', },
  },
  topFilter: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '315px',
    background: 'linear-gradient(180deg, rgba(50, 115, 182, 0.1) 0%, rgba(133, 193, 31, 0) 53.12%)',
    zIndex: 1,
  },
  title: {
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  },
  contentContainer: {
    maxWidth: '940px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    gap: '40px',
  },
  description: {
    width: '100%',
    marginBottom: '48px',
  },
  subTitle: {
    width: '100%',
    textAlign: 'left',
    borderBottom: '1px solid rgba(209, 210, 212, 0.45)',
    marginBottom: '24px',
  },
  subTitleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    color: theme.palette.secondary.dark,
    opacity: 0.85,
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: '24px',
  },
  formControl: { minWidth: '150px', },
  listContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  },
  list: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #D1D2D473',
    padding: '16px 0',
  },
  download: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '24px',
      height: '24px',
      marginLeft: '8px',
    },
    '&:hover': {
      '& svg path': { stroke: `${theme.palette.primary.main}`, },
      '& p': { color: theme.palette.primary.main, },
    },
  },
}));

const FinancialBlock4 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const [year, setYear,] = React.useState<any>(String(new Date().getFullYear()));
  const [yearList, setYearList,] = React.useState<string[]>([]);
  const [fileList, setFileList,] = React.useState<any>([]);
  const { locale, } = useSelector(getGlobal);
  const { annual, } = useSelector(getFinancial);
  const {
    data, loaded, loading,
  } = annual;
  const basicUrl = window.Config.API_HOST;

  const handleGetAnnual = () => {
    dispatch(financialActions.getAnnualRequest({ fields: '*.*', }));
  };

  const handleClickDownload = (fileName: string, id:string) => {
    const link = document.createElement('a');
    link.href = `${basicUrl}/assets/${id}`;
    link.target = '_blank';
    link.download = `${fileName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangeYear = (year: string) => {
    const newFileList: any[] = [];
    data.filter((item) => String(item.year) === year).forEach((item) => {
      const dataItem: {id:string, title:string} = {
        id: '',
        title:'',
      };
      dataItem.id = item.file.id;
      dataItem.title = item.translations.find((nameInfo: any) => {
        return nameInfo.languages_code.toLowerCase().replace('-', '_') === locale;
      })?.title ?? '';
      newFileList.push(dataItem);
    });
    setFileList(newFileList);
  };

  const handleYearList = () => {
    const newYearList: string[] = [];
    data.forEach((item) => {
      if(newYearList.includes(String(item.year))) return;
      newYearList.push(String(item.year));
    });
    setYearList(newYearList);

    // 帶入最近年份
    if(newYearList.length > 0){
      setYear(newYearList[0]);
    }
  };

  // 進入頁面、轉換年份時取得營業額資訊
  useEffect(() => {
    handleGetAnnual();
  }, []);

  // 資料帶入
  useEffect(() => {
    if(loaded) {
      handleChangeYear(year);
    }
  }, [loaded, year,]);

  // 第一次帶入新年份
  useEffect(() => {
    if(loaded) {
      handleYearList();
    }
  }, [loaded,]);

  return (
    <Box id="annual" className={classes.container}>
      <Box
        className={classes.contentContainer}
      >
        {/* 財務報表 */}
        <Box className={classes.description}>
          <Box className={classes.subTitle}>
            <Box className={classes.subTitleContent}>
              <Typography
                fontSize={isMobile ? '16px' : '24px'}
                fontWeight="700"
                color={theme.palette.secondary.light}
              >
                <FormattedMessage id="investor.financial.blockfour.data.1.title" />
              </Typography>

              <FormControl
                variant="outlined"
                margin="dense"
              >
                <Autocomplete
                  className={classes.formControl}
                  fullWidth
                  size="small"
                  value={year || String(new Date().getFullYear())}
                  options={yearList}
                  disableClearable
                  onChange={(event: any, newValue: string | null) => {setYear(newValue);}}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Box>
          <Box className={classes.subTitleContent}>
            <Typography
              fontSize={isMobile ? '16px' : '24px'}
              fontWeight="700"
              color={theme.palette.secondary.light}
              pb={2}
            >
              {year}
            </Typography>
          </Box>
          <Box className={classes.listContainer}>
            {fileList.map((item: any) => (
              <Box
                key={item.title}
                className={classes.list}
              >
                <Typography
                  fontSize={isMobile ? '14px' : '16px'}
                  fontWeight="700"
                  color={theme.palette.secondary.dark}
                >
                  {item.title}
                </Typography>
                <Box
                  className={classes.download}
                  onClick={() => handleClickDownload(item.title, item.id)}
                >
                  <Typography
                    fontSize={isMobile ? '14px' : '16px'}
                    fontWeight="700"
                  >
                    <FormattedMessage id="investor.financial.data.download" />
                  </Typography>
                  <DownloadIcon />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default React.memo(FinancialBlock4);