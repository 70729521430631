import React, {
  useState, useMemo, useEffect, useRef,
} from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme, Typography, Divider,
} from '@mui/material';
import clsx from 'clsx';
import {
  useSelector, useDispatch,
} from 'react-redux';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { newsActions, } from '@/reducers/news.slice';
import {
  getNews, getGlobal,
} from '@/reducers/states';
import { FormattedMessage, } from 'react-intl';
import {
  useNavigate, useSearchParams,
} from 'react-router-dom';
import Loading from '@/pages/Loading/Loading';
import PaginationButton from '@/components/PaginationButton';
import TextButton from '@/components/TextButton';
import { getLanguageNewsData, } from '@/utils/tool';
import SideBar from './SideBar';
import { ActiveCategories, } from './SideBar';
import ArticleCard from './ArticleCard';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '120px 120px 0 120px',
    marginBottom: '120px',
    [theme.breakpoints.down('md')]: { padding: '60px 24px 0px 24px', },
  },
  selectedBarContainer: {
    display: 'flex',
    marginBottom: '32px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '12px',
    },
  },
  divider: {
    background: theme.palette.secondary.dark,
    opacity: '0.45',
  },
  tagSelected: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.dark,
  },
  categoriesSelected: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  sectionContainer: {
    display: 'flex',
    gap: '60px',
    [theme.breakpoints.down('md')]: { flexDirection: 'column', },
  },
  sectionRight: {
    width: '30%',
    [theme.breakpoints.down('md')]: { width: '100%', },
  },
  articlesBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  sectionLeft: {
    width: '70%',
    [theme.breakpoints.down('md')]: { width: '100%', },
  },
  paginationBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '20px',
  },
}));

const BlockTwo = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(900));
  const { locale, } = useSelector(getGlobal);
  const { news, } = useSelector(getNews);
  const {
    data, loaded, filter_count, loading,
  } = news;
  const PAGE_ITEM_LIMIT = 6;
  const basicUrl = window.Config.API_HOST;

  const [searchParams, setSearchParams,] = useSearchParams();
  const [activeTags, setActiveTags,] = useState<any>('');
  const [page, setPage,] = useState<number>(1);
  const containerRef = useRef<null | HTMLElement>(null);
  const paramsTags = searchParams.get('tags');
  const categoriesTags = searchParams.get('categories');

  const handleClearTags = () => {
    searchParams.delete('tags');
    setSearchParams(searchParams);
  };

  const handlePrevPage = () => {
    setPage(page - 1);
    handleGetNews(page - 1);
    handleScrollTop();
  };

  const handleNextPage = () => {
    setPage(page + 1);
    handleGetNews(page + 1);
    handleScrollTop();
  };

  const handleScrollTop = () => {
    setTimeout(() => {
      containerRef.current?.scrollIntoView({ behavior: 'smooth', });
    }, 500);
  };

  const handleGetNews = (page: number) => {
    dispatch(newsActions.getNewsRequest({
      fields: '*.*.*.*.*',
      limit: PAGE_ITEM_LIMIT,
      category: categoriesTags,
      page: page,
      tags: paramsTags,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loaded,]);

  useEffect(() => {
    // 如果categories沒有參數，則在進入頁面時自動設為all
    if (!categoriesTags) {
      searchParams.set('categories', ActiveCategories.ALL);
      setSearchParams(searchParams);
    }
  }, [categoriesTags,]);

  useEffect(() => {
    if (paramsTags) {
      setActiveTags(paramsTags.replaceAll(',', ' , '));
    } else {
      setActiveTags('');
    }
  }, [paramsTags,]);

  // 當標籤或類別改變時重設頁面到第一頁
  useEffect(() => {
    setPage(1);
    handleGetNews(1);
  }, [categoriesTags, paramsTags,]);

  const renderTabSelector = useMemo(() => {
    return (
      <Box className={classes.selectedBarContainer}>
        <Box display="flex">
          <Typography>
            <FormattedMessage id="newspage.blocktwo.tab.description" />
          </Typography>
          <Box className={classes.categoriesSelected}>
            <FormattedMessage id={`newspage.blocktwo.sidebar.${categoriesTags}`} />
          </Box>
        </Box>
        <Box display="flex">
          {activeTags && (
            <React.Fragment>
              {!isMobile && (
                <Divider
                  classes={{ root: classes.divider, }}
                  sx={{
                    height: '22px',
                    margin: '0 16px',
                    width: '1px',
                  }}
                  light
                  orientation="vertical"
                />
              )}
              <Box className={classes.tagSelected}>
                {activeTags}
                <Box ml="16px">
                  <TextButton
                    color="green"
                    onClick={handleClearTags}
                  >
                    <FormattedMessage id={'newspage.blocktwo.clear_button'} />
                  </TextButton>
                </Box>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Box>
    );
  }, [
    activeTags,
    categoriesTags,
    searchParams,
    isMobile,
  ]);

  if (!loaded) return <Loading />;
  return (
    <Box
      className={classes.container}
      ref={containerRef}
    >
      <Box>{renderTabSelector}</Box>
      <Box className={classes.sectionContainer}>
        {/* 文章區域 */}
        <Box className={classes.sectionLeft}>
          <Box className={classes.articlesBox}>
            {data.map((article, index) => {
              // 找到當前語系的文案
              const languageNewsData = getLanguageNewsData(article.translations, locale);
              // 找到文案內容第一行文字作為預覽
              const translateContent = languageNewsData.contents.find((item: any) => item.type === 'TEXT');
              return (
                <ArticleCard
                  key={article.id}
                  id={article.id}
                  date={article.date_published}
                  title={languageNewsData.title}
                  articleTag={article.tags}
                  publisher={languageNewsData.publisher}
                  content={translateContent?.content}
                  image={`${basicUrl}/assets/${article.cover.id}?width=500`}
                  alt={article.cover.title}
                />
              );
            })}
          </Box>
          <Box className={classes.paginationBox}>
            <PaginationButton
              color="green"
              reverse="180"
              disabled={page === 1}
              onClick={handlePrevPage}
            />
            <PaginationButton
              color="green"
              disabled={Math.ceil(filter_count / PAGE_ITEM_LIMIT) === page || filter_count < PAGE_ITEM_LIMIT}
              onClick={handleNextPage}
            />
          </Box>
        </Box>
        {/* 側邊欄 */}
        <Box className={classes.sectionRight}>
          <SideBar />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(BlockTwo);