import React, {
  useState, useEffect, useRef, type FC, type ComponentProps, useMemo,
} from 'react';
import {
  useNavigate, Link,
} from 'react-router-dom';
import {
  Box, Theme, Typography,
} from '@mui/material';
import {
  FormattedMessage, useIntl,
} from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  useDispatch, useSelector,
} from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';
import Path from '../../../plugins/path.json';
import BackgroudImage from '../assets/Block2/background.png';
import GreenLine from '../assets/Block2/green-line.png';
import zhEsgImage1 from '../assets/Block2/esg-pic-1-zh.png';
import enEsgImage1 from '../assets/Block2/esg-pic-1-en.png';
import EsgImage2 from '../assets/Block2/esg-pic-2.png';
import EsgImage3 from '../assets/Block2/esg-pic-3.png';
import EsgImage4 from '../assets/Block2/esg-pic-4.png';
import EsgImage7 from '../assets/Block2/esg-pic-7.png';
import CardData from '../Block2CardData.json';
import { getImage, } from '@/utils/tool';
import PaginationButton from '@/components/PaginationButton';
import TextButton from '@/components/TextButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '120px 100px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down(800)]: {
      padding: '0px 24px 60px',
      height: '100%',
    },
  },
  anchorBar: {
    display: 'flex',
    alignItems: 'center',
    height: '68px',
    padding: '24px',
    marginBottom: '60px',
    fontSize: '14px',
    width: '100%',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': { display: 'none', },
  },
  info1Container: {
    width: '710px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '40px',
    [theme.breakpoints.down(800)]: { width: '100%', },
  },
  title1: { color: theme.palette.primary.main, },
  description1: {
    width: '100%',
    maxWidth: '720px',
    color: theme.palette.secondary.dark,
  },
  info2Container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '40px',
    [theme.breakpoints.down(800)]: { width: '100%', },
  },
  title2: { color: theme.palette.secondary.dark, },
  description2: {
    color: theme.palette.secondary.dark,
    textAlign: 'center',
  },
  info3Container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '120px',
    gap: '100px',
    [theme.breakpoints.down(1100)]: {
      width: '100%',
      marginTop: '60px',
      flexDirection: 'column',
      gap: '24px',
    },
  },
  title3: { color: theme.palette.secondary.dark, },
  info3Description: {
    maxWidth: '520px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  info4Container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '120px',
    gap: '100px',
    [theme.breakpoints.down(1100)]: {
      width: '100%',
      marginTop: '60px',
      flexDirection: 'column-reverse',
      gap: '24px',
    },
  },
  info5Container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '120px',
    gap: '100px',
    [theme.breakpoints.down(1100)]: {
      width: '100%',
      marginTop: '60px',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '24px',
    },
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
    [theme.breakpoints.down(1100)]: { flexWrap: 'wrap', },
  },
  cardContaner: {
    position: 'relative',
    width: '328px',
    height: '373px',
    border: 'solid 1px #c0ed72',
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(to top, #f6faee, rgba(246, 250, 238, 0))',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardImg: {
    position: 'absolute',
    right: '50%',
    top: '30%',
    transform: 'translate(50%, -50%)',
  },
  cardTitle: {
    position: 'absolute',
    right: '50%',
    transform: 'translate(50%, -50%)',
    width: '112px',
    height: '28px',
    borderRadius: '20px',
    backgroundColor: '#dfefc4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    color: theme.palette.secondary.dark,
  },
  cardinfo: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '32px',
  },
  cardDescription1: {
    width: '100%',
    color: theme.palette.secondary.dark,
    fontSize: '66px',
    fontWeight: 'bold',
    [theme.breakpoints.down(800)]: { fontSize: '40px', },
  },
  cardDescription2: {
    width: '100%',
    color: theme.palette.secondary.dark,
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '8px',
    [theme.breakpoints.down(800)]: { fontSize: '16px', },
  },
  cardDescription3: {
    width: '100%',
    color: theme.palette.primary.main,
    fontSize: '16px',
    [theme.breakpoints.down(800)]: { fontSize: '14px', },
  },
}));

const esgPageAnchors = Path.find((item, index) => item.path === '/esg');

const Card = ({
  title, imgPath, description1, description2, description3,
}: any) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.cardContaner}>
      <Box className={classes.cardTitle}>
        <FormattedMessage id={title} />
      </Box>
      <img
        className={classes.cardImg}
        height="115px"
        src={getImage(imgPath)}
        alt={intl.formatMessage({ id: title, })}
      />
      <Box className={classes.cardinfo}>
        <Box className={classes.cardDescription1}>{description1}</Box>
        <Box className={classes.cardDescription2}>
          <FormattedMessage id={description2} />
        </Box>
        <Box className={classes.cardDescription3}>
          <FormattedMessage id={description3} />
        </Box>
      </Box>
    </Box>
  );
};

const Statement: FC<ComponentProps<typeof Box> & { locale?: string }> = ({
  locale = 'zh_tw', ...rest
}) => {
  const data = useMemo(() => locale === 'zh_tw' ? [
    'esg/assets/Block2/statement1.png',
    'esg/assets/Block2/statement2.png',
    'esg/assets/Block2/statement3.png',
    'esg/assets/Block2/statement4.png',
    'esg/assets/Block2/statement5.png',
  ] : [
    'esg/assets/Block2/statement-en1.png',
    'esg/assets/Block2/statement-en2.png',
    'esg/assets/Block2/statement-en3.png',
    'esg/assets/Block2/statement-en4.png',
    'esg/assets/Block2/statement-en5.png',
    'esg/assets/Block2/statement-en6.png',
  ], [locale,]);
  return (
    <Box style={{
      display: 'flex',
      gap: '16px',
      maxWidth: '1100px',
      width: '100%',
      padding: '0 24px',
      overflowX: 'auto',
    }} {...rest}>
      {
        data.map((item, i) => (
          <img
            width={250}
            key={item}
            src={getImage(item)}
            alt={`statement${i}`} />
        ))
      }
    </Box>
  );
};

const BlockTwo = ({ id, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const intl = useIntl();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const isShowAnchor = useMediaQuery(theme.breakpoints.down('md'));

  const anchorRef = useRef<any>(null);
  const [anchorCenter, setAnchorCenter,] = useState(false);

  // 判斷 anchor bar 是否需要置中（如果需要 scroll 的話靠左，沒有的話置中）
  useEffect(() => {
    if (!!anchorRef.current) {
      const anchorContainerWidth = anchorRef.current.clientWidth;
      let anchorItemsTotalWidth = 0;
      for (const item of anchorRef.current.children) {
        anchorItemsTotalWidth += item.clientWidth;
      }
      if (anchorContainerWidth > anchorItemsTotalWidth) {
        setAnchorCenter(true);
      }
    }
  }, []);

  return (
    <div
      id={id}
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroudImage})`, }}
    >
      {isShowAnchor && (
        <Box
          ref={anchorRef}
          className={classes.anchorBar}
          sx={{ justifyContent: `${anchorCenter ? 'center' : 'flex-start'}`, }}
        >
          {esgPageAnchors?.menu
            ?.filter((item) => !!item.anchor)
            .map((item: any, index: any) => {
              // “關於新光鋼集團” 為外部連結，不放在白色錨點列表
              return (
                <Link
                  key={index}
                  to={item.anchor}
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.secondary.dark,
                    borderLeft: index !== 0 ? `2px solid ${theme.palette.primary.main}` : '',
                    padding: '0 24px',
                  }}
                >
                  <TextButton>
                    <FormattedMessage id={item.title} />
                  </TextButton>
                </Link>
              );
            })}
        </Box>
      )}

      {/* 上方綠色線條圖片 */}
      <img
        width="100%"
        src={GreenLine}
        alt={intl.formatMessage({ id: 'esg.blocktwo.title.1', })}
      />

      <Box className={classes.info1Container}>
        <Typography
          className={classes.title1}
          fontSize={'24px'}
          fontWeight={'bold'}
          marginTop={'16px'}
          marginBottom={'24px'}
        >
          <FormattedMessage id={'esg.blocktwo.title.1'} />
        </Typography>
        <Box
          display="flex"
          flexWrap={isMobile ? 'wrap' : 'unset'}
          justifyContent={isMobile ? 'center' : 'unset'}
          gap="40px"
        >
          <img
            width="240px"
            src={locale === 'en_us' ? enEsgImage1 : zhEsgImage1}
            alt={intl.formatMessage({ id: 'esg.blocktwo.title.1', })}
          />
          <Typography
            className={classes.description1}
            fontSize={isMobile ? '14px' : '16px'}
          >
            <FormattedMessage id={'esg.blocktwo.description.1'} />
          </Typography>
        </Box>
      </Box>

      <ScrollAnimation
        className={classes.info2Container}
        animateIn="fadeInDown"
      >
        <Typography
          className={classes.title2}
          fontSize={isMobile ? '24px' : '40px'}
          fontWeight={'bold'}
          marginTop={'16px'}
          marginBottom={'24px'}
        >
          <FormattedMessage id={'esg.blocktwo.title.2'} />
        </Typography>
        <Typography
          className={classes.description2}
          fontSize={isMobile ? '14px' : '16px'}
        >
          <FormattedMessage id={'esg.blocktwo.description.2'} />
        </Typography>
      </ScrollAnimation>

      <Box className={classes.cardsContainer}>
        {/* 溫室氣體與碳排放卡片 */}
        {CardData.map((data, index) => {
          return (
            <Card
              key={index}
              title={data.title}
              imgPath={locale === 'zh_tw' ? data.imgPath : data.enImgPath}
              description1={data.description1}
              description2={data.description2}
              description3={data.description3}
            />
          );
        })}
      </Box>

      <Statement sx={{ marginTop: '80px', }} locale={locale} />

      <Typography sx={{
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'right',
        color: 'rgba(14, 35, 56, 0.45);',
        width: '100%',
        maxWidth: '1100px',
        marginTop: '25px',
      }}>
        <FormattedMessage id="esg.blocktwo.statement" />
      </Typography>

      {/* 水資源循環利用 */}
      <Box className={classes.info3Container}>
        <img
          width={isMobile ? '100%' : '350px'}
          src={EsgImage2}
          alt={intl.formatMessage({ id: 'esg.blocktwo.title.3', })}
        />
        <ScrollAnimation
          className={classes.info3Description}
          animateIn="fadeInRight"
        >
          <Typography
            className={classes.title3}
            fontSize={isMobile ? '24px' : '40px'}
            fontWeight={'bold'}
            marginTop={'16px'}
            marginBottom={'24px'}
          >
            <FormattedMessage id={'esg.blocktwo.title.3'} />
          </Typography>
          <Typography
            className={classes.title3}
            fontSize={isMobile ? '14px' : '16px'}
            marginBottom={'16px'}
          >
            <span style={{ fontWeight: 'bold', }}>
              <FormattedMessage id={'esg.blocktwo.description.3-1'} />
            </span>
            <FormattedMessage id={'esg.blocktwo.description.3-2'} />
          </Typography>
          <Typography
            className={classes.title3}
            fontSize={isMobile ? '14px' : '16px'}
            marginBottom={'24px'}
          >
            <FormattedMessage id={'esg.blocktwo.description.4'} />
          </Typography>
        </ScrollAnimation>
      </Box>

      {/* 廢棄物處理 */}
      <Box className={classes.info4Container}>
        <ScrollAnimation
          className={classes.info3Description}
          animateIn="fadeInLeft"
        >
          <Typography
            className={classes.title3}
            fontSize={isMobile ? '24px' : '40px'}
            fontWeight={'bold'}
            marginTop={'16px'}
            marginBottom={'24px'}
          >
            <FormattedMessage id={'esg.blocktwo.title.4'} />
          </Typography>
          {
            [
              1,
              2,
              3,
            ].map(item => (
              <>
                <Typography
                  sx={[{
                    fontFamily: 'Noto Sans',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '22px',
                  }, (theme) => ({ [theme.breakpoints.up('md')]: {
                    fontSize: '14px',
                    lineHeight: '19px',
                  }, }),]}
                  marginBottom={'8px'}
                >
                  <FormattedMessage id={`esg.blocktwo.one.list.${item}.title`} />
                </Typography>
                <Typography
                  className={classes.title3}
                  fontSize={isMobile ? '14px' : '16px'}
                  marginBottom={'24px'}
                >
                  <FormattedMessage id={`esg.blocktwo.one.list.${item}.description`} />
                </Typography>
              </>
            ))
          }
        </ScrollAnimation>
        <img
          width={isMobile ? '100%' : '457px'}
          src={EsgImage3}
          alt={intl.formatMessage({ id: 'esg.blocktwo.title.4', })}
        />
      </Box>

      <Box className={classes.info5Container}>
        <img
          width={isMobile ? '100%' : '475px'}
          src={EsgImage7}
          alt={intl.formatMessage({ id: 'esg.blocktwo.title.6', })}
        />
        <Box className={classes.info3Description}>
          <ScrollAnimation animateIn="fadeInRight">
            <Typography
              className={classes.title3}
              fontSize={isMobile ? '24px' : '40px'}
              fontWeight={'bold'}
              marginTop={'16px'}
              marginBottom={'24px'}
            >
              <FormattedMessage id={'esg.blocktwo.title.6'} />
            </Typography>
            <Typography
              sx={[{
                fontFamily: 'Noto Sans',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '22px',
              }, (theme) => ({ [theme.breakpoints.up('md')]: {
                fontSize: '14px',
                lineHeight: '19px',
              }, }),]}
              marginBottom={'8px'}
            >
              <FormattedMessage id={'esg.blocktwo.three.list.1.title'} />
            </Typography>
            <Typography
              className={classes.title3}
              fontSize={isMobile ? '14px' : '16px'}
              marginBottom={'24px'}
            >
              <FormattedMessage id={'esg.blocktwo.three.list.1.description'} />
            </Typography>
          </ScrollAnimation>
        </Box>
      </Box>

      {/* 參與彰化縣政府推廣農業ESG */}
      <Box className={classes.info4Container}>
        <Box className={classes.info3Description}>
          <ScrollAnimation animateIn="fadeInLeft">
            <Typography
              className={classes.title3}
              fontSize={isMobile ? '24px' : '40px'}
              fontWeight={'bold'}
              marginTop={'16px'}
              marginBottom={'24px'}
            >
              <FormattedMessage id={'esg.blocktwo.title.5'} />
            </Typography>
            <Typography
              className={classes.title3}
              fontSize={isMobile ? '14px' : '16px'}
              marginBottom={'16px'}
            >
              <FormattedMessage id={'esg.blocktwo.description.7'} />
            </Typography>
            <Typography
              className={classes.title3}
              fontSize={isMobile ? '14px' : '16px'}
              marginBottom={'24px'}
            >
              <FormattedMessage id={'esg.blocktwo.description.8'} />
            </Typography>
          </ScrollAnimation>
          <PaginationButton
            onClick={() => {
              const newsId = 'apex-news-2022-05-24-hks-steel-apex-donate-wood-decomposition-liquid-to-changhua-government';
              navigate(`/${locale}/news/${newsId}`);
            }}
            color="green"
          >
            <FormattedMessage id={'esg.blocktwo.button'} />
          </PaginationButton>
        </Box>
        <img
          width={isMobile ? '100%' : '475px'}
          src={EsgImage4}
          alt={intl.formatMessage({ id: 'esg.blocktwo.title.5', })}
        />
      </Box>
    </div>
  );
};

export default BlockTwo;