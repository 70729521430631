import { Box, } from '@mui/material';
import { makeStyles, } from '@mui/styles';
import { Theme, } from '@mui/material';

type Props = {
  children?: any;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  topLine?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
  },
  textContainer: {
    color: theme.palette.secondary.dark,
    userSelect: 'none',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover': { color: theme.palette.primary.main, },
    '&:hover + $underLine': {
      transformOrigin: 'bottom left',
      transform: 'scaleX(1)',
      '&:after': {
        transformOrigin: 'bottom left',
        transform: 'scaleX(1)',
      },
    },
    '&:active': {
      color: theme.palette.primary.main,
      '&:after': {
        transformOrigin: 'bottom left',
        transform: 'scaleX(1)',
      },
    },
  },
  topLine: {
    width: '100%',
    transform: 'scaleX(1)',
    height: '2px',
    bottom: '-3px',
    left: '0',
    backgroundColor: 'rgba(209, 210, 212, 0.45)',
    transformOrigin: 'bottom left',
    transition: 'transform 0.25s ease-out',
  },
  underLine: {
    width: '100%',
    transform: 'scaleX(1)',
    height: '2px',
    bottom: '-3px',
    left: '0',
    backgroundColor: 'rgba(209, 210, 212, 0.45)',
    transformOrigin: 'bottom left',
    transition: 'transform 0.25s ease-out',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      transform: 'scaleX(0)',
      height: '2px',
      bottom: '0px',
      left: '0',
      backgroundColor: theme.palette.primary.main,
      transformOrigin: 'bottom left',
      transition: 'transform 0.25s ease-out',
    },
  },
}));

const MenuButton = ({
  children, onClick, topLine,
}: Props) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      onClick={onClick}
    >
      {topLine && <Box className={classes.topLine}></Box>}
      <Box
        className={classes.textContainer}
        style={{ marginTop: topLine ? '10px' : '0px', }}
      >
        {children}
      </Box>
      <Box className={classes.underLine}></Box>
    </Box>
  );
};

export default MenuButton;