import { createSlice, } from '@reduxjs/toolkit';

export const FINANCIAL_FEATURE_KEY = 'financial';

/*
 * Update these interfaces according to your requirements.
 */

export interface FinancialState {
  revenues: {
    loading: boolean;
    loaded: boolean;
    data: any[];
  };
  finance: {
    loading: boolean;
    loaded: boolean;
    data: any[];
  };
  annual: {
    loading: boolean;
    loaded: boolean;
    data: any[];
  };
  report: {
    loading: boolean;
    loaded: boolean;
    data: any;
  };
}

export const initialFinancialState: FinancialState = {
  revenues: {
    loading: false,
    loaded: false,
    data: [],
  },
  finance: {
    loading: false,
    loaded: false,
    data: [],
  },
  annual: {
    loading: false,
    loaded: false,
    data: [],
  },
  report: {
    loading: false,
    loaded: false,
    data: null,
  },
};

export const financialSlice = createSlice({
  name: FINANCIAL_FEATURE_KEY,
  initialState: initialFinancialState,
  reducers: {
    getRevenuesRequest(state, action) {
      state.revenues = {
        ...state.revenues,
        loading: true,
        loaded: false,
      };
    },
    getRevenuesSuccess(state, action) {
      state.revenues = {
        ...state.revenues,
        loading: false,
        loaded: true,
        data: [...action.payload.data,],
      };
    },
    getRevenuesFail(state, action) {
      state.revenues = {
        ...state.revenues,
        loading: false,
        loaded: false,
      };
    },

    getFinanceRequest(state, action) {
      state.finance = {
        ...state.finance,
        loading: true,
        loaded: false,
      };
    },
    getFinanceSuccess(state, action) {
      state.finance = {
        ...state.finance,
        loading: false,
        loaded: true,
        data: [...action.payload.data,],
      };
    },
    getFinanceFail(state, action) {
      state.finance = {
        ...state.finance,
        loading: false,
        loaded: false,
      };
    },

    getAnnualRequest(state, action) {
      state.annual = {
        ...state.annual,
        loading: true,
        loaded: false,
      };
    },
    getAnnualSuccess(state, action) {
      state.annual = {
        ...state.annual,
        loading: false,
        loaded: true,
        data: [...action.payload.data,],
      };
    },
    getAnnualFail(state, action) {
      state.annual = {
        ...state.annual,
        loading: false,
        loaded: false,
      };
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const financialReducer = financialSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const financialActions = financialSlice.actions;