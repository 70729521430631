import React from 'react';
import { Box, } from '@mui/material';
import { Helmet, } from 'react-helmet';
import { useIntl, } from 'react-intl';
import Block1 from './component/Block1';
import Block2 from './component/Block2';

const News = () => {
  const intl = useIntl();

  // meta tag文案
  const pageTitle = intl.formatMessage({ id: 'newspage.page.title', });
  const ogTagTitle = intl.formatMessage({ id: 'newspage.ogtag.title', });
  const ogTagDescription = intl.formatMessage({ id: 'newspage.ogtag.description', });

  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={ogTagDescription}
        ></meta>
        <meta
          property="og:title"
          content={ogTagTitle}
        />
        <meta
          property="og:description"
          content={ogTagDescription}
        />
      </Helmet>
      <Block1 />
      <Block2 />
    </Box>
  );
};

export default React.memo(News);