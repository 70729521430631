import React, {
  useState, useEffect,
} from 'react';
import {
  useNavigate, Link,
} from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  Box, Theme,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import { isLink, } from '@/utils/tool';
import path from './path.json';
import LanguageSwicher from './component/LanguageSwicher';
import ApexLogo from './assets/apex-logo.png';
import CloseIcon from './assets/header-close-icon.png';
import MoreIcon from './assets/header-more-icon.png';
import LessIcon from './assets/header-less-icon.png';
import HksSteelRedLogo from './assets/hkssteel-red-logo.png';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    overflowY: 'auto',
    color: 'white',
    padding: '0 40px',
    transition: 'top 0.25s ease-out',
    zIndex: '101',
    backgroundColor: theme.palette.secondary.main,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0',
  },
  checkBoxWrapper: {
    width: '80px',
    height: '24px',
    position: 'relative',
    cursor: 'pointer',
  },
  checkBoxLabel: {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    width: '80px',
    height: '24px',
    borderRadius: '4px',
    background: theme.palette.secondary.dark,
    userSelect: 'none',
    '&:after': {
      content: '""',
      display: 'block',
      borderRadius: '4px 0px 0px 4px',
      width: '40px',
      height: '24px',
      background: theme.palette.primary.main,
      transition: '0.2s',
    },
  },
  checkBox: {
    opacity: '0',
    zIndex: '3',
    borderRadius: '4px',
    width: '80px',
    height: '24px',
    '&:checked + $checkBoxLabel': { '&:after': {
      content: '""',
      display: 'block',
      borderRadius: '0px 4px 4px 0px',
      width: '40px',
      height: '24px',
      background: theme.palette.primary.main,
      marginLeft: '40px',
      transition: '0.2s',
    }, },
  },
  closeIcon: {
    cursor: 'pointer',
    marginLeft: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  itemsContainer: {
    borderBottom: '1px solid rgba(209, 210, 212, 0.45)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    transition: 'all 0.5s ease-out',
    '&:first-child': { borderTop: '1px solid rgba(209, 210, 212, 0.45)', },
  },
  menuItem: {
    padding: '24px 20px 24px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '16px',
  },
  menuDropdownItem: {
    padding: '0px 20px 24px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  moreIcon: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lessIcon: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const MenuItem = ({
  item, children,
}: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const [isOpenMenu, setIsOpenMenu,] = useState(false);

  return (
    <Box className={classes.itemsContainer}>
      <Box className={classes.menuItem}>
        {isLink(item.path) ? (
          <a
            style={{
              textDecoration: 'none',
              color: isOpenMenu ? theme.palette.primary.main : theme.palette.secondary.dark,
            }}
            href={item.path}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        ) : (
          <Link
            style={{
              textDecoration: 'none',
              color: isOpenMenu ? theme.palette.primary.main : theme.palette.secondary.dark,
            }}
            to={locale + item.path}
          >
            {children}
          </Link>
        )}

        {/* 加減 icon */}
        {item.menu &&
          (isOpenMenu ? (
            <Box
              className={classes.lessIcon}
              onClick={() => setIsOpenMenu(false)}
            >
              <img
                width={'20px'}
                height={'20px'}
                src={LessIcon}
                alt="less-icon"
              />
            </Box>
          ) : (
            <Box
              className={classes.moreIcon}
              onClick={() => setIsOpenMenu(true)}
            >
              <img
                width={'20px'}
                height={'20px'}
                src={MoreIcon}
                alt="more-icon"
              />
            </Box>
          ))}
      </Box>

      {/* 下拉選單 */}
      {item.menu &&
        isOpenMenu &&
        item.menu.map((menuItem: any, index: any) => {
          const isLink = !!menuItem.link;
          if (isLink) {
            return (
              <Box
                key={index}
                className={classes.menuDropdownItem}
              >
                <a
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.secondary.dark,
                  }}
                  href={menuItem.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage id={menuItem.title} />
                </a>
              </Box>
            );
          } else {
            return (
              <Box
                key={index}
                className={classes.menuDropdownItem}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.secondary.dark,
                  }}
                  to={menuItem.anchor.includes('/') ? `${locale}${menuItem.anchor}` : `${locale}${item.path}${menuItem.anchor}`}
                >
                  <FormattedMessage id={menuItem.title} />
                </Link>
              </Box>
            );
          }
        })}
    </Box>
  );
};

const MobileHeader = ({ onClose, }: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { locale, } = useSelector(getGlobal);

  return (
    <Box className={classes.container}>
      <Box className={classes.logoContainer}>
        {/* left area */}
        <Box display="flex">
          {/* Apex Logo */}
          <Link to={`${locale}/`}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRight="1px solid rgba(209, 210, 212, 0.45)"
              paddingRight="12px"
              style={{ textDecoration: 'none', }}
              onClick={() => {
                navigate('/');
              }}
            >
              <img
                src={ApexLogo}
                width="40px"
                height="32px"
                alt="apex-logo"
              />
            </Box>
          </Link>

          <a
            href="https://www.hkssteel.com.tw/"
            target="_blank"
            rel="noreferrer"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingLeft="12px"
            >
              <img
                src={HksSteelRedLogo}
                height="34px"
                alt="hkssteel-logo"
              />
            </Box>
          </a>
        </Box>

        {/* right area */}
        {/* language switcher */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <LanguageSwicher />

          {/* close icon */}
          <Box
            className={classes.closeIcon}
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={onClose}
          >
            <img
              width={'32px'}
              height={'32px'}
              src={CloseIcon}
              alt="menu-icon"
            />
          </Box>
        </Box>
      </Box>

      {/* menu items */}
      <nav className={classes.menuItems}>
        {path.map((item, index) => {
          return (
            <MenuItem
              key={index}
              item={item}
            >
              <FormattedMessage id={item.title} />
            </MenuItem>
          );
        })}
      </nav>
    </Box>
  );
};

export default MobileHeader;