import React, {
  useEffect, useState,
} from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme, Typography, TextField, FormHelperText, FormControl, Backdrop, CircularProgress,
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getImage, } from '@/utils/tool';
import {
  FormattedMessage, useIntl,
} from 'react-intl';
import Validator, {
  validation, getMultilevelValue,
} from '@/utils/validator';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { getContact, } from '@/reducers/states';
import { contactActions, } from '@/reducers/contact.slice';

import BackgroudImage from '../assets/block2-background.jpg';
import BackgroudImageMobile from '../assets/block2-background-m.jpg';
import RectangleButton from '@/components/RectangleButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: { width: '100%', },
  backgroundContainer: {
    width: '100%',
    height: '1000px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: { height: '100%', },
  },
  contentContainer: {
    width: '100%',
    padding: '180px 150px 150px',
    display: 'flex',
    justifyContent: 'space-around',
    gap: 32,
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'center',
      gap: 190,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '60px 24px',
      alignItems: 'center',
    },
  },
  contentInfo: {
    width: '378px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: { padding: '0px 24px', },
  },
  contentForm: {
    maxWidth: '536px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: '40px 60px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1);',
    borderRadius: '20px',
    '& .MuiTextField-root': { width: '100%', },
    '& .MuiInputBase-root': { borderRadius: '10px', },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginLeft: '0px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '24px',
      marginBottom: '60px',
    },
  },
}));

const Block2 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { contact, } = useSelector(getContact);
  const {
    loading, loaded,
  } = contact;

  const [validations, _setValidations,] = useState<any>({
    name: [Validator.required,],
    email: [Validator.required, Validator.isEmail,],
    title: [Validator.required,],
    content: [Validator.required,],
  });
  const validationField: string[] = [
    'companyName',
    'name',
    'phone',
    'email',
    'title',
    'content',
  ];
  const [errorMessage, setErrorMessage,] = useState<any>({});
  const [formData, setFormData,] = useState<any>({
    companyName: '',
    jobTitle: '',
    name: '',
    phone: '',
    email: '',
    title: '',
    content: '',
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      name, value,
    } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrorMessage({
      ...errorMessage,
      [name]: '',
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    let validBol: boolean = true;
    let newErrorMessage = { ...errorMessage, };
    validationField.forEach((key: string) => {
      if (validBol && validations[key]) {
        const value = getMultilevelValue({
          formData,
          name: key,
        });

        const validate = validation({
          validations: validations[key],
          name: key,
          value,
        });
        newErrorMessage = {
          ...newErrorMessage,
          ...validate.errorMessage,
        };
        validBol = validate.validBol;
      }
    });

    setErrorMessage(newErrorMessage);
    // 驗證通過執行 submit
    if (validBol) {
      dispatch(contactActions.postContactRequest({
        name: formData.name,
        title: formData.title,
        company: formData.companyName,
        job_title: formData.jobTitle ? formData.jobTitle : '',
        email: formData.email,
        phone: formData.phone ? formData.phone : '',
        message: formData.content,
      }));
    }
  };

  // 如果表單送出成功則清空表單欄位
  useEffect(() => {
    if (loaded) {
      setFormData({
        companyName: '',
        jobTitle: '',
        name: '',
        phone: '',
        email: '',
        title: '',
        content: '',
      });
    }
  }, [loaded,]);

  return (
    <Box className={classes.container}>
      {/* loading */}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        className={classes.backgroundContainer}
        style={{ backgroundImage: `url(${isMobile ? BackgroudImageMobile : BackgroudImage})`, }}
      >
        <Box className={classes.contentContainer}>
          <Box className={classes.contentInfo}>
            <Typography
              component="h2"
              fontSize={isMobile ? '16px' : '24px'}
              fontWeight="700"
              color={theme.palette.primary.main}
            >
              <FormattedMessage id="contact.blocktwo.title" />
            </Typography>
            <Typography
              fontSize={isMobile ? '24px' : '40px'}
              fontWeight="700"
              mb={1}
            >
              <FormattedMessage id="contact.blocktwo.subtitle" />
            </Typography>
            <Typography
              mb={1}
              variant="subtitle2"
              fontWeight="700"
              color={theme.palette.secondary.dark}
              style={{ opacity: 0.85, }}
            >
              {intl.formatMessage({ id: `contact.blocktwo.address.title`, })}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="400"
              color={theme.palette.secondary.dark}
              mb={1}
              style={{ opacity: 0.85, }}
            >
              {intl.formatMessage({ id: `contact.blocktwo.address1`, })}
            </Typography>
            <Typography
              mb={2}
              variant="body2"
              fontWeight="400"
              color={theme.palette.secondary.dark}
              style={{ opacity: 0.85, }}
            >
              {intl.formatMessage({ id: `contact.blocktwo.address2`, })}
            </Typography>
            <Typography
              mb={1}
              variant="subtitle2"
              fontWeight="700"
              color={theme.palette.secondary.dark}
              style={{ opacity: 0.85, }}
            >
              {intl.formatMessage({ id: `contact.blocktwo.contact.info.title`, })}
            </Typography>
            <Typography
              mb={1}
              variant="body2"
              fontWeight="400"
              color={theme.palette.secondary.dark}
              style={{ opacity: 0.85, }}
            >
              {intl.formatMessage({ id: `contact.blocktwo.contact.info.sale_contact_person`, })}
            </Typography>
            <Typography
              mb={1}
              variant="body2"
              fontWeight="400"
              color={theme.palette.secondary.dark}
              style={{ opacity: 0.85, }}
            >
              {intl.formatMessage({ id: `contact.blocktwo.contact.info.tech_contact_person`, })}
            </Typography>
            <Typography
              mb={1}
              variant="body2"
              fontWeight="400"
              color={theme.palette.secondary.dark}
              style={{ opacity: 0.85, }}
            >
              {intl.formatMessage({ id: `contact.blocktwo.contact.info.customer_service_contact_person`, })}
            </Typography>
          </Box>
          <Box className={classes.contentForm}>
            <Box
              display="flex"
              flexWrap={isMobile ? 'wrap' : 'nowrap'}
              sx={{ gap: 2, }}
            >
              <FormControl fullWidth>
                <TextField
                  name="companyName"
                  value={formData.companyName}
                  label={<FormattedMessage id="contact.blocktwo.form.label1" />}
                  size="small"
                  autoComplete="off"
                  error={!!errorMessage['companyName']}
                  onChange={handleTextFieldChange}
                />
                {errorMessage['companyName'] && (
                  <FormHelperText>
                    <FormattedMessage id={errorMessage['companyName']} />
                  </FormHelperText>
                )}
              </FormControl>
              <TextField
                name="jobTitle"
                value={formData.jobTitle}
                label={<FormattedMessage id="contact.blocktwo.form.label2" />}
                size="small"
                autoComplete="off"
                onChange={handleTextFieldChange}
              />
            </Box>
            <Box
              display="flex"
              flexWrap={isMobile ? 'wrap' : 'nowrap'}
              sx={{ gap: 2, }}
            >
              <FormControl fullWidth>
                <TextField
                  name="name"
                  value={formData.name}
                  label={<FormattedMessage id="contact.blocktwo.form.label3" />}
                  size="small"
                  autoComplete="off"
                  error={!!errorMessage['name']}
                  onChange={handleTextFieldChange}
                />
                {errorMessage['name'] && (
                  <FormHelperText>
                    <FormattedMessage id={errorMessage['name']} />
                  </FormHelperText>
                )}
              </FormControl>
              <TextField
                name="phone"
                value={formData.phone}
                label={<FormattedMessage id="contact.blocktwo.form.label4" />}
                size="small"
                autoComplete="off"
                onChange={handleTextFieldChange}
              />
            </Box>
            <FormControl fullWidth>
              <TextField
                name="email"
                value={formData.email}
                label={<FormattedMessage id="contact.blocktwo.form.label5" />}
                size="small"
                autoComplete="off"
                error={!!errorMessage['email']}
                onChange={handleTextFieldChange}
              />
              {errorMessage['email'] && (
                <FormHelperText>
                  <FormattedMessage id={errorMessage['email']} />
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                name="title"
                value={formData.title}
                label={<FormattedMessage id="contact.blocktwo.form.label6" />}
                size="small"
                autoComplete="off"
                error={!!errorMessage['title']}
                onChange={handleTextFieldChange}
              />
              {errorMessage['title'] && (
                <FormHelperText>
                  <FormattedMessage id={errorMessage['title']} />
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                name="content"
                value={formData.content}
                label={<FormattedMessage id="contact.blocktwo.form.label7" />}
                multiline
                minRows={4}
                autoComplete="off"
                error={!!errorMessage['content']}
                onChange={handleTextFieldChange}
              />
              {errorMessage['content'] && (
                <FormHelperText>
                  <FormattedMessage id={errorMessage['content']} />
                </FormHelperText>
              )}
            </FormControl>
            <Box>
              <RectangleButton
                color="green"
                onClick={handleSubmit}
              >
                <FormattedMessage id="contact.blocktwo.form.submit" />
              </RectangleButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Block2);