import {
  NEWS_FEATURE_KEY, newsReducer,
} from './news.slice';
import {
  FINANCIAL_FEATURE_KEY, financialReducer,
} from './financial.slice';
import {
  HISTORY_FEATURE_KEY, historyReducer,
} from './history.slice';
import {
  CONTACT_FEATURE_KEY, contactReducer,
} from './contact.slice';

const reducers = {
  [NEWS_FEATURE_KEY]: newsReducer,
  [FINANCIAL_FEATURE_KEY]: financialReducer,
  [HISTORY_FEATURE_KEY]: historyReducer,
  [CONTACT_FEATURE_KEY]: contactReducer,
};
export default reducers;