import {
  put, takeLatest, call,
} from 'redux-saga/effects';
import { financialActions, } from '@/reducers/financial.slice';
import {
  getRevenues,
  getFinance,
  getAnnual,
} from '@/serivces/financialApi';
import { AxiosResponse, } from 'axios';

export function* getRevenuesSaga({ payload, }: ReturnType<typeof financialActions.getRevenuesRequest>) {
  try {
    const res: AxiosResponse = yield call(getRevenues, payload);
    yield put(financialActions.getRevenuesSuccess(res));
  } catch (error) {
    yield put(financialActions.getRevenuesFail(error));
  }
}

export function* getFinanceSaga({ payload, }: ReturnType<typeof financialActions.getFinanceRequest>) {
  try {
    const res: AxiosResponse = yield call(getFinance, payload);
    yield put(financialActions.getFinanceSuccess(res));
  } catch (error) {
    yield put(financialActions.getFinanceFail(error));
  }
}

export function* getAnnualSaga({ payload, }: ReturnType<typeof financialActions.getAnnualRequest>) {
  try {
    const res: AxiosResponse = yield call(getAnnual, payload);
    yield put(financialActions.getAnnualSuccess(res));
  } catch (error) {
    yield put(financialActions.getAnnualFail(error));
  }
}

const sagas = [
  takeLatest(financialActions.getRevenuesRequest, getRevenuesSaga),
  takeLatest(financialActions.getFinanceRequest, getFinanceSaga),
  takeLatest(financialActions.getAnnualRequest, getAnnualSaga),
];

export default sagas;