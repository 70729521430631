import React, {
  useState, useEffect, useRef, useCallback, useMemo,
} from 'react';
import { useNavigate, } from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { historyActions, } from '@/reducers/history.slice';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  Box, Theme, Typography, Button, Divider,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import moment from 'moment';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import useEmblaCarousel from 'embla-carousel-react';
import RoundedButton from '@/components/RoundedButton';
import PaginationButton from '@/components/PaginationButton';
import BackgroudImage from '../assets/Block5/background.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '56px',
    padding: '120px 0',
    [theme.breakpoints.down(800)]: {
      padding: '60px 24px',
      gap: '40px',
    },
  },
  titleContainer: {
    width: '100%',
    maxWidth: '740px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '24px',
    textAlign: 'center',
    color: theme.palette.secondary.dark,
    [theme.breakpoints.down(800)]: { maxWidth: '100%', },
  },
  historyContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '24px',
    overflow: 'hidden',
  },
  slideContainer: {
    overflow: 'hidden',
    width: '100%',
    maxWidth: '1240px',
  },
  slides: { display: 'flex', },
  slide: {
    flex: '0 0 1',
    display: 'flex',
  },
  years: {
    width: '100%',
    maxWidth: '1040px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '1000%',
      height: '1px',
      bottom: '50%',
      right: '-100%',
      backgroundColor: 'rgba(14, 35, 56, 0.25)',
    },
  },
  yearButton: { zIndex: '1', },
  card: {
    width: '400px',
    minHeight: '286px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '16px',
    flexDirection: 'column',
    padding: '0 40px',
    borderRight: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down(800)]: {
      padding: '0 24px',
      minHeight: 'auto',
    },
    [theme.breakpoints.down(400)]: { width: '100vw', },
  },
  slideButton: {
    display: 'flex',
    gap: '24px',
  },
}));

const getLanguageHistoryData = (data: any[], language: string) => {
  const languageData = data.find((item: any) => {
    return item.languages_code.toLowerCase().split('-').join('') === language.split('_').join('');
  });
  if (!!languageData) {
    return languageData;
  } else {
    return data[0];
  }
};

const Card = ({
  date, title, body,
}: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <Box className={classes.card}>
      <Typography
        fontSize={'14px'}
        fontWeight={'bold'}
        color={theme.palette.primary.main}
      >
        {date}
      </Typography>
      <Typography
        fontSize={isMobile ? '16px' : '24px'}
        fontWeight={'bold'}
        color={theme.palette.secondary.dark}
      >
        {title}
      </Typography>
      <Typography
        fontSize={'16px'}
        color={theme.palette.secondary.dark}
        whiteSpace="pre-line"
      >
        {body}
      </Typography>
    </Box>
  );
};

const BlockFive = ({ id, }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { locale, } = useSelector(getGlobal);
  const { history, } = useSelector(getHistory);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const [currentSlideIndex, setCurrentSlideIndex,] = useState<any>(0);
  const [prevBtnEnabled, setPrevBtnEnabled,] = useState<any>(false);
  const [nextBtnEnabled, setNextBtnEnabled,] = useState<any>(false);
  const [emblaRef, emblaApi,] = useEmblaCarousel({ loop: false, });
  const {
    data, loaded, loading,
  } = history;
  const getYears = () => {
    const result: string[] = [];
    data.forEach((item, index) => {
      if (!result.includes(item.year)) {
        result.push(item.year);
      }
    });
    return result;
  };

  const scrollPrev = () => {
    emblaApi && emblaApi.scrollPrev();
  };

  const scrollNext = () => {
    emblaApi && emblaApi.scrollNext();
  };

  const scrollTo = (index: any) => {
    emblaApi && emblaApi.scrollTo(index);
  };

  // 按下年份tag時，去尋找陣列中第一個符合年份的index
  const findTargetYearCard = (year: string) => {
    const result = data.findIndex((item, index) => {
      return item.year === year;
    });
    setCurrentSlideIndex(result);
  };

  useEffect(() => {
    const onSlideChange = () => {
      setCurrentSlideIndex(emblaApi?.selectedScrollSnap());
      setPrevBtnEnabled(emblaApi?.canScrollPrev());
      setNextBtnEnabled(emblaApi?.canScrollNext());
    };
    emblaApi?.on('select', onSlideChange);
    return () => {
      emblaApi?.off('select', onSlideChange);
    };
  }, [emblaApi,]);

  useEffect(() => {
    scrollTo(currentSlideIndex);
  }, [currentSlideIndex,]);

  return (
    <div
      id={id}
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroudImage})`, }}
    >
      <ScrollAnimation
        className={classes.titleContainer}
        animateIn="fadeInDown"
      >
        <Typography
          fontSize={isMobile ? '24px' : '40px'}
          fontWeight={'bold'}
          color={theme.palette.secondary.dark}
        >
          <FormattedMessage id={'about.blockfive.title'} />
        </Typography>
        <Typography
          fontSize={isMobile ? '14px' : '16px'}
          color={theme.palette.secondary.dark}
          whiteSpace="pre-line"
        >
          <FormattedMessage id={'about.blockfive.description'} />
        </Typography>
      </ScrollAnimation>

      {/* 年份按鈕 */}
      <Box className={classes.historyContainer}>
        <Box className={classes.years}>
          {getYears().map((item: any, index: any) => {
            return (
              <Box
                key={index}
                className={classes.yearButton}
              >
                <RoundedButton
                  variant="contained"
                  selected={item === data[currentSlideIndex].year}
                  onClick={() => {
                    findTargetYearCard(item);
                  }}
                >
                  {item}
                </RoundedButton>
              </Box>
            );
          })}
        </Box>

        {/* 年份卡片 */}
        {loaded && (
          <Box
            className={classes.slideContainer}
            ref={emblaRef}
          >
            <Box className={classes.slides}>
              {data.map((item: any, index: any) => {
                const languageData = getLanguageHistoryData(item.translations, locale);
                const date = `${item.year}.${moment(`${item.month}`).format('MM')}`;
                return (
                  <Box
                    key={index}
                    className={classes.slide}
                  >
                    <Card
                      key={index}
                      date={date}
                      title={languageData.title}
                      body={languageData.body}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}

        <Box className={classes.slideButton}>
          <PaginationButton
            color="green"
            reverse="180"
            onClick={scrollPrev}
            disabled={!emblaApi?.canScrollPrev()}
          />
          <PaginationButton
            color="green"
            onClick={scrollNext}
            disabled={!emblaApi?.canScrollNext()}
          />
        </Box>
      </Box>
    </div>
  );
};

export default BlockFive;