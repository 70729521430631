import { createSlice, } from '@reduxjs/toolkit';

export const HISTORY_FEATURE_KEY = 'history';

/*
 * Update these interfaces according to your requirements.
 */

export interface HistoryState {
  history: {
    loading: boolean;
    loaded: boolean;
    data: any[];
  };
}

export const initialHistoryState: HistoryState = { history: {
  loading: false,
  loaded: false,
  data: [],
}, };

export const historySlice = createSlice({
  name: HISTORY_FEATURE_KEY,
  initialState: initialHistoryState,
  reducers: {
    getCompanyHistoryRequest(state, action) {
      state.history = {
        ...state.history,
        loading: true,
        loaded: false,
      };
    },
    getCompanyHistorySuccess(state, action) {
      state.history = {
        ...state.history,
        loading: false,
        loaded: true,
        data: action.payload.data,
      };
    },
    getCompanyHistoryFail(state, action) {
      state.history = {
        ...state.history,
        loading: false,
        loaded: false,
      };
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const historyReducer = historySlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const historyActions = historySlice.actions;