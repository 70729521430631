import React, {
  useState, useEffect, useRef,
} from 'react';
import {
  useNavigate, Link,
} from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  Box, Theme, Typography, Button, Divider,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import ScrollAnimation from 'react-animate-on-scroll';
import Path from '../../../plugins/path.json';
import BackgroudImage from '../assets/Block2/background.png';
import zhLogo from '../assets/Block2/logo-zh.png';
import enLogo from '../assets/Block2/logo-en.png';
import TextButton from '@/components/TextButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    padding: '120px 24px',
    [theme.breakpoints.down(800)]: { padding: '0px 24px 60px', },
  },
  anchorBar: {
    display: 'flex',
    alignItems: 'center',
    height: '68px',
    padding: '24px',
    marginBottom: '60px',
    fontSize: '14px',
    width: '100%',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': { display: 'none', },
  },
  titleContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '40px',
  },
  title1: { color: theme.palette.secondary.dark, },
  description1: {
    width: '100%',
    maxWidth: '720px',
    color: theme.palette.secondary.dark,
    textAlign: 'center',
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  contentDescription: {
    width: '100%',
    maxWidth: '600px',
    textAlign: 'center',
    padding: '12px 0px',
    color: theme.palette.primary.main,
    borderTop: '1px solid rgba(209, 210, 212, 0.45)',
    '&:last-child': { borderBottom: '1px solid rgba(209, 210, 212, 0.45)', },
  },
  brand: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '133px',
    [theme.breakpoints.down(1000)]: {
      flexDirection: 'column',
      gap: '32px',
    },
    '& img': {
      width: '447px',
      height: 'auto',
      [theme.breakpoints.down(800)]: { width: '100%', },
    },
  },
  brandDescription: {
    width: '100%',
    maxWidth: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down(800)]: {
      gap: '16px',
      maxWidth: '100%',
    },
  },
}));

const aboutPageAnchors = Path.find((item, index) => item.path === '/about');

const BlockTwo = ({ id, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const isShowAnchor = useMediaQuery(theme.breakpoints.down('md'));

  const anchorRef = useRef<any>(null);
  const [anchorCenter, setAnchorCenter,] = useState(false);

  // 判斷 anchor bar 是否需要置中（如果需要 scroll 的話靠左，沒有的話置中）
  useEffect(() => {
    if (!!anchorRef.current) {
      const anchorContainerWidth = anchorRef.current.clientWidth;
      let anchorItemsTotalWidth = 0;
      for (const item of anchorRef.current.children) {
        anchorItemsTotalWidth += item.clientWidth;
      }
      if (anchorContainerWidth > anchorItemsTotalWidth) {
        setAnchorCenter(true);
      }
    }
  }, []);

  return (
    <div
      id={id}
      className={classes.container}
      style={{ backgroundImage: `url(${BackgroudImage})`, }}
    >
      {isShowAnchor && (
        <Box
          ref={anchorRef}
          className={classes.anchorBar}
          sx={{ justifyContent: `${anchorCenter ? 'center' : 'flex-start'}`, }}
        >
          {aboutPageAnchors?.menu
            ?.filter((item) => !!item.anchor)
            .map((item: any, index: any) => {
              // “關於新光鋼集團” 為外部連結，不放在白色錨點列表
              return (
                <Link
                  key={index}
                  to={item.anchor}
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.secondary.dark,
                    borderLeft: index !== 0 ? `2px solid ${theme.palette.primary.main}` : '',
                    padding: '0 24px',
                  }}
                >
                  <TextButton>
                    <FormattedMessage id={item.title} />
                  </TextButton>
                </Link>
              );
            })}
        </Box>
      )}

      <ScrollAnimation
        className={classes.titleContainer}
        animateIn="fadeIn"
      >
        <Typography
          className={classes.title1}
          fontSize={isMobile ? '24px' : '40px'}
          fontWeight={'bold'}
          marginBottom={'24px'}
        >
          WHO WE ARE
        </Typography>
        <Typography
          className={classes.description1}
          fontSize={isMobile ? '14px' : '16px'}
        >
          <FormattedMessage id={'about.blocktwo.description.1'} />
        </Typography>
      </ScrollAnimation>

      <Box className={classes.content}>
        <Typography
          className={classes.contentDescription}
          fontSize={isMobile ? '14px' : '16px'}
        >
          <FormattedMessage id={'about.blocktwo.content.1'} />
        </Typography>
        <Typography
          className={classes.contentDescription}
          fontSize={isMobile ? '14px' : '16px'}
        >
          <FormattedMessage id={'about.blocktwo.content.2'} />
        </Typography>
        <Typography
          className={classes.contentDescription}
          fontSize={isMobile ? '14px' : '16px'}
        >
          <FormattedMessage id={'about.blocktwo.content.3'} />
        </Typography>
      </Box>

      <Box className={classes.brand}>
        <img
          src={locale === 'en_us' ? enLogo : zhLogo}
          alt="apex-logo-brand"
        />
        <Box className={classes.brandDescription}>
          <Typography
            color={theme.palette.secondary.dark}
            fontSize={isMobile ? '16px' : '24px'}
            fontWeight={'bold'}
          >
            <FormattedMessage id={'about.blocktwo.brand.title'} />
          </Typography>
          <Typography
            color={theme.palette.secondary.dark}
            fontSize={isMobile ? '14px' : '16px'}
            fontWeight={'bold'}
          >
            <FormattedMessage id={'about.blocktwo.brand.description.1'} />
          </Typography>
          <Typography
            color={theme.palette.secondary.dark}
            fontSize={isMobile ? '14px' : '16px'}
          >
            <FormattedMessage id={'about.blocktwo.brand.description.2'} />
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default BlockTwo;