import React, {
  useState, type ComponentProps, useCallback, type FC, type ReactNode, Fragment,
} from 'react';
import { useNavigate, } from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  Box, Theme, styled, Typography, Button,
} from '@mui/material';
import {
  FormattedMessage, useIntl,
} from 'react-intl';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import { getGlobal, } from '@/reducers/states';
import {
  GoogleMap, MarkerF, useJsApiLoader, InfoWindowF,
} from '@react-google-maps/api';
import { getImage, } from '@/utils/tool';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '900px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // 重置 InfoWindow 的樣式
    '& .gm-style-iw.gm-style-iw-c': { padding: '0px !important', },
    '& .gm-style-iw-d': { overflow: 'auto !important', },
    '& .gm-style-iw-tc': { display: 'none !important', },
    // 右上角的 x icon
    '& .gm-ui-hover-effect': { display: 'none !important', },
    '& .gm-style-iw': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  // infoWindow 樣式
  infoWindowContainer: {
    padding: 16, background: '#fff',
  },
}));

const containerStyle = {
  width: '100%',
  height: '800px',
};

const center = {
  lat: 24.029325,
  lng: 120.049735,
};

type Places = {
  id?: number;
  info: {
    title: ReactNode
    synergy?: ReactNode
    product?: ReactNode
  };
  location: {
    lat: number;
    lng: number;
  };
};

const places: Places[] = [
  {
    id: 1,
    info: {
      title: <FormattedMessage id="service.blocksix.place1.info.title" />,
      synergy: <FormattedMessage id="service.blocksix.place1.info.synergy" />,
      product: <FormattedMessage id="service.blocksix.place1.info.product" />,
    },
    location: {
      lat: 24.730388,
      lng: 120.782234,
    },
  },
  {
    id: 2,
    info: {
      title: <FormattedMessage id="service.blocksix.place2.info.title" />,
      synergy: <FormattedMessage id="service.blocksix.place2.info.synergy" />,
      product: <FormattedMessage id="service.blocksix.place2.info.product" />,
    },
    location: {
      lat: 24.173248,
      lng: 119.683348,
    },
  },
  {
    id: 3,
    info: {
      title: <FormattedMessage id="service.blocksix.place3.info.title" />,
      synergy: <FormattedMessage id="service.blocksix.place3.info.synergy" />,
      product: <FormattedMessage id="service.blocksix.place3.info.product" />,
    },
    location: {
      lat: 24.012910,
      lng: 119.799506,
    },
  },
  {
    id: 4,
    info: {
      title: <FormattedMessage id="service.blocksix.place4.info.title" />,
      synergy: <FormattedMessage id="service.blocksix.place4.info.synergy" />,
      product: <FormattedMessage id="service.blocksix.place4.info.product" />,
    },
    location: {
      lat: 23.560784,
      lng: 119.990550,
    },
  },
  {
    id: 5,
    info: {
      title: <FormattedMessage id="service.blocksix.place5.info.title" />,
      synergy: <FormattedMessage id="service.blocksix.place5.info.synergy" />,
      product: <FormattedMessage id="service.blocksix.place5.info.product" />,
    },
    location: {
      lat: 23.896962,
      lng: 120.111847,
    },
  },
  {
    id: 6,
    info: {
      title: <FormattedMessage id="service.blocksix.place6.info.title" />,
      synergy: <FormattedMessage id="service.blocksix.place6.info.synergy" />,
      product: <FormattedMessage id="service.blocksix.place6.info.product" />,
    },
    location: {
      lat: 23.896962,
      lng: 120.111847,
    },
  },
  {
    id: 7,
    info: {
      title: <FormattedMessage id="service.blocksix.place7.info.title" />,
      synergy: <FormattedMessage id="service.blocksix.place7.info.synergy" />,
      product: <FormattedMessage id="service.blocksix.place7.info.product" />,
    },
    location: {
      lat: 24.029325,
      lng: 120.049735,
    },
  },
  {
    id: 8,
    info: {
      title: <FormattedMessage id="service.blocksix.place8.info.title" />,
      synergy: <FormattedMessage id="service.blocksix.place8.info.synergy" />,
      product: <FormattedMessage id="service.blocksix.place8.info.product" />,
    },
    location: {
      lat: 24.075922,
      lng: 120.161418,
    },
  },
];

const InfoCard: FC<{ places: Places, onClose?: () => void }> = ({
  places, onClose,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return(
    <InfoWindowF
      options={{ pixelOffset: isMobile ? new google.maps.Size(0, -60) : new google.maps.Size(-185, 60), }}
      position={{
        lat: places.location.lat,
        lng: places.location.lng,
      }}
      onCloseClick={onClose}
    >
      <Box sx={{
        padding: '24px', background: '#fff', maxWidth: '300px',
      }}>
        <Typography
          sx={{
            marginBottom: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '33px',
            color: '#3273B6',
          }}>{places.info.title}</Typography>
        <Typography
          sx={{
            marginBottom: '4px',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
            borderBottom: '1px solid rgba(209, 210, 212, 0.45)',
            paddingBottom: '4px',
          }}>{places.info.synergy}</Typography>
        <Typography
          sx={{
            marginBottom: '8px',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
          }}>{places.info.product}</Typography>
      </Box>
    </InfoWindowF>
  );
};

const BlockSix: FC<ComponentProps<'div'>> = (props) => {
  const classes = useStyles();

  const { isLoaded, } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? window.Config.GOOGLE_API_KEY ?? '',
  });

  const [map, setMap,] = useState<google.maps.Map | null>(null);
  const [icon, setIcon,] = useState<string>(getImage('service/assets/Block6/flag.png'));
  const [selected, setSelected,] = useState<Places | null>({
    id: 4,
    info: {
      title: <FormattedMessage id="service.blocksix.place4.info.title" />,
      synergy: <FormattedMessage id="service.blocksix.place4.info.synergy" />,
      product: <FormattedMessage id="service.blocksix.place4.info.product" />,
    },
    location: {
      lat: 23.560784,
      lng: 119.990550,
    },
  });
  const [zoom, setZoom,] = useState(0);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    map.setZoom(9);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const handleSelected = useCallback(({
    place, icon,
  }: { place: Places, icon: string }) => {
    setSelected(place);
    setIcon(icon);
  }, []);

  const handleClose = useCallback(() => {
    setSelected(null);
    setIcon(getImage('service/assets/Block6/flag.png'));
  }, []);

  const { ref, } = useInfiniteScroll({
    hasMore: !!map,
    onLoadMore: () => setZoom(9),
  });

  return (
    <div
      ref={ref}
      className={classes.container}
      {...props}
    >
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDrag={() => setSelected(null)}
          onClick={() => setSelected(null)}
          options={{ styles: [
            {
              'elementType': 'geometry',
              'stylers': [{ 'color': '#E7E8EC', },],
            },
            {
              'elementType': 'labels.icon',
              'stylers': [{ 'visibility': 'off', },],
            },
            {
              'elementType': 'labels.text.fill',
              'stylers': [{ 'color': '#616161', },],
            },
            {
              'elementType': 'labels.text.stroke',
              'stylers': [{ 'color': '#F5F5F5', },],
            },
            {
              'featureType': 'administrative.land_parcel',
              'elementType': 'labels.text.fill',
              'stylers': [{ 'color': '#BDBDBD', },],
            },
            {
              'featureType': 'poi',
              'elementType': 'geometry',
              'stylers': [{ 'color': '#EEEEEE', },],
            },
            {
              'featureType': 'poi',
              'elementType': 'labels.text.fill',
              'stylers': [{ 'color': '#757575', },],
            },
            {
              'featureType': 'poi.park',
              'elementType': 'geometry',
              'stylers': [{ 'color': '#E5E5E5', },],
            },
            {
              'featureType': 'poi.park',
              'elementType': 'labels.text.fill',
              'stylers': [{ 'color': '#9E9E9E', },],
            },
            {
              'featureType': 'road',
              'elementType': 'geometry',
              'stylers': [{ 'color': '#FFFFFF', },],
            },
            {
              'featureType': 'road.arterial',
              'stylers': [{ 'visibility': 'off', },],
            },
            {
              'featureType': 'road.arterial',
              'elementType': 'labels.text.fill',
              'stylers': [{ 'color': '#757575', },],
            },
            {
              'featureType': 'road.highway',
              'stylers': [{ 'visibility': 'off', },],
            },
            {
              'featureType': 'road.highway',
              'elementType': 'geometry',
              'stylers': [{ 'color': '#DADADA', },],
            },
            {
              'featureType': 'road.highway',
              'elementType': 'labels.text.fill',
              'stylers': [{ 'color': '#616161', },],
            },
            {
              'featureType': 'road.local',
              'elementType': 'labels.text.fill',
              'stylers': [{ 'color': '#9E9E9E', },],
            },
            {
              'featureType': 'transit.line',
              'elementType': 'geometry',
              'stylers': [{ 'color': '#E5E5E5', },],
            },
            {
              'featureType': 'transit.station',
              'elementType': 'geometry',
              'stylers': [{ 'color': '#EEEEEE', },],
            },
            {
              'featureType': 'water',
              'elementType': 'geometry',
              'stylers': [{ 'color': '#CED5E1', },],
            },
            {
              'featureType': 'water',
              'elementType': 'labels.text.fill',
              'stylers': [{ 'color': '#9E9E9E', },],
            },
          ], }}
        >
          <>
            {places.map((marker) => (
              <Fragment key={marker.id}>
                <MarkerF
                  icon={icon}
                  position={{
                    lat: marker.location.lat,
                    lng: marker.location.lng,
                  }}
                  onClick={() => handleSelected({
                    place: marker,
                    icon: getImage('service/assets/Block6/focus-flag.png'),
                  })}
                />
                {
                  // MarkerF 的 icon 無法動態更新，所以用另一個 MarkerF 來做 focus 的效果
                  selected?.id === marker.id && (
                    <MarkerF
                      icon={getImage('service/assets/Block6/focus-flag.png')}
                      position={{
                        lat: marker.location.lat,
                        lng: marker.location.lng,
                      }}
                      onClick={() => {
                        setSelected(marker);
                      }}
                    />
                  )
                }
              </Fragment>
            ))}

            {selected && <InfoCard places={selected} onClose={handleClose} />}
          </>
        </GoogleMap>
      )}
    </div>
  );
};

export default BlockSix;