import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography,
} from '@mui/material';
import { makeStyles, } from '@mui/styles';
import { FormattedMessage, } from 'react-intl';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    maxWidth: '95vw',
    border: 'none',
  },
  table: {
    minWidth: '940px',
    [theme.breakpoints.down(800)]: { minWidth: 'auto', },
    '& thead': {
      backgroundColor: theme.palette.secondary.light,
      '& th': {
        color: theme.palette.common.white,
        fontWeight: '700',
        border: '2px solid white',
      },
    },
    '& td': {
      background: '#EEEEEE',
      border: '2px solid white',
      color: theme.palette.secondary.dark,
      opacity: '0.85',
      maxWidth: '650px',
      minWidth: '180px',
    },
  },
}));

interface TableProps {
  headers: Array<string>;
  rows: string[][];
  focusItems?: string[];
}

export default function ContentTable({
  headers, rows, focusItems,
}: TableProps) {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <TableContainer
      component={Paper}
      className={classes.tableContainer}
      sx={{ boxShadow: 'none', }}
    >
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>
                <Typography
                  fontWeight={700}
                  fontSize={isMobile ? '14px' : '16px'}
                >
                  <FormattedMessage id={header} />
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((subRow: string[], index) => {
            const isfocusItem = !!focusItems ? subRow.filter((item) => focusItems.includes(item)).length > 0 : false;
            return(
              <TableRow key={index}>
                {subRow.map((item: string, index: number) => (
                  <TableCell key={index} >
                    <Typography
                      fontSize={isMobile ? '14px' : '16px'}
                      fontWeight={isfocusItem ? '700' : '400'}
                      whiteSpace="pre-line"
                    >
                      <FormattedMessage id={item} />
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}