import React from 'react';
import { Box, } from '@mui/material';
import { Helmet, } from 'react-helmet';
import { useIntl, } from 'react-intl';
import FinancialBlock1 from './component/FinancialBlock1';
import FinancialBlock2 from './component/FinancialBlock2';
import FinancialBlock3 from './component/FinancialBlock3';
import FinancialBlock4 from './component/FinancialBlock4';
import ScrollHandler from '@/components/ScrollHandler';

const Financial = () => {
  const intl = useIntl();

  // meta tag文案
  const pageTitle = intl.formatMessage({ id: 'investor.governance.page.title', });
  const ogTagTitle = intl.formatMessage({ id: 'investor.governance.ogtag.title', });
  const ogTagDescription = intl.formatMessage({ id: 'investor.governance.ogtag.description', });

  return (
    <Box>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={ogTagDescription}
        ></meta>
        <meta
          property="og:title"
          content={ogTagTitle}
        />
        <meta
          property="og:description"
          content={ogTagDescription}
        />
      </Helmet>
      <ScrollHandler />
      <FinancialBlock1 />
      <FinancialBlock2 />
      <FinancialBlock3 />
      <FinancialBlock4 />
    </Box>
  );
};

export default React.memo(Financial);