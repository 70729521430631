import React, {
  useState, useEffect, useMemo,
} from 'react';
import {
  useSelector, useDispatch,
} from 'react-redux';
import {
  getNews, getGlobal,
} from '@/reducers/states';

const StructuredData = () => {
  const { locale, } = useSelector(getGlobal);
  const { newsContent, } = useSelector(getNews);

  const getCurrentLocale = () => {
    const language = locale.split('_')[0] || '';
    const region = locale.split('_')[1] || '';

    return `${language}-${region.toLocaleUpperCase()}`;
  };

  const getArticleContentByLocale = (locale: string) => {
    const result = newsContent.data.translations.filter((item: any) => item.languages_id.code === locale);

    return result.length > 0 ? result[0] : null;
  };

  const data = useMemo(() => {
    if (newsContent.loaded) {
      return {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        headline: getArticleContentByLocale(getCurrentLocale())?.title || '',
        datePublished:
          String(new Date(`${newsContent.data.date_published}`)) !== 'Invalid Date'
            ? `${new Date(`${newsContent.data.date_published}`).toISOString()}`
            : '',
        description: getArticleContentByLocale(getCurrentLocale())?.title || '',
        url: `${window.Config.HOST}/news/${newsContent.data.id}`,
        keywords: newsContent.data.tags?.length > 0 ? newsContent.data.tags?.map((item: any) => item.news_tags_id.name) : [],
        author: {
          '@type': 'Organization',
          name: getArticleContentByLocale(getCurrentLocale())?.publisher || '',
          url: window.Config.HOST,
        },
        image: `${window.Config.API_HOST}/assets/${newsContent.data.header_image.id}`,
      };
    }
  }, [newsContent.data.id,]);

  return <script type="application/ld+json">{JSON.stringify(data)}</script>;
};

export default React.memo(StructuredData);