import React from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme, Typography,
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import BackgroudImage from '../assets/block1-background.png';
import BackgroudImageMobile from '../assets/block1-background-m.png';
import { FormattedMessage, } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '400px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    paddingTop: '72px',
    [theme.breakpoints.down('md')]: { height: '300px', },
  },
  titleContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '0 110px',
    top: 'calc(50% + 36px)',
    transform: 'translate(0, -50%)',
    [theme.breakpoints.down('md')]: { padding: '0 24px', },
  },
  titleBox: {
    width: 'fit-content',
    borderRadius: '10px',
    backgroundColor: 'rgba(133, 193, 31, 0.75);',
    padding: '8px 32px',
    marginBottom: '20px',
  },
}));

const BlockOne = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      className={classes.container}
      style={{ backgroundImage: `url(${isMobile ? BackgroudImageMobile : BackgroudImage})`, }}
    >
      <Box className={classes.titleContainer}>
        <Box className={classes.titleBox}>
          <Typography
            fontWeight="bold"
            color="white"
          >
            <FormattedMessage id={'contact.blockone.tag'} />
          </Typography>
        </Box>
        <ScrollAnimation className="fadeInDown">
          <Typography
            fontWeight="bold"
            color="white"
            fontSize={isMobile ? '40px' : '60px'}
          >
            <FormattedMessage id="contact.blockone.title" />
          </Typography>
        </ScrollAnimation>
      </Box>
    </Box>
  );
};

export default React.memo(BlockOne);