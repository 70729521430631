import React, {
  useState, useEffect, useRef,
} from 'react';
import { useNavigate, } from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { getGlobal, } from '@/reducers/states';
import {
  Box, Theme, Typography, Tooltip, styled, TooltipProps, tooltipClasses,
} from '@mui/material';
import clsx from 'clsx';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import ISOEN1090 from '../assets/Block5/ISO-EN1090.png';
import ISO3834 from '../assets/Block5/ISO-3834.png';
import ISO14001 from '../assets/Block5/ISO-14001.png';
import ISO50001 from '../assets/Block5/ISO-50001.png';
import ISO45001 from '../assets/Block5/ISO-45001.png';
import ISO9001 from '../assets/Block5/ISO-9001.png';
import ISOBackgrund from '../assets/Block5/ISO-bg.png';
import ISOLittleBackgrund from '../assets/Block5/ISO-bg-little.png';

import { useInView, } from 'react-intersection-observer';
import Lottie from 'lottie-react';
import lottieSourceTW from '../assets/Block5/lottie/data_zh_tw.json';
import lottieSourceUS from '../assets/Block5/lottie/data_en_us.json';

const ISOTooltip = styled(({
  className, ...props
}: TooltipProps) => {
  const theme = useTheme();
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1200));
  const [open, setOpen,] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return isLittleDesktop ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClick={handleTooltipOpen}
        open={open}
        {...props}
        classes={{ popper: className, }}
      />
    </ClickAwayListener>
  ) : (
    <Tooltip
      {...props}
      classes={{ popper: className, }}
    />
  );
})(({ theme, }) => ({ [`& .${tooltipClasses.tooltip}`]: {
  backgroundColor: theme.palette.secondary.light,
  color: 'white',
  maxWidth: 396,
  fontSize: '16px',
  borderRadius: '10px',
  padding: '16px 32px',
  [theme.breakpoints.down(1200)]: { fontSize: 14, },
  [theme.breakpoints.down(400)]: { maxWidth: 'calc(100vw - 48px)', },
}, }));

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '60px',
    backgroundImage: 'linear-gradient(180deg, rgba(50, 115, 182, 0.1) 0%, rgba(133, 193, 31, 0) 53.12%)',
    padding: '100px 170px 200px 170px',
  },
  titleContainer: {
    width: '650px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '24px',
  },
  title: { color: theme.palette.secondary.dark, },
  description: { color: theme.palette.secondary.dark, },
  imgsContainer: {
    width: '1100px',
    height: '1111px',
    paddingTop: '150px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '200px',
  },
  verticalCenter: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  isoArea1: {
    display: 'flex',
    flexDirection: 'row',
    gap: '200px',
    visibility: 'hidden',
    opacity: 0,
    transition: 'all .5s',
  },
  isoArea2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '476px',
    visibility: 'hidden',
    opacity: 0,
    transition: 'all .5s',
    paddingTop: '8rem',
  },
  isoArea3: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    visibility: 'hidden',
    opacity: 0,
    transition: 'all .5s',
  },
  showEl: {
    visibility: 'visible',
    opacity: 1,
  },
  isoAreaButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: '8px',
  },
  isoCard: {
    width: '300px',
    height: '110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '8px',
    border: '1px solid #3273B6',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  isoDescription: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    userSelect: 'none',
  },
  isoImg: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [theme.breakpoints.down(1200)]: {
    container: {
      padding: '100px 24px',
      gap: '0px',
    },
    titleContainer: { width: '90%', },
    imgsContainer: {
      width: '100%',
      height: '1260px',
      justifyContent: 'flex-start',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      paddingTop: '0',
      gap: '0px',
    },
    isoArea1: {
      width: '100%',
      height: '404px',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '16px',
      cursor: 'pointer',
    },
    isoArea2: {
      width: '100%',
      height: '404px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '60px',
      cursor: 'pointer',
      paddingTop: '0',
      marginTop: '-3rem',
    },
    isoArea3: {
      display: 'flex',
      marginTop: '200px',
      cursor: 'pointer',
    },
    isoCard: {
      width: '250px',
      height: '80px',
    },
  },
}));

const BlockFive = ({ id, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1200));
  const { locale, } = useSelector(getGlobal);

  const lottie1Ref = useRef<any>(null);
  const [lottie1AreaRef, lottie1AreaRefInView,] = useInView();
  const [lottieCompelete, setLottieComplete,] = useState(false);
  const [didMount, setDidMount,] = useState(false);

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (lottie1AreaRefInView) {
      lottie1Ref.current.stop();
      lottie1Ref.current.play();
    } else {
      setLottieComplete(false);
    }
  }, [lottie1AreaRefInView,]);

  const handleChangeSource = () => {
    if (didMount) {
      setLottieComplete(false);
      lottie1Ref.current.stop();
      lottie1Ref.current.play();
    }
  };

  useEffect(() => {
    if (isLittleDesktop) {
      setLottieComplete(true);
    }
  }, [isLittleDesktop,]);

  const handleComplete = () => {
    setLottieComplete(true);
  };

  return (
    <div
      id={id}
      className={classes.container}
    >
      <ScrollAnimation
        className={classes.titleContainer}
        animateIn="fadeInDown"
      >
        <Typography
          className={classes.title}
          fontSize={isLittleDesktop ? '24px' : '40px'}
          fontWeight={'bold'}
        >
          <FormattedMessage id={'service.blockfive.title'} />
        </Typography>
        <Typography
          className={classes.description}
          fontSize={isLittleDesktop ? '14px' : '16px'}
        >
          <FormattedMessage id={'service.blockfive.description'} />
        </Typography>
      </ScrollAnimation>
      <Box
        className={isLittleDesktop ? classes.imgsContainer : undefined}
        style={{
          backgroundImage: isLittleDesktop ? `url(${ISOLittleBackgrund})` : undefined,
          position: 'relative',
        }}
      >
        <div
          ref={lottie1AreaRef}
          style={{
            display: isLittleDesktop ? 'none' : 'block',
            width: '1100px',
            height: '1111px',
          }}
        >
          <Lottie
            onComplete={handleComplete}
            onLoadedImages={handleChangeSource}
            lottieRef={lottie1Ref}
            animationData={locale === 'zh_tw' ? lottieSourceTW : lottieSourceUS}
            autoplay={false}
            loop={false}
          />
        </div>
        <Box className={clsx(classes.imgsContainer, { [classes.verticalCenter]: !isLittleDesktop, })}>
          <Box className={clsx(classes.isoArea1, { [classes.showEl]: lottieCompelete, })}>
            {isLittleDesktop && (
              <Typography
                fontSize={'16px'}
                fontWeight={'bold'}
              >
                <FormattedMessage id={'service.blockfive.iso_area_title.0'} />
              </Typography>
            )}
            <ISOTooltip
              enterTouchDelay={0}
              leaveTouchDelay={100000000}
              title={<FormattedMessage id={'service.blockfive.iso_en1090.description'} />}
            >
              <Box>
                <Box className={classes.isoCard}>
                  <Typography
                    className={classes.isoDescription}
                    fontSize={isLittleDesktop ? '12px' : '14px'}
                    lineHeight={1.1}
                    borderRight="1px solid #3273B6"
                  >
                    <FormattedMessage id={'service.blockfive.iso_en1090.title'} />
                  </Typography>
                  <Box className={classes.isoImg}>
                    <img
                      height="60px"
                      src={ISOEN1090}
                      alt={'EN1090 鋼 (鋁) 結構品質標準'}
                    />
                  </Box>
                </Box>
                {isLittleDesktop && (
                  <Typography
                    className={classes.isoAreaButton}
                    fontSize={'14px'}
                    color={theme.palette.secondary.light}
                  >
                    <FormattedMessage id={'service.blockfive.iso_area_button'} />
                  </Typography>
                )}
              </Box>
            </ISOTooltip>
            <ISOTooltip title={<FormattedMessage id={'service.blockfive.iso_3834.description'} />}>
              <Box>
                <Box className={classes.isoCard}>
                  <Box className={classes.isoImg}>
                    <img
                      height="60px"
                      src={ISO3834}
                      alt={'ISO 3834 金屬材料熔融銲接品質管理系統'}
                    />
                  </Box>
                  <Typography
                    className={classes.isoDescription}
                    fontSize={isLittleDesktop ? '12px' : '14px'}
                    lineHeight={1.1}
                    borderLeft="1px solid #3273B6"
                  >
                    <FormattedMessage id={'service.blockfive.iso_3834.title'} />
                  </Typography>
                </Box>
                {isLittleDesktop && (
                  <Typography
                    className={classes.isoAreaButton}
                    fontSize={'14px'}
                    color={theme.palette.secondary.light}
                  >
                    <FormattedMessage id={'service.blockfive.iso_area_button'} />
                  </Typography>
                )}
              </Box>
            </ISOTooltip>
          </Box>
          <Box className={clsx(classes.isoArea2, { [classes.showEl]: lottieCompelete, })}>
            <Box>
              <ISOTooltip title={<FormattedMessage id={'service.blockfive.iso_14001.description'} />}>
                <Box>
                  {isLittleDesktop && (
                    <Typography
                      style={{
                        textAlign: 'center',
                        paddingBottom: '16px',
                      }}
                      fontSize={'16px'}
                      fontWeight={'bold'}
                    >
                      <FormattedMessage id={'service.blockfive.iso_area_title.1'} />
                    </Typography>
                  )}
                  <Box className={classes.isoCard}>
                    <Typography
                      className={classes.isoDescription}
                      fontSize={isLittleDesktop ? '12px' : '14px'}
                      lineHeight={1.1}
                      borderRight="1px solid #3273B6"
                    >
                      <FormattedMessage id={'service.blockfive.iso_14001.title'} />
                    </Typography>
                    <Box className={classes.isoImg}>
                      <img
                        height="60px"
                        src={ISO14001}
                        alt={'ISO 14001 環境管理系統 (EMS)'}
                      />
                    </Box>
                  </Box>
                  {isLittleDesktop && (
                    <Typography
                      className={classes.isoAreaButton}
                      fontSize={'14px'}
                      color={theme.palette.secondary.light}
                    >
                      <FormattedMessage id={'service.blockfive.iso_area_button'} />
                    </Typography>
                  )}
                </Box>
              </ISOTooltip>
              <ISOTooltip title={<FormattedMessage id={'service.blockfive.iso_50001.description'} />}>
                <Box mt={2}>
                  <Box className={classes.isoCard}>
                    <Typography
                      className={classes.isoDescription}
                      fontSize={isLittleDesktop ? '12px' : '14px'}
                      lineHeight={1.1}
                      borderRight="1px solid #3273B6"
                    >
                      <FormattedMessage id={'service.blockfive.iso_50001.title'} />
                    </Typography>
                    <Box className={classes.isoImg}>
                      <img
                        height="60px"
                        src={ISO50001}
                        alt={'ISO 50001 能源管理系統'}
                      />
                    </Box>
                  </Box>
                  {isLittleDesktop && (
                    <Typography
                      className={classes.isoAreaButton}
                      fontSize={'14px'}
                      color={theme.palette.secondary.light}
                    >
                      <FormattedMessage id={'service.blockfive.iso_area_button'} />
                    </Typography>
                  )}
                </Box>
              </ISOTooltip>
            </Box>
            <ISOTooltip title={<FormattedMessage id={'service.blockfive.iso_45001.description'} />}>
              <Box>
                {isLittleDesktop && (
                  <Typography
                    style={{
                      textAlign: 'center',
                      paddingBottom: '16px',
                    }}
                    fontSize={'16px'}
                    fontWeight={'bold'}
                  >
                    <FormattedMessage id={'service.blockfive.iso_area_title.2'} />
                  </Typography>
                )}
                <Box className={classes.isoCard}>
                  <Box className={classes.isoImg}>
                    <img
                      height="60px"
                      src={ISO45001}
                      alt={'ISO 45001 職業安全衛生管理系統 (OHSMS)'}
                    />
                  </Box>
                  <Typography
                    className={classes.isoDescription}
                    fontSize={isLittleDesktop ? '12px' : '14px'}
                    lineHeight={1.1}
                    borderLeft="1px solid #3273B6"
                  >
                    <FormattedMessage id={'service.blockfive.iso_45001.title'} />
                  </Typography>
                </Box>
                {isLittleDesktop && (
                  <Typography
                    className={classes.isoAreaButton}
                    fontSize={'14px'}
                    color={theme.palette.secondary.light}
                  >
                    <FormattedMessage id={'service.blockfive.iso_area_button'} />
                  </Typography>
                )}
              </Box>
            </ISOTooltip>
          </Box>
          <Box className={clsx(classes.isoArea3, { [classes.showEl]: lottieCompelete, })}>
            {isLittleDesktop && (
              <Typography
                style={{
                  textAlign: 'center',
                  paddingBottom: '16px',
                }}
                fontSize={'16px'}
                fontWeight={'bold'}
              >
                <FormattedMessage id={'service.blockfive.iso_area_title.3'} />
              </Typography>
            )}
            <ISOTooltip title={<FormattedMessage id={'service.blockfive.iso_9001.description'} />}>
              <Box>
                <Box className={classes.isoCard}>
                  <Box className={classes.isoImg}>
                    <img
                      height="60px"
                      src={ISO9001}
                      alt={'ISO 9001 品質管理系統 (QMS)'}
                    />
                  </Box>
                  <Typography
                    className={classes.isoDescription}
                    fontSize={isLittleDesktop ? '12px' : '14px'}
                    lineHeight={1.1}
                    borderLeft="1px solid #3273B6"
                  >
                    <FormattedMessage id={'service.blockfive.iso_9001.title'} />
                  </Typography>
                </Box>
                {isLittleDesktop && (
                  <Typography
                    className={classes.isoAreaButton}
                    fontSize={'14px'}
                    color={theme.palette.secondary.light}
                  >
                    <FormattedMessage id={'service.blockfive.iso_area_button'} />
                  </Typography>
                )}
              </Box>
            </ISOTooltip>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default BlockFive;