import React, {
  type FC, type ComponentProps,
} from 'react';
import {
  Box,
  type Theme,
  Typography,
  styled,
  type SxProps,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { getImage, } from '@/utils/tool';
import PaginationButton from '@/components/PaginationButton';
import ImageCarousel, {
  type ImageCarouselProps, type ImageData,
} from '@/components/ImageCarousel';
import ScrollAnimation from 'react-animate-on-scroll';

const benefit1ImagesData: ImageData[] = [
  {
    id: 1,
    src: 'hiring/assets/Block3/benefit1-1.png',
    alt: 'benefit1',
  },
  {
    id: 2,
    src: 'hiring/assets/Block3/benefit1-2.png',
    alt: 'benefit2',
  },
  {
    id: 3,
    src: 'hiring/assets/Block3/benefit1-3.png',
    alt: 'benefit3',
  },
  {
    id: 4,
    src: 'hiring/assets/Block3/benefit1-4.png',
    alt: 'benefit3',
  },
  {
    id: 5,
    src: 'hiring/assets/Block3/benefit1-5.png',
    alt: 'benefit3',
  },
  {
    id: 6,
    src: 'hiring/assets/Block3/benefit1-6.png',
    alt: 'benefit3',
  },
];

const benefit2ImagesData: ImageData[] = [
  {
    id: 1,
    src: 'hiring/assets/Block3/benefit2-1.png',
    alt: 'benefit1',
  },
  {
    id: 2,
    src: 'hiring/assets/Block3/benefit2-2.png',
    alt: 'benefit2',
  },
  {
    id: 3,
    src: 'hiring/assets/Block3/benefit2-3.png',
    alt: 'benefit3',
  },
  {
    id: 4,
    src: 'hiring/assets/Block3/benefit2-4.png',
    alt: 'benefit3',
  },
  {
    id: 5,
    src: 'hiring/assets/Block3/benefit2-5.png',
    alt: 'benefit3',
  },
  {
    id: 6,
    src: 'hiring/assets/Block3/benefit2-6.png',
    alt: 'benefit3',
  },
];

const ImageWrapper = styled('div')(({ theme, }) => ({
  borderRadius: '12px',
  overflow: 'hidden',
  background:
    'linear-gradient(165.95deg, rgba(50, 115, 182, 0) 55.21%, rgba(50, 115, 182, 0.25) 92.91%)',
  aspectRatio: '500 / 338',
}));

const Image = styled('img')(({ theme, }) => ({
  width: '100%',
  objectFit: 'cover',
}));

const Picture: FC<{ src: string; alt: string; sx?: SxProps<Theme> }> = ({
  src,
  alt,
  sx,
}) => {
  return (
    <ImageWrapper sx={sx}>
      <Image
        src={src}
        alt={alt}
      />
    </ImageWrapper>
  );
};

const Block3: FC<ComponentProps<typeof Box>> = ({ ...props }) => {
  return (
    <Box
      sx={[{
        padding: '60px 25px',
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '60px',
        background:
            'linear-gradient(180deg, rgba(50, 115, 182, 0.1) 0%, rgba(133, 193, 31, 0) 53.12%)',
      }, (theme) => ({ [theme.breakpoints.up('lg')]: {
        gap: '130px',
        padding: '150px 0',
      }, }),]}
      {...props}
    >
      <Box
        sx={[{
          margin: '0 auto',
          width: '100%',
          // maxWidth: '1050px',
          display: 'flex',
          flexDirection: 'column',
          gap: '25px',
        }, (theme) => ({ [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
          gap: '100px',
        }, }),]}
      >
        <Box
          sx={[{ width: '100%', }, (theme) => ({ [theme.breakpoints.up('lg')]: {
            width: '50%', maxWidth: '50vw - 270px',
          }, }),]}
        >
          <ImageCarousel
            images={benefit1ImagesData}
            emblaOptions={{
              direction: 'rtl', align: 0,
            }}
            imageSx={[{ margin: '25px', },]}
            slideSx={[{ flex: '0 0 100%', }, (theme) => ({
              [theme.breakpoints.up('md')]: { flex: '0 0 70%', },
              [theme.breakpoints.up('lg')]: { flex: '0 0 65%', },
            }),]} />
        </Box>
        <Box
          sx={[{ width: '100%', }, (theme) => ({ [theme.breakpoints.up('lg')]: {
            width: '50%', maxWidth: '50vw - 270px', paddingRight: '10vw',
          }, }),]}
        >
          <Typography
            sx={[{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '33px',
              marginBottom: '24px',
              color: '#0E2338',
            }, (theme) => ({ [theme.breakpoints.up('lg')]: {
              fontSize: '40px',
              lineHeight: '54px',
            }, }),]}
          >
            <FormattedMessage id={'hiring.blockthree.benefit1.title'} />
          </Typography>
          <Box
            sx={[{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '19px',
              marginBottom: '24px',
              color: '#515151',
            }, (theme) => ({ [theme.breakpoints.up('lg')]: {
              fontSize: '16px',
              lineHeight: '24px',
            }, }),]}
          >
            <Typography>
              <FormattedMessage
                id={'hiring.blockthree.benefit1.message.session1'}
              />
            </Typography>
            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '20px',
                margin: '0',
                listStyleType: 'disc',
              }}
            >
              {[
                1,
                2,
                3,
                4,
                5,
              ].map((item) => (
                <li key={item}>
                  <FormattedMessage
                    id={`hiring.blockthree.benefit1.message.session1.list${item}`}
                  />
                </li>
              ))}
            </ul>
          </Box>
          <Typography
            sx={[{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '19px',
              color: '#515151',
            }, (theme) => ({ [theme.breakpoints.up('lg')]: {
              fontSize: '16px',
              lineHeight: '24px',
            }, }),]}
          >
            <FormattedMessage
              id={'hiring.blockthree.benefit1.message.session2'}
            />
          </Typography>
          <a
            href={`${window.location.origin}/zh_tw/esg#employee_benefit`}
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: 'none', }}
          >
            <PaginationButton
              color="green"
              sx={{ marginTop: '25px', }}
            >
              <FormattedMessage id={'hiring.blockthree.benefit1.link'} />
            </PaginationButton>
          </a>
        </Box>
      </Box>
      <Box
        sx={[{
          margin: '0 auto',
          width: '100%',
          // maxWidth: '1050px',
          display: 'flex',
          flexDirection: 'column-reverse',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '25px',
        }, (theme) => ({ [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
          alignItems: 'start',
          gap: '100px',
        }, }),]}
      >
        <Box
          id="training"
          sx={[{ width: '100%', }, (theme) => ({ [theme.breakpoints.up('lg')]: {
            width: '50%', maxWidth: '50vw - 270px', paddingLeft: '10vw',
          }, }),]}
        >
          <Typography
            sx={[{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '33px',
              marginBottom: '24px',
              color: '#0E2338',
            }, (theme) => ({ [theme.breakpoints.up('lg')]: {
              fontSize: '40px',
              lineHeight: '54px',
            }, }),]}
          >
            <FormattedMessage id={'hiring.blockthree.benefit2.title'} />
          </Typography>
          <Box
            sx={[{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '19px',
              marginBottom: '24px',
              color: '#515151',
            }, (theme) => ({ [theme.breakpoints.up('lg')]: {
              fontSize: '16px',
              lineHeight: '24px',
            }, }),]}
          >
            <Typography>
              <FormattedMessage
                id={'hiring.blockthree.benefit2.message.session1'}
              />
            </Typography>
            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '20px',
                margin: '0',
                listStyleType: 'disc',
              }}
            >
              {[
                1,
                2,
                3,
                4,
                5,
                6,
                7,
              ].map((item) => (
                <li key={item}>
                  <FormattedMessage
                    id={`hiring.blockthree.benefit2.message.session1.list${item}`}
                  />
                </li>
              ))}
            </ul>
          </Box>
          <Typography
            sx={[{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '19px',
              color: '#515151',
            }, (theme) => ({ [theme.breakpoints.up('lg')]: {
              fontSize: '16px',
              lineHeight: '24px',
            }, }),]}
          >
            <FormattedMessage
              id={'hiring.blockthree.benefit2.message.session2'}
            />
          </Typography>
        </Box>
        <Box
          sx={[{ width: '100%', }, (theme) => ({ [theme.breakpoints.up('lg')]: {
            width: '50%', maxWidth: '50vw - 270px',
          }, }),]}
        >
          <ImageCarousel
            images={benefit2ImagesData}
            imageSx={[{ margin: '25px', },]}
            slideSx={[{ flex: '0 0 100%', }, (theme) => ({
              [theme.breakpoints.up('md')]: { flex: '0 0 70%', },
              [theme.breakpoints.up('lg')]: { flex: '0 0 65%', },
            }),]} />
        </Box>
      </Box>
    </Box>
  );
};

export default Block3;