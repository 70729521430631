import React, {
  useState, useEffect,
} from 'react';
import {
  useNavigate, Link,
} from 'react-router-dom';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  getHistory, getGlobal,
} from '@/reducers/states';
import {
  Box, Theme, Typography, Button,
} from '@mui/material';
import { FormattedMessage, } from 'react-intl';
import { globalActions, } from '@/reducers/global.slice';
import { makeStyles, } from '@mui/styles';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollAnimation from 'react-animate-on-scroll';
import PaginationButton from '@/components/PaginationButton';
import BackgroudImage from '../assets/Block2/background.png';
import MobileBackgroudImage from '../assets/Block2/background-mobile.png';
import Arrow1 from '../assets/Block2/arrow1.png';
import Arrow2 from '../assets/Block2/arrow2.png';
import Arrow3 from '../assets/Block2/arrow3.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '1100px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '80px 0',
  },
  titleContainer: {
    width: '100%',
    height: '30%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainTitle: {
    color: theme.palette.secondary.main,
    textShadow: '0 0 24px rgba(0, 0, 0, 0.2)',
  },
  subtitle: {
    width: '730px',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  section: {
    width: '100%',
    height: '70%',
    padding: '0 5vw 0 10vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  infoOne: {
    width: 'fit-content',
    justifySelf: 'flex-start',
    display: 'flex',
    alignItems: 'flex-start',
  },
  infoTwo: {
    width: 'fit-content',
    justifySelf: 'flex-start',
    display: 'flex',
    alignItems: 'flex-start',
  },
  infoThree: {
    width: 'fit-content',
    justifySelf: 'flex-start',
    display: 'flex',
    alignItems: 'flex-start',
  },
  greenTitle: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'flex-end',
  },
  infoMainTitle: {
    color: theme.palette.secondary.main,
    width: '',
  },
  infoSubTitle: {
    color: theme.palette.secondary.main,
    maxWidth: '280px',
    paddingBottom: '20px',
  },
  arrow1: {
    marginLeft: '20px',
    marginTop: '90px',
  },
  arrow2: {
    marginLeft: '50px',
    marginTop: '200px',
  },
  arrow3: {
    marginRight: '50px',
    marginTop: '50px',
  },
  [theme.breakpoints.down(800)]: {
    container: {
      height: '100%',
      paddingBottom: '93px',
    },
    subtitle: {
      width: '330px',
      paddingBottom: '20px',
    },
    section: {
      padding: '0 24px',
      flexDirection: 'column',
    },
  },
}));

const BlockTwo = ({ block2Ref, }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { locale, } = useSelector(getGlobal);
  const isLittleDesktop = useMediaQuery(theme.breakpoints.down(1400));
  const isMobile = useMediaQuery(theme.breakpoints.down(400));

  return (
    <div
      className={classes.container}
      style={{ backgroundImage: `url(${isMobile ? MobileBackgroudImage : BackgroudImage})`, }}
      ref={block2Ref}
    >
      <ScrollAnimation
        className={classes.titleContainer}
        animateIn="fadeInDown"
      >
        <Typography
          className={classes.mainTitle}
          fontSize={isLittleDesktop ? '24px' : '40px'}
          fontWeight={'bold'}
          marginBottom={'24px'}
        >
          <FormattedMessage id={'homepage.blocktwo.title'} />
        </Typography>
        <Typography
          className={classes.subtitle}
          fontSize={isLittleDesktop ? '14px' : '16px'}
        >
          <FormattedMessage id={'homepage.blocktwo.description'} />
        </Typography>
      </ScrollAnimation>
      <section className={classes.section}>
        <Box className={classes.leftBlock}>
          {/* 高品質良率，符合品質標準 */}
          <Box className={classes.infoOne}>
            <Box>
              <ScrollAnimation
                className={classes.greenTitle}
                animateIn="fadeInLeft"
              >
                <Typography
                  fontWeight={'bold'}
                  fontSize={isLittleDesktop ? '66px' : '100px'}
                >
                  99.9
                </Typography>
                <Typography
                  style={{ padding: isLittleDesktop ? '0 0 20px 10px' : '0 0 26px 10px', }}
                  fontWeight={'bold'}
                  fontSize={isLittleDesktop ? '24px' : '40px'}
                >
                  %
                </Typography>
              </ScrollAnimation>
              <Typography
                className={classes.infoMainTitle}
                fontWeight={'bold'}
                fontSize={'24px'}
              >
                <FormattedMessage id={'homepage.blocktwo.info1.maintitle'} />
              </Typography>
              <Typography
                className={classes.infoSubTitle}
                fontSize={'16px'}
              >
                <FormattedMessage id={'homepage.blocktwo.info1.subtitle'} />
              </Typography>
              <Link
                to={`/${locale}/service#type`}
                style={{ textDecoration: 'none', }}
              >
                <PaginationButton>
                  <FormattedMessage id={'homepage.blocktwo.info1.buttontext'} />
                </PaginationButton>
              </Link>
            </Box>
            {!isLittleDesktop && (
              <Box className={classes.arrow1}>
                <img src={Arrow1} />
              </Box>
            )}
          </Box>
          {/* 承接離岸風場數量 */}
          <Box className={classes.infoTwo}>
            <Box>
              <ScrollAnimation
                className={classes.greenTitle}
                animateIn="fadeInLeft"
              >
                <Typography
                  fontWeight={'bold'}
                  fontSize={isLittleDesktop ? '66px' : '100px'}
                >
                  5
                </Typography>
                <Typography
                  style={{ padding: isLittleDesktop ? '0 0 20px 10px' : '0 0 26px 10px', }}
                  fontWeight={'bold'}
                  fontSize={isLittleDesktop ? '24px' : '40px'}
                >
                  <FormattedMessage id={'homepage.blocktwo.info2.unit'} />
                </Typography>
              </ScrollAnimation>
              <Typography
                className={classes.infoMainTitle}
                fontWeight={'bold'}
                fontSize={'24px'}
              >
                <FormattedMessage id={'homepage.blocktwo.info2.maintitle'} />
              </Typography>
              <Typography
                className={classes.infoSubTitle}
                fontSize={'16px'}
              >
                <FormattedMessage id={'homepage.blocktwo.info2.subtitle'} />
              </Typography>
              <Link
                to={`/${locale}/esg#environmental`}
                style={{ textDecoration: 'none', }}
              >
                <PaginationButton>
                  <FormattedMessage id={'homepage.blocktwo.info2.buttontext'} />
                </PaginationButton>
              </Link>
            </Box>
            {!isLittleDesktop && (
              <Box className={classes.arrow2}>
                <img src={Arrow2} />
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.rightBlock}>
          {/* 國際標準認證 */}
          <Box className={classes.infoThree}>
            {!isLittleDesktop && (
              <Box className={classes.arrow3}>
                <img src={Arrow3} />
              </Box>
            )}
            <Box>
              <ScrollAnimation
                className={classes.greenTitle}
                animateIn="fadeInRight"
              >
                <Typography
                  fontWeight={'bold'}
                  fontSize={isLittleDesktop ? '66px' : '100px'}
                >
                  5
                </Typography>
                <Typography
                  style={{ padding: isLittleDesktop ? '0 0 20px 10px' : '0 0 26px 10px', }}
                  fontWeight={'bold'}
                  fontSize={isLittleDesktop ? '24px' : '40px'}
                >
                  <FormattedMessage id={'homepage.blocktwo.info3.unit'} />
                </Typography>
              </ScrollAnimation>
              <Typography
                className={classes.infoMainTitle}
                fontWeight={'bold'}
                fontSize={'24px'}
              >
                <FormattedMessage id={'homepage.blocktwo.info3.maintitle'} />
              </Typography>
              <Typography
                className={classes.infoSubTitle}
                fontSize={'16px'}
              >
                <FormattedMessage id={'homepage.blocktwo.info3.subtitle'} />
              </Typography>
              <Link
                to={`/${locale}/service#international_certification`}
                style={{ textDecoration: 'none', }}
              >
                <PaginationButton>
                  <FormattedMessage id={'homepage.blocktwo.info3.buttontext'} />
                </PaginationButton>
              </Link>
            </Box>
          </Box>
        </Box>
      </section>
    </div>
  );
};

export default BlockTwo;